import React from 'react';
import { Dialog, DialogActions, Typography } from '@material-ui/core';
import useStyles from './styles';

import CloseIcon from '@material-ui/icons/Close';

export default function GenericModal({ children, open, handleClose, buttons = null, title }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: classes.paper
      }}
    >
      <div className={classes.header}>
        <Typography variant="h4">{title}</Typography>
        {handleClose && (
          <button type="button" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </button>
        )}
      </div>

      <div className={classes.content}>
        {children}
      </div>

      {!!buttons && (
        <DialogActions style={{ justifyContent: 'center' }} disableSpacing>
          {buttons}
        </DialogActions>
      )}
    </Dialog>
  );
}
