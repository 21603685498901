/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import Login from "../../../components/Admin/Login";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AdminAuthPage() {
  return (
    <>
      <div
        className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
        style={{ backgroundColor: "#f3f5f9" }}
      >
        <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"></div>
        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          <Switch>
            <ContentRoute path="/admin/auth/login" component={Login} />
            <Redirect from="/admin/auth" exact={true} to="/admin/auth/login" />
            <Redirect to="/admin/auth/login" />
          </Switch>
        </div>
      </div>
    </>
  );
}
