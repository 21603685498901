import React from 'react';
import clsx from 'clsx';
import './styles.scss';

export default function AnimatedHeight({ show, className = '', children, ...props }) {
  return (
    <div
      className={clsx('animated-card', {'animated-show': show}, className)}
      {...props}
    >
      {children}
    </div>
  );
}
