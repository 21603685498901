import clsx from 'clsx';
import React from 'react';
import { prettyPercentage } from '../../../helpers';

export default function Comparison({ a, b, useColors = true, showSymbol = true, inverse = false }) {
  const equalReturn = <span className="font-size-lg">-</span>

  a = parseFloat(a);
  b = parseFloat(b);

  if (a === b) {
    return equalReturn;
  }

  const percentage = a / b;
  let value;
  let sign;
  let colorClass;

  if (a == 0) {
    value = 1;
    sign = '-';
    colorClass = 'text-danger';
  } else if (b == 0) {
    value = 1;
    sign = '+';
    colorClass = 'text-success';
  } else if (percentage > 1) {
    value = percentage - 1;
    sign = '+';
    colorClass = 'text-success';
  } else if (percentage < 1) {
    value = 1 - percentage;
    sign = '-';
    colorClass = 'text-danger';
  }

  if (percentage === 1) {
    return equalReturn;
  }

  return (
    <span className={clsx('font-size-lg', {
      [colorClass]: useColors
    })}>
      {sign}{prettyPercentage(value)}
    </span>
  );
}
