/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import ForgotPassword from "../../../components/Site/Auth/ForgotPassword";
import Login from "../../../components/Site/Auth/Login";
import ResetPassword from "../../../components/Site/Auth/ResetPassword";
import { ContentRoute } from "../../../_metronic/layout";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import './styles.scss';

export function Auth() {
  return (
    <>
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white login-container"
      >
        <div
          className="login-aside d-flex  bgi-size-cover bgi-no-repeat p-10 p-lg-10"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`,
          }}
        >
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-center">
                PSI Technologies
              </h3>
            </div>
            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div className="opacity-70 font-weight-bold">
                &copy; {new Date().getFullYear()} Made with lot of <span style={{ color: "#f42c51" }}>❤</span> and ☕ by Devio&reg;
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
          style={{ backgroundColor: "#f3f5f9" }}
        >
          <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"></div>
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <Switch>
              <ContentRoute path="/auth/login" component={Login} />
              <ContentRoute
                path="/auth/forgot-password"
                component={ForgotPassword}
              />
              <ContentRoute
                path="/auth/reset-password"
                component={ResetPassword}
              />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
              &copy; {new Date().getFullYear()} Made with lot of <span style={{ color: "#f42c51" }}>♥</span> and ☕ by Devio&reg;
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
