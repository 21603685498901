import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Link,
  makeStyles,
  Paper,
  Snackbar
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import Clipboard from "react-clipboard.js";
import useAuth from "../../../../hooks/auth";
import { baseURL } from "../../../../services/api";

const BLING_CLIENT_ID = process.env.REACT_APP_BLING_CLIENT_ID || "";

const useStyles = makeStyles(theme => ({
  paperRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: "100%"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    marginLeft: theme.spacing(1)
  },
  divider: {
    height: 28,
    margin: 4
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  authorizeButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 2)
  }
}));

export default function StockUpdates() {
  const classes = useStyles();
  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState(false);
  const auth = useAuth();

  const updateUrl = `${baseURL}/stock_updates/bling/${auth.user.saas_business.id}`;

  useEffect(() => {
    if (showCopySuccessMessage) {
      setTimeout(() => {
        setShowCopySuccessMessage(false);
      }, 2500);
    }
  }, [showCopySuccessMessage]);

  const handleCopyUrlSuccess = () => {
    setShowCopySuccessMessage(true);
  };

  const handleCloseUrlMessage = () => {
    setShowCopySuccessMessage(false);
  };

  const handleOpenAuthorazeClient = () => {
    window.open('https://www.bling.com.br/Api/v3/oauth/authorize?response_type=code&client_id=' + BLING_CLIENT_ID + '&state=' + auth.user.saas_business.uuid, "_blank");
  };

  return (
    <Card className="card-heavy-border">
      <CardHeader subheader="Cadastre a URL abaixo para atualizações de estoque do Bling" />

      <CardContent>
        <Paper component="form" className={classes.paperRoot}>
          <InputBase
            value={updateUrl}
            type="text"
            disabled
            className={classes.input}
          />
          <Divider className={classes.divider} />
          <Clipboard
            component={IconButton}
            data-clipboard-text={updateUrl}
            onSuccess={handleCopyUrlSuccess}
            button-title="Copiar"
          >
            <AssignmentIcon />
          </Clipboard>
        </Paper>
        <Snackbar open={showCopySuccessMessage} autoHideDuration={2500}>
          <Alert onClose={handleCloseUrlMessage} severity="success">
            URL copiada com sucesso!
          </Alert>
        </Snackbar>
      </CardContent>
      <Grid item xs={12} className={classes.inputContainer}>
        <Button
          variant="contained"
          color="default"
          className={classes.authorizeButton}
          onClick={handleOpenAuthorazeClient}
        >
          Autorizar Bling
        </Button>
      </Grid>
      <CardActions>
        <Link
          href="https://ajuda.bling.com.br/hc/pt-br/articles/360046387754-Callback-de-altera%C3%A7%C3%A3o-de-estoque-API"
          target="_blank"
          rel="noopener"
          className="mx-auto"
        >
          Saiba Mais
        </Link>
      </CardActions>
    </Card>
  );
}
