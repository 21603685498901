import { persistReducer } from "redux-persist";

export const actionTypes = {
  LoadedStores: "[Loaded Stores]",
  CreateStore: "[Create Store] Action",
  UpdateStore: "[Update Store] Action",
  DeleteStore: "[Delete Store] Action",
  SetSelectedStore: "[Set Selected Store] Action",
  CurrentSelectedStore: "[Current Selected Store] Action",
  DeleteSelectedStore: "[Delete Selected Store] Action",
  ClearSelectedStores: "[Clear Selected Stores] Action",
};

const initialStoresState = {
  data: null,
  selectedStores: []
};

export const reducer = (state = initialStoresState, action) => {
  switch (action.type) {
    case actionTypes.LoadedStores: {
      const { stores } = action.payload;

      return {
        ...state,
        data: stores
      };
    }

    case actionTypes.CreateStore: {
      const { store } = action.payload;

      return {
        ...state,
        data: [store, ...state.data]
      };
    }

    case actionTypes.UpdateStore: {
      const { store: savedStore } = action.payload;
      const newStores = state.data.map(store =>
        savedStore.id === store.id ? savedStore : store
      );

      return {
        ...state,
        data: newStores
      };
    }

    case actionTypes.DeleteStore: {
      const { storeId } = action.payload;
      const newStores = state.data.filter(store => store.id !== storeId);

      return {
        ...state,
        data: newStores
      };
    }

    case actionTypes.LoadSelectedStores: {
      const { selectedStores } = action.payload;

      return {
        ...state,
        selectedStores: {
          selectedStores
        }
      };
    }

    case actionTypes.SetSelectedStore: {
      const { selectedStores } = action.payload;

      return {
        ...state,
        selectedStores: [...state.selectedStores, selectedStores]
      };
    }

    case actionTypes.DeleteSelectedStore: {
      const { selectedStoreId } = action.payload;
      const newSelectedStore = state.selectedStores.filter(
        store => store.id !== selectedStoreId
      );

      return {
        ...state,
        selectedStores: newSelectedStore
      };
    }

    case actionTypes.ClearSelectedStores: {
      return {
        ...state,
        selectedStores: [],
      };
    }

    default:
      return state;
  }
};

export const actions = {
  fillStores: stores => ({
    type: actionTypes.LoadedStores,
    payload: { stores }
  }),
  createStore: store => ({ type: actionTypes.CreateStore, payload: { store } }),
  updateStore: store => ({ type: actionTypes.UpdateStore, payload: { store } }),
  deleteStore: storeId => ({
    type: actionTypes.DeleteStore,
    payload: { storeId }
  }),
  loadSelectedStores: selectedStores => ({
    type: actionTypes.LoadSelectedStores,
    payload: { selectedStores }
  }),
  setSelectedStore: selectedStores => ({
    type: actionTypes.SetSelectedStore,
    payload: { selectedStores }
  }),
  deleteSelectedStore: selectedStoreId => ({
    type: actionTypes.DeleteSelectedStore,
    payload: { selectedStoreId }
  }),
  clearSelectedStores: () => ({
    type: actionTypes.ClearSelectedStores,
  }),
};
