import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import UserPage from "./UserPage";
import BusinessPage from "./BusinessPage";

export default function ManagePage() {
  return (
    <Switch>
      <Redirect exact={true} from="/admin/manage" to="/admin/manage/users" />
      <ContentRoute from="/admin/manage/users" component={UserPage} />
      <ContentRoute from="/admin/manage/businesses" component={BusinessPage} />
    </Switch>
  );
}
