import {
  Button,
  ButtonGroup, CircularProgress, Grid,
  Table, TableBody, TableCell as MuiTableCell, TableRow, withStyles
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { formatStoresToId, syncScrollBetween } from "../../../../../helpers";
import { useSelectedStores } from "../../../../../hooks/stores";
import {
  dLessOneMoment, yearlyVision
} from "../../../../../services/date-filters";
import { getSalesReceiptYearly } from "../../../../../services/sales-receipt";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar
} from "../../../../../_metronic/_partials/controls";
import ComparisonTooltip from "../../../../Common/ComparisonTooltip";
import YearlyTableSideTitle from "../../../YearlyTableSideTitle";
import "./styles.scss";

const PAYMENT_METHOD = {
  CASH: "Venda R$",
  DEBIT_CARD: "Venda CD",
  CREDIT_CARD: "Venda CC",
  CREDIT_CARD_WITH_INSTALLMENTS: "Venda CCP"
};

const PAYMENT_METHOD_DESCRIPTION = {
  CASH: "Dinheiro",
  DEBIT_CARD: "Cartão de débito",
  CREDIT_CARD: "Cartão de crédito",
  CREDIT_CARD_WITH_INSTALLMENTS: "Cartão de crédito parcelado"
};

const visions = {
  ANUAL: "anual",
  TRIMESTRAL: "trimestral"
};

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: 0
  }
}))(MuiTableCell);

const FixedColumn = withStyles(theme => ({
  root: {
    width: 60,
    position: 'sticky',
    left: 0,
    background: '#fff',
  },
}))(TableCell);

export function Yearly({ vision, setVision }) {
  const yearlies = yearlyVision();
  const [dataYearly, setDataYearly] = useState([]);
  const [dataTrimester, setDataTrimester] = useState([]);
  const selectedStores = useSelectedStores();
  const [loading, setLoading] = useState(false);
  const anualContainerRef = useRef();
  const trimestralContainerRef = useRef();

  const loadSalesReceiptYearly = async () => {
    setLoading(true);
    try {
      const storesId = formatStoresToId(selectedStores);
      const res = await getSalesReceiptYearly(storesId);

      setDataYearly(res.data.yearly);
      setDataTrimester(res.data.trimester);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const monthTotal = (data, year, month) => {
    return Object.keys(data).reduce((carry, method) => {
      return carry + (data[method][year][month] || 0);
    }, 0);
  };

  const sumTotal = (data, type, year) => {
    const result = Object.keys(data).reduce((carry, method) => {
      return carry + data[method][year][type === 'yearly' ? 13 : 5];
    }, 0);

    return result;
  };

  const sideTitles = Object.keys(PAYMENT_METHOD).map(method => ({
    title: PAYMENT_METHOD[method],
    tooltip: PAYMENT_METHOD_DESCRIPTION[method]
  }));

  useEffect(() => {
    loadSalesReceiptYearly();
  }, [selectedStores]);

  useEffect(() => {
    if ((dataYearly || dataTrimester) && !loading) {
      // syncronize scroll
      const anualContainer = anualContainerRef.current;
      const trimestralContainer = trimestralContainerRef.current;

      if (anualContainer) {
        syncScrollBetween(
          anualContainer, document.querySelector('#anual-finance-header')
        );
      }

      if (trimestralContainer) {
        syncScrollBetween(
          trimestralContainer, document.querySelector('#trimestral-finance-header')
        );
      }
    }
  }, [dataYearly, dataTrimester, loading, vision]);

  return (
    <>
      <Grid item xs={12} className="printable-container">
        <Card className="card-border">
          <CardHeader
            title={
              <>
                <CardHeaderTitle>
                  <span>Forma de Recebimento da Venda R$</span>
                </CardHeaderTitle>
                <div className="font-size-sm text-muted mt-2">
                  Registros das formas de recebimento das vendas realizadas nos
                  anos de {dLessOneMoment.format("YYYY") - 2},{" "}
                  {dLessOneMoment.format("YYYY") - 1} e{" "}
                  {dLessOneMoment.format("YYYY")} até{" "}
                  {dLessOneMoment.format("MMMM").toLowerCase()} com os
                  indicadores de variação de desempenho, tanto na visão
                  trimestral como anual.
                </div>
              </>
            }
            toolbar={
              <CardHeaderToolbar className="d-print-none">
                <ButtonGroup>
                  <Button
                    color="secondary"
                    onClick={() => setVision(visions.ANUAL)}
                    variant={
                      vision === visions.ANUAL ? "contained" : "outlined"
                    }
                  >
                    Anual
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setVision(visions.TRIMESTRAL)}
                    variant={
                      vision === visions.TRIMESTRAL ? "contained" : "outlined"
                    }
                  >
                    Trimestral
                  </Button>
                </ButtonGroup>
              </CardHeaderToolbar>
            }
          />
          <CardBody className="border-table">
            {loading && (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}
            {!loading &&
              (vision === "anual" ? (
                <>
                <div class="theader" id="anual-finance-header">
                  <div><strong>Jan</strong></div>
                  <div><strong>Fev</strong></div>
                  <div><strong>Mar</strong></div>
                  <div><strong>Abr</strong></div>
                  <div><strong>Mai</strong></div>
                  <div><strong>Jun</strong></div>
                  <div><strong>Jul</strong></div>
                  <div><strong>Ago</strong></div>
                  <div><strong>Set</strong></div>
                  <div><strong>Out</strong></div>
                  <div><strong>Nov</strong></div>
                  <div><strong>Dez</strong></div>
                  <div><strong>Total</strong></div>
                </div>
                <div className="d-flex">
                  <YearlyTableSideTitle titles={sideTitles} />

                  <div className="overflow-auto" ref={anualContainerRef}>
                    {Object.keys(PAYMENT_METHOD).map((method, idx) => (
                      <Table className="subtable">
                        <TableBody className="yearly-table-body">
                          {yearlies.map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {dataYearly &&
                              Object.keys(dataYearly).length > 0 ? (
                                Object.keys(dataYearly[method][year]).map(
                                  (value, idx) => (
                                    <TableCell
                                      key={`${year}-${idx}`}
                                      style={{
                                        minWidth: 150,
                                        backgroundColor: "#f7f7f7"
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <span className="mr-2">
                                          {value != 13
                                            ? (
                                              dataYearly[method][year][13] != 0
                                                ? (
                                                  dataYearly[method][year][value]
                                                    ? parseFloat((dataYearly[method][year][value] / monthTotal(dataYearly, year, value)) * 100).toFixed(2) + "%"
                                                    : "0.0%"
                                                )
                                                : "0.0%"
                                            )
                                            : (
                                              (sumTotal(dataYearly, "yearly", year)
                                                ? (dataYearly[method][year][value] || 0) / sumTotal(dataYearly, "yearly", year)
                                                : 0
                                              ) * 100
                                              ).toFixed(2) + "%"}
                                        </span>

                                        {dataYearly[method][year - 1] !==
                                          undefined && (
                                          <ComparisonTooltip
                                            a={dataYearly[method][year][value]}
                                            b={
                                              dataYearly[method][year - 1][
                                                value
                                              ]
                                            }
                                          />
                                        )}
                                      </div>
                                    </TableCell>
                                  )
                                )
                              ) : (
                                <TableCell
                                  key={`${year}-${idx}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  0.0%
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ))}
                  </div>
                </div>
                </>
              ) : (
                <>
                  <div class="theader" id="trimestral-finance-header" style={{
                    maxWidth: 750
                  }}>
                    <div><strong>1º trimestre</strong></div>
                    <div><strong>2º trimestre</strong></div>
                    <div><strong>3º trimestre</strong></div>
                    <div><strong>4º trimestre</strong></div>
                    <div><strong>Total</strong></div>
                  </div>
                  <div className="d-flex">
                    <YearlyTableSideTitle titles={sideTitles} />

                    <div className="overflow-auto" ref={trimestralContainerRef}>
                      {Object.keys(PAYMENT_METHOD).map((method, idx) => (
                        <Table>
                          <TableBody className="yearly-table-body">
                            {yearlies.map(year => (
                              <TableRow key={year}>
                                <FixedColumn>
                                  <strong>{year}</strong>
                                </FixedColumn>
                                {Object.keys(dataTrimester[method][year]).map(
                                  (value, idx) => (
                                    <TableCell
                                      key={`${year}-${idx}`}
                                      style={{
                                        minWidth: 150,
                                        backgroundColor: "#f7f7f7"
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <span className="mr-2">
                                          {value != 5
                                            ? (
                                              dataTrimester[method][year][5] != 0
                                                ? (
                                                  dataTrimester[method][year][value]
                                                    ? parseFloat((dataTrimester[method][year][value] / monthTotal(dataTrimester, year, value)) * 100).toFixed(2) + "%"
                                                    : "0.0%"
                                                )
                                                : "0.0%"
                                            ) : (
                                                (sumTotal(dataTrimester, "trimestral", year)
                                                  ? (dataTrimester[method][year][value] || 0) / sumTotal(dataTrimester, "trimestral", year)
                                                  : 0
                                                ) * 100
                                              ).toFixed(2) + "%"}
                                        </span>

                                        {dataTrimester[method][year - 1] !==
                                          undefined && (
                                          <ComparisonTooltip
                                            a={dataTrimester[method][year][value]}
                                            b={
                                              dataTrimester[method][year - 1][
                                                value
                                              ]
                                            }
                                          />
                                        )}
                                      </div>
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ))}
                    </div>
                  </div>
                </>
              ))}
          </CardBody>
        </Card>
      </Grid>
    </>
  );
}
