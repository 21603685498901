import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import React from "react";
import { decimalMoney, prettyPercentage } from "../../../../helpers";
import ComparisonTooltip from "../../../Common/ComparisonTooltip";
import InfoTooltip from "../../../Common/InfoTooltip";
import Pagination from "../../../Common/Pagination";

export default function ProductsList({
  loading,
  products,
  currentPage,
  pages,
  onChangePaginationHandler,
  withCurveColumn = false,
  showBrands = true
}) {
  const getSubcategories = subcategories => {
    if (typeof subcategories === "string") {
      return JSON.parse(subcategories);
    }

    return subcategories;
  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}

      {!loading && !products?.length && (
        <div className="empty-list">Nenhum produto encontrado.</div>
      )}

      {!loading && !!products?.length && (
        <>
          <div className="overflow-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: 0,
                      padding: 6,
                      minWidth: 100,
                      position: "sticky"
                    }}
                  >
                    <span className="font-size-lg">Código</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Item</span>
                  </TableCell>
                  {showBrands && (
                    <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                      <span className="font-size-lg">Marca</span>
                    </TableCell>
                  )}
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Categoria</span>
                  </TableCell>
                  {withCurveColumn && (
                    <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                      <span className="font-size-lg">Curva</span>
                    </TableCell>
                  )}
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Venda QTD</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Venda R$</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="font-size-lg">CMV R$</span>
                      <InfoTooltip
                        text="Venda R$ a CMV (Custo da Mercadoria Vendida)"
                        margin="mb-1 ml-2"
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Lucro Bruto R$</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="font-size-lg">MB%</span>
                      <InfoTooltip text="Margem Bruta %" margin="mb-1 ml-2" />
                    </div>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Mark Up</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Estoque QTD</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Estoque R$</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <div className="d-flex align-items-center">
                      <span className="font-size-lg">VMM</span>
                      <InfoTooltip
                        text="Venda Média Mensal no período analisado"
                        margin="ml-2 mb-1"
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="font-size-lg">PSI Index</span>
                      <InfoTooltip
                        text="PSI Index: fator que indica se VMM (Venda Média Mensal) do período analisado é maior ou menor que a venda média calculada baseada nas vendas da última semana."
                        margin="mb-1 ml-2"
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <span className="font-size-lg">Necessidade Compra</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  products?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.sku || "-"}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">{product.name}</span>
                      </TableCell>
                      {showBrands && (
                        <TableCell style={{ border: 0, padding: 6 }}>
                          <span className="font-size-lg">
                            {product.brand || "-"}
                          </span>
                        </TableCell>
                      )}
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.category || "-"}
                        </span>
                      </TableCell>
                      {withCurveColumn && (
                        <TableCell
                          style={{ border: 0, padding: 6, minWidth: 100 }}
                        >
                          <span className="font-size-lg">
                            <strong>{product.curve.toUpperCase()}</strong>
                          </span>
                        </TableCell>
                      )}
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.total_sold}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <div className="d-flex align-items-center">
                          <span className="font-size-lg">
                            {decimalMoney(
                              product.total_value_without_discounts
                            )}
                          </span>
                          <InfoTooltip
                            text={`Valor Unitário de Venda ${decimalMoney(
                              product.total_value_without_discounts /
                                product.total_sold
                            )}`}
                            margin="ml-2 mb-1"
                          />
                        </div>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {decimalMoney(product.cmv)}
                        </span>
                        <InfoTooltip
                          text={`Custo unitário médio: ${decimalMoney(
                            product.cmv / product.total_sold
                          )}`}
                          margin="ml-2 mb-1"
                        />
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {decimalMoney(product.gross_profit)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {prettyPercentage(product.gross_margin)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.mark_up && +product.mark_up > 0
                            ? parseFloat(product.mark_up).toFixed(2)
                            : "-"}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg badge badge-warning">
                          {product.in_stock}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <div className="d-flex align-items-center">
                          <span className="font-size-lg">
                            {decimalMoney(product.stock_value)}
                          </span>
                          <InfoTooltip
                            text={`Custo unitário atual: ${decimalMoney(
                              product.cost_value
                            )}`}
                            margin="ml-2 mb-1"
                          />
                        </div>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg badge badge-success">
                          {Math.round(parseFloat(product.vmm).toFixed(0))}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="d-flex align-items-center">
                          <ComparisonTooltip
                            a={parseFloat(product.vmm)}
                            b={parseFloat((product.vms / 8) * 30)}
                            texts={{
                              same: "Se manteve o mesmo",
                              obs: product.vms == 0 ? "VMM' é 0" : false
                            }}
                          />
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        {parseInt(product.need) > 0 ? (
                          <span className="font-size-lg badge badge-danger">
                            {Math.ceil(product.need)}
                          </span>
                        ) : (
                          <span className="font-size-lg"> - </span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}

      {!loading && !!products?.length && (
        <div className="mt-10">
          <Pagination
            page={currentPage}
            count={pages}
            onChange={onChangePaginationHandler}
          />
        </div>
      )}
    </>
  );
}
