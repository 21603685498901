import {
  Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableSortLabel,
  Tooltip
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import StoreDialog from "../../../../components/Admin/Manage/StoreDialog";
import DeleteDialog from "../../../../components/Common/Dialogs/DeleteDialog";
import { useStores } from "../../../../hooks/stores";
import { actions as storesActions } from "../../../../redux/Stores";
import { deleteStore } from "../../../../services/stores";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";

const INITIAL_DATA = {
  name: ""
};

export default function Stores() {
  const stores = useStores();
  const [data, setData] = useState(INITIAL_DATA);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("asc");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPersistModal, setShowPersistModal] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const createSortHandler = (property, order) => event => {
    setOrderBy(property);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  const handleShowDeleteModal = store => {
    setData(store);
    setShowDeleteModal(true);
  };

  const handleHideDeleteModal = () => {
    setShowDeleteModal(false);
    setData(INITIAL_DATA);
  };

  const handleShowPersistModal = store => {
    if (store) {
      setData(store);
    } else {
      setData(INITIAL_DATA);
    }

    setShowPersistModal(true);
  };

  const handleClosePersistModal = () => {
    setErrors({});
    setShowPersistModal(false);
  };

  const handleDelete = async () => {
    try {
      await deleteStore(data.id);

      dispatch(storesActions.deleteStore(data.id));

      setData(INITIAL_DATA);
      setShowDeleteModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const headRows = [
    { id: "name", label: "Filial" },
    { id: "slug", label: "Slug" },
    { id: "deposit", label: "Depósito" },
    { id: "actions", label: "" }
  ];

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Tooltip title="Adicionar empresa">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleShowPersistModal}
          >
            Adicionar
          </Button>
        </Tooltip>
      </div>
    </div>
  );

  return (
    <>
      <DeleteDialog
        title="Deseja remover a filial?"
        description="Todos os pedidos relacionados serão removidos também."
        open={showDeleteModal}
        handleClose={handleHideDeleteModal}
        handleDelete={handleDelete}
      />

      <StoreDialog
        open={showPersistModal && data}
        handleClose={handleClosePersistModal}
        store={data}
      />

      <div className="row">
        <div className="col">
          <Card className="card-heavy-border">
            <CardHeader title="Gerencie as filiais" toolbar={toolbar} />
            <CardBody>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headRows.map(row => (
                        <TableCell
                          key={row.id}
                          sortDirection={orderBy === row.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id, order)}
                          >
                            <span className="font-size-lg">{row.label}</span>
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stores &&
                      stores.map(store => (
                        <TableRow key={store.id}>
                          <TableCell><span className="font-size-lg">{store.name}</span></TableCell>
                          <TableCell><span className="font-size-lg">{store.slug}</span></TableCell>
                          <TableCell><span className="font-size-lg">{store.deposit}</span></TableCell>
                          <TableCell>
                            <Tooltip title={"Editar filial"}>
                              <IconButton
                                aria-label="Criar"
                                color="secondary"
                                onClick={() => handleShowPersistModal(store)}
                              >
                                <CreateIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Deletar filial"}>
                              <IconButton
                                aria-label="Delete"
                                style={{ color: "#f44336" }}
                                onClick={() => handleShowDeleteModal(store)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
