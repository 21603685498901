import React, { useEffect, useState, useCallback } from 'react';
import useProducts from '../../../../hooks/products';
import useOrders from '../../../../hooks/orders';
import { useSubheader } from '../../../../_metronic/layout';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Card, CardBody, CardHeader, CardHeaderTitle } from '../../../../_metronic/_partials/controls';
import { actions as ordersActions } from '../../../../redux/Orders';
import { useDispatch } from 'react-redux';
import {
  filters,
} from '../../../../services/orders';
import { getDaysBetween, getHoursBetween, getMonthsBetween, groupByDate, prettyMoney } from '../../../../helpers';
import { Line } from 'react-chartjs-2';

const onlyNotReturnedOrders = order => !order.returned;

function getOrdersWithProduct(productId, orders) {
  return orders.filter((order) => {
    return !!order.products.filter((product) => product.id === productId).length;
  });
}

function getCvmForProduct(product, orders) {
  const sortedOrders = [ ...orders ];
  sortedOrders.sort((a, b) => new Date(b.date) - new Date(a.date));
  const ordersWithProduct = getOrdersWithProduct(product.id, sortedOrders);

  if (ordersWithProduct.length) {
    const firstOrder = ordersWithProduct[0];
    const lastOrder = ordersWithProduct[ordersWithProduct.length - 1];

    const stockUpdates = product.stock_updates;

    if (stockUpdates.length) {
      const previousStockUpdates = stockUpdates.filter((update) => {
        const updateDate = new Date(update.created_at);
        const firstOrderDate = new Date(firstOrder.date);

        return updateDate < firstOrderDate;
      });
      const afterLastOrderStockUpdates = stockUpdates.filter((update) => {
        const updateDate = new Date(update.created_at);
        const lastOrderDate = new Date(lastOrder.date);

        return updateDate > lastOrderDate;
      });

      previousStockUpdates.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      afterLastOrderStockUpdates.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      const firstStockUpdate = previousStockUpdates[previousStockUpdates.length - 1];
      const lastStockUpdate = afterLastOrderStockUpdates[0];

      if (firstStockUpdate && lastStockUpdate) {
        const totalSold = ordersWithProduct.reduce((prev, curr) => {
          return prev + curr.products.reduce((prevQuantity, currProduct) => {
            if (currProduct.id === product.id) {
              return prevQuantity + currProduct.pivot.quantity * currProduct.pivot.unitary_value;
            }

            return prevQuantity;
          }, 0);
        }, 0);
        const initialStockValue = parseFloat(firstStockUpdate.quantity * firstStockUpdate.unitary_value);
        const finalStockValue = parseFloat(lastStockUpdate.quantity * lastStockUpdate.unitary_value);
        const cmv = initialStockValue + totalSold - finalStockValue;

        return cmv;
      }
    }
  }
}

function getQuantityForOrder(order, productId) {
  const found = order.products.find(product => product.id == productId);

  if (found) {
    return found.pivot.quantity;
  }

  return 0;
}

const dateFormats = {
  days: {
    format: 'MM-DD',
    label: (date) => {
      const parts = date.split('-');
  
      return `${parts[1]}/${parts[0]}`;
    }
  },
  months: {
    format: 'YYYY-MM',
    label: (date) => {
      const parts = date.split('-');
  
      return `${parts[1]}/${parts[0]}`;
    }
  },
  hours: {
    format: 'HH',
    label: (date) => {
      return `${date}:00`;
    },
    sort: (a, b) => a - b,
  }
};

const defaultDatasetOptions = {
  fill: false,
  borderColor: '#3783E7',
};

const chartDataExtractor = {
  sellsNumber: (orders, productId, datesBetween) => {
    const dateFormat = dateFormats[orders.filters.type];
    const ordersGrouped = orders.data ? groupByDate(orders.data, 'date', datesBetween, dateFormat.format) : {};
    const groupsNames = Object.keys(ordersGrouped);
    if (dateFormat.sort) {
      groupsNames.sort(dateFormat.sort)
    }

    const chartLabels = groupsNames.map(dateFormat.label);
    const valueOfOrders = groupsNames.map((group) => {
      return ordersGrouped[group].reduce((prev, order) => {
        return prev + getQuantityForOrder(order, productId);
      }, 0);
    });
    
    const chartData = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Número de vendas',
          data: valueOfOrders,
          ...defaultDatasetOptions,
        }
      ]
    };

    return {
      data: chartData,
      options: {
        tooltips: {
          callbacks: {
            label: function(tooltipItems, data) {
              return `${tooltipItems.yLabel} vendas`;
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function(value, index, values) {
                return parseInt(value);
              },
              stepSize: 1,
              scaleStartValue: 0,
              beginAtZero: true,
            }
          }]
        }
      }
    }
  },
};

const intervalHandler = {
  days: getDaysBetween,
  hours: getHoursBetween,
  months: getMonthsBetween,
}

export default function Product({ match }) {
  const products = useProducts();
  const orders = useOrders();
  const validOrders = orders.data
    ? orders.data.filter(onlyNotReturnedOrders)
    : [];
  const [product, setProduct] = useState(null);
  const [cmv, setCmv] = useState(0);
  const { id } = match.params;
  const dispatch = useDispatch();
  const subheader = useSubheader();
  const [chartType, setChartType] = useState('sellsNumber');
  const filterType = orders.filters.type;
  const [datesBetween, setDatesBetween] = useState(intervalHandler[filterType](
    orders.filters.start_date,
    orders.filters.end_date
  ));
  const { data: chartData, options: chartOptions } = chartDataExtractor[chartType](orders, id, datesBetween);

  if (product) {
    subheader.setTitle(product.name);
    subheader.setBreadcrumbs(null);
  }

  useEffect(() => {
    if (product && validOrders) {
      const productCmv = getCvmForProduct(product, validOrders);

      if (productCmv) {
        setCmv(productCmv);
      }
    }
  }, [product, validOrders]);

  useEffect(() => {
    if (products) {
      const currentProduct = products.find(_product => _product.id == id);

      setProduct(currentProduct);
    }
  }, [products]);

  const updateFilters = (value) => {
    dispatch(ordersActions.updateFilters(value));
  };

  const handleTimeFilterChange = useCallback((e) => {
    const { value: type } = e.target;
    const timeFilter = filters.time[type]; // Returns both start date and end date filters
    const newFilters = {
      ...orders.filters,
      ...timeFilter,
      time_type: type,
    };

    setDatesBetween(intervalHandler[timeFilter.type](timeFilter.start_date, timeFilter.end_date));

    updateFilters(newFilters);
  }, [orders.filters]);

  const handlePaymentMethodFilterChange = useCallback((e) => {
    const { value } = e.target;
    const filterValue = value === 'ALL' ? '' : value;
    const newFilters = { ...orders.filters, payment_method: filterValue };

    updateFilters(newFilters);
  }, [orders.filters]);

  const filtersBody = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth className="filter-container">
          <InputLabel id="payment-method-filter">Intervalo</InputLabel>
          <Select
            label="Intervalo"
            labelId="payment-method-filter"
            value={orders.filters.time_type || 'ALL'}
            onChange={handleTimeFilterChange}
            fullWidth
          >
            <MenuItem value="LAST_DAY">Dia anterior</MenuItem>
            <MenuItem value="CURRENT_MONTH">Mês atual D-1</MenuItem>
            <MenuItem value="LAST_12_MONTHS">Últimos 12 meses até mês atual D-1</MenuItem>
            <MenuItem value="CURRENT_YEAR">Ano atual até D-1</MenuItem>
            <MenuItem value="LAST_YEAR">Ano passado</MenuItem>
            <MenuItem value="LAST_TWO_YEARS_WITH_CURRENT_YEAR">Últimos 2 anos até D-1</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth className="filter-container">
          <InputLabel id="payment-method-filter">Forma de pagamento</InputLabel>
          <Select
            label="Forma de pagamento"
            labelId="payment-method-filter"
            value={orders.filters.payment_method || 'ALL'}
            onChange={handlePaymentMethodFilterChange}
            fullWidth
          >
            <MenuItem value="ALL">Todas</MenuItem>
            <MenuItem value="CREDIT_CARD">Cartão de crédito</MenuItem>
            <MenuItem value="CREDIT_CARD_WITH_INSTALLMENTS">Cartão de crédito parcelada</MenuItem>
            <MenuItem value="DEBIT_CARD">Cartão de débito</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Card>
          {!!product && <CardHeader title="Vendas" />}

          <CardBody>
            {product === null && <div className="loading-container"><CircularProgress /></div>}
            {product && <Line data={chartData} options={chartOptions} />}
          </CardBody>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title={
            <>
              <CardHeaderTitle>Filtros</CardHeaderTitle>
              <div className="font-size-sm text-muted mt-2">Em relação as vendas</div>
            </>
          }
          />
          <CardBody>
            {filtersBody}
          </CardBody>
        </Card>
      </Grid>

      <Grid item xs={12} container spacing={4}>
          <Grid item xs={12} md={3}>
            <Card className="bg-primary">
              <CardBody>
                <div className="text-inverse-primary font-weight-bolder font-size-h1 mt-3">{prettyMoney(cmv)}</div>
                <p className="text-inverse-primary font-weight-bold font-size-lg mt-1">CMV</p>
              </CardBody>
            </Card>
          </Grid>
      </Grid>
    </Grid>
  );
}
