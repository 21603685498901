import React, { useState } from 'react';

export const ProductsManagementFiltersContext = React.createContext(12);

export default function ProductsManagementFiltersProvider({ children }) {
  const [weeksInterval, setWeeksInterval] = useState(12);
  const [category, setCategory] = useState(undefined);
  const [subCategory, setSubCategory] = useState(undefined);
  const [segment, setSegment] = useState(undefined);
  const [brandsList, setBrandsList] = useState([]);

  return (
    <ProductsManagementFiltersContext.Provider value={{
      weeksInterval,
      setWeeksInterval,
      category,
      setCategory,
      subCategory,
      setSubCategory,
      segment,
      setSegment,
      brandsList,
      setBrandsList
    }}>
      {children}
    </ProductsManagementFiltersContext.Provider>
  );
}
