import api, { useFetch } from './api';
import qs from 'qs';

export function getProducts() {
  return api.get('product');
}

export function getCurrentMonthProducts(page = 1, extraParams = {}) {
  const params = qs.stringify({ page, ...extraParams });

  return api.get(`product/current_month?${params}`);
}

export function getLastDayProducts(page = 1, extraParams = {}) {
  const params = qs.stringify({ page, ...extraParams });
  
  return api.get(`product/last_day?${params}`);
}

export function useCategoriesAndSegments() {
  return useFetch('get', '/product/categories-and-segments');
}
