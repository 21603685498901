import React, { useState, useRef, useContext } from "react";
import { Button, Grid, ButtonGroup } from "@material-ui/core";
import {
  Card,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import Brand from "./Brand";
import Item from "./Item";
import ExportPopover from "../../Common/ExportPopover";
import { formatStoresToId } from "../../../helpers";
import { useSelectedStores } from "../../../hooks/stores";
import { exportLowTurnProducts } from "../../../services/product-management";
import fileDownload from "js-file-download";
import moment from "moment";
import { ProductsManagementFiltersContext } from "../../../contexts/ProductsManagementFiltersProvider";

const types = {
  BRAND: "brand",
  ITEM: "item"
};

export default function LowTurn() {
  const { weeksInterval, category, subCategory, segment } = useContext(ProductsManagementFiltersContext);
  const [type, setType] = useState(types.ITEM);
  const [exporting, setExporting] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [exportHandle, setExportHandle] = useState(null);
  const selectedStores = useSelectedStores();
  const containerRef = useRef();

  const openExportHandle = e => {
    setExportHandle(e.currentTarget);
  };

  const closeExportHandle = () => {
    setExportHandle(null);
  };

  const handleExport = async extension => {
    setExporting(extension);
    const storesId = formatStoresToId(selectedStores);

    try {
      const res = await exportLowTurnProducts(extension, storesId, weeksInterval, category, subCategory, segment);
      fileDownload(res.data, `LT${moment().format("YYYYMMDD")}.${extension}`);
    } catch (e) {
      setErrorAlert(true);
    }
    setExporting(null);
  };

  return (
    <>
      <Card ref={containerRef}>
        <CardHeader
          title=""
          toolbar={
            <CardHeaderToolbar className="toolbar-container d-print-none">
              <ButtonGroup style={{ padding: "5px 15px 5px 0" }}>
                <Button
                  color="secondary"
                  onClick={() => setType(types.ITEM)}
                  variant={type === types.ITEM ? "contained" : "outlined"}
                >
                  Item
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setType(types.BRAND)}
                  variant={type === types.BRAND ? "contained" : "outlined"}
                >
                  Marca
                </Button>
              </ButtonGroup>
              <ExportPopover
                open={exportHandle}
                handleOpenExport={openExportHandle}
                handleCloseExport={closeExportHandle}
                handleExport={handleExport}
                exporting={exporting}
              />
            </CardHeaderToolbar>
          }
        />
        {type === types.BRAND && <Brand />}
        {type === types.ITEM && <Item />}
      </Card>
    </>
  );
}
