import { Button, Grid, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import './styles.scss';

export default function StoresList({ stores, errors, handleUpdateStore, addStore, removeStore }) {
  return (
    <>
      <Grid container spacing={2}>
        {!stores.length && <Grid item xs={12} className="text-center">Nenhuma filial.</Grid>}

        {stores.map((store, index) => (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={5}>
              <TextField
                label="Nome"
                variant="outlined"
                value={store.value}
                onChange={(e) => handleUpdateStore({ ...store, name: e.target.value }, index)}
                error={!!errors[`stores.${index}.name`]}
                helperText={errors[`stores.${index}.name`]}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Slug"
                variant="outlined"
                value={store.value}
                onChange={(e) => handleUpdateStore({ ...store, slug: e.target.value }, index)}
                helperText={errors[`stores.${index}.slug`] || 'Código da loja'}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Depósito"
                variant="outlined"
                value={store.value}
                onChange={(e) => handleUpdateStore({ ...store, deposit: e.target.value }, index)}
                helperText={errors[`stores.${index}.deposit`] || 'Código do depósito'}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} className="remove-button-container">
              <button title="Remover" className="icon-button danger" onClick={() => removeStore(index)}>
                <DeleteIcon />
              </button>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12} className="button-container">
          <Button
            color="secondary"
            variant="contained"
            onClick={addStore}
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
