import clsx from "clsx";
import moment from "moment";
import React from "react";

export function createYupErrorsObject(error) {
  return error.inner.reduce((prev, curr) => {
    const path = curr.path.replace(/\[/g, ".").replace(/\]/g, "");

    return { ...prev, [path]: curr.errors[0] };
  }, {});
}

export function formatDate(date) {
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  }).format(date);
}

export function getOnlyNumbers(str) {
  return str.replace(/\D/g, "");
}

/**
 * @param {object[]} collection
 * @param {String} dateKey
 *
 * @return {object}
 */
export function groupByDate(collection, dates, groupDateFormatExtractor) {
  const groups = dates.reduce(
    (groups, dateString) => ({ ...groups, [dateString]: null }),
    {}
  );

  Object.keys(groups).map(group => {
    const [, month, day] = group.split('-');
    const groupName = `${month}-${day}`;

    const records = collection.filter(
      record => groupName === groupDateFormatExtractor(record)
    );

    if (records.length) {
      groups[group] = records[0];
    }
  });

  return groups;
}

/**
 * @param {String} start
 * @param {String} end
 *
 * @return {String[]}
 */
export function getMonthsBetween(start, end, format = "YYYY-MM") {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
    timeValues.push(dateStart.format(format));
    dateStart.add(1, "month");
  }

  return timeValues;
}

/**
 * @param {String} start
 * @param {String} end
 *
 * @return {String[]}
 */
export function getYearsBetween(start, end) {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const timeValues = [];

  while (
    dateEnd > dateStart ||
    dateStart.format("YYYY") === dateEnd.format("YYYY")
  ) {
    timeValues.push(dateStart.format("YYYY"));
    dateStart.add(1, "year");
  }

  return timeValues;
}

export function getDaysBetween(start, end) {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const timeValues = [];

  while (
    dateEnd > dateStart ||
    dateStart.format("MM-DD") === dateEnd.format("MM-DD")
  ) {
    timeValues.push(dateStart.format("YYYY-MM-DD"));
    dateStart.add(1, "day");
  }

  return timeValues;
}

export function getHoursBetween(start, end) {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const timeValues = [];

  while (
    dateEnd > dateStart ||
    dateStart.format("HH") === dateEnd.format("HH")
  ) {
    timeValues.push(dateStart.format("HH"));
    dateStart.add(1, "hour");
  }

  return timeValues;
}

export function prettyMoney(value, options = {}) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    ...options
  }).format(value);
}

export function prettyMoneyWithoutSymbol(value) {
  return new Intl.NumberFormat("pt-BR", {
    currency: "BRL"
  }).format(value);
}

export function getClosestPreviousDate(dateStr, datesStrs) {
  const date = new Date(dateStr);
  const dates = datesStrs.map(str => new Date(str));
  const previousDates = dates.filter(comparisonDate => comparisonDate < date);
  const closestDate = previousDates.reduce((prev, curr) => {
    const prevDiff = date - prev.Date;
    const currDiff = date - curr.Date;

    return prevDiff < currDiff ? curr : prev;
  });

  return closestDate;
}

export function formatStoresToId(stores) {
  let storesId = [];
  if (stores && stores.length > 0) {
    stores.map(store => {
      storesId = [...storesId, store.id];
    });
  }
  return storesId;
}

export function prettyPercentage(decimal, fractionDigits = 1) {
  return isFinite(decimal) ? ((parseFloat(decimal) * 100).toFixed(fractionDigits) + "%") : "-";
}

export function decimalMoney(value, options = {}) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    ...options
  })
    .format(value)
    .replace("R$ ", "");
}

export function formatIntNumber(number) {
  return parseInt(number || 0).toLocaleString();
}

export function comparisonPercentage(a, b) {
  const equalReturn = <span className="font-size-lg">-</span>;

  if (a === b || a == 0 || b == 0) {
    return equalReturn;
  }

  const percentage = a / b;
  let value;
  let sign;
  let colorClass;

  if (percentage > 1) {
    value = percentage - 1;
    sign = "+";
    colorClass = "text-success";
  } else if (percentage < 1) {
    value = 1 - percentage;
    sign = "-";
    colorClass = "text-danger";
  }

  if (percentage === 1) {
    return equalReturn;
  }

  return (
    <span className={clsx("font-size-lg", colorClass)}>
      {sign}
      {prettyPercentage(value)}
    </span>
  );
}

export function capitalize(s) {
  if (typeof s !== "string") return "";

  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function truncate(str, maxSize, suffix = '...') {
  if (str.length > maxSize) {
    return `${str.substring(0, maxSize)}${suffix}`;
  }

  return str;
}

export const itemsNumber = (number, zero = '-') => number
    ? `${number.toString().padStart(2, '0')} ${number === 1 ? 'item' : 'itens'}`
    : zero;

export const hasDecimal = (n) => Math.floor(n) !== parseFloat(n);

export const roundSign = (n) => hasDecimal(n) ? '±' : '';

export function syncScrollBetween(el1, el2) {
  el1.addEventListener('scroll', function () {
    el2.scrollLeft = this.scrollLeft;
  });
}
