import React from 'react';
import MaskedInput from 'react-text-mask';

export default function CnpjInputMask({ inputRef, ...rest }) {
  return <MaskedInput
    {...rest}
    ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
    }}
    mask={(rawValue) => {
      return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    }}
    placeholderChar={'\u2000'}
  />
}
