import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  withStyles
} from "@material-ui/core";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { ProductsManagementFiltersContext } from "../../../contexts/ProductsManagementFiltersProvider";
import {
  formatStoresToId,
  prettyMoney,
  prettyPercentage
} from "../../../helpers";
import { useSelectedStores } from "../../../hooks/stores";
import { getBrandsAbcAnalysis } from "../../../services/abc";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle
} from "../../../_metronic/_partials/controls";
import InfoTooltip from "../../Common/InfoTooltip";
import Pagination from "../../Common/Pagination";
import Summary from "./Summary";
import TableHeadCurve from "./TableHeadCurve";

const sumBrandCurves = (brand, property) => {
  return Object.keys(brand).reduce((carry, curve) => {
    return carry + brand[curve][property];
  }, 0);
};

const sumBrandCurvesValue = brand => {
  return Object.keys(brand).reduce((carry, curve) => {
    return carry + brand[curve].needed_value;
  }, 0);
};

const sumBrandCurvesItems = brand => {
  return Object.keys(brand).reduce((carry, curve) => {
    return carry + Math.floor(brand[curve].unique);
  }, 0);
};

const getNecessaryItemsForCurve = (brands, curve) => {
  const items = Object.keys(brands).reduce((carry, brandName) => {
    return carry + (brands[brandName][curve]?.unique || 0);
  }, 0);

  return Math.floor(items);
};

const TableCell = withStyles(() => ({
  root: {
    padding: 6,
    border: 0
  }
}))(MuiTableCell);

function BrandCell({ brand, neededQuantity, neededValue, onClick }) {
  const [hoverActive, setHoverActive] = useState(false);
  const background = hoverActive ? "bg-primary" : "bg-light-primary";
  const textColor = hoverActive ? "text-light" : "";

  const toggleHover = () => setHoverActive(!hoverActive);

  return (
    <TableCell
      className={`${background} ${textColor} p-3 d-flex align-items-center cursor-pointer`}
      onMouseOver={toggleHover}
      onMouseOut={toggleHover}
      onClick={onClick}
    >
      <InfoTooltip
        text={`O valor da Sugestão de Compra desta Marca é de ${prettyMoney(
          neededValue
        )} para ${neededQuantity} ${neededQuantity === 1 ? "item" : "itens"}.`}
        margin="mr-2"
        light={hoverActive}
      />
      <span className="font-size-lg font-weight-bolder">
        {brand || "Sem marca"}
      </span>
    </TableCell>
  );
}

function BrandsAbcAnalysis(
  {
    curvesTotalSoldValue,
    curvesTotalSoldQuantity,
    curvesStockQuantity,
    curvesTotalGrossProfit,
    curvesStockValue,
    totalSoldValue,
    handleSetCurrentBuyNecessityCurveData,
    totalGrossProfit,
    stockValue,
    show = true
  },
  ref
) {
  const { weeksInterval, category, subCategory, segment, brandsList, setBrandsList } = useContext(
    ProductsManagementFiltersContext
  );
  const [brands, setBrands] = useState(null);
  const [totals, setTotals] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedStores = useSelectedStores();

  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [cachedPages, setCachedPages] = useState({});

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const fetchBrandsNeeds = async (page = 1) => {
    setLoading(true);

    const params = {
      weeks: weeksInterval,
      category,
      subcategory: subCategory,
      segment
    };

    if (selectedStores.length) {
      params.stores = formatStoresToId(selectedStores).join(",");
    }

    try {
      const { data } = await getBrandsAbcAnalysis(page, params);

      setBrandsList(Object.keys(data.data));
      setBrands(data.data);
      setTotals(data.totals);
      cachePage(page, data.data);
      setPages(data.pages);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;

    console.log(`Current page inside useEffect: ${currentPage}`); // Debug log

    if (cachedPages.hasOwnProperty(currentPage) && isMounted) {
      setBrands(cachedPages[currentPage]);
      return;
    }

    fetchBrandsNeeds(currentPage);

    return () => { isMounted = false };

  }, [selectedStores, weeksInterval, category, subCategory, segment, currentPage]);



  const itemsNumber = (number, zero = "-") =>
    number
      ? `${number.toString().padStart(2, "0")} ${
          number === 1 ? "item" : "itens"
        }`
      : zero;

  const curveGrossProfit = curve => {
    if (totals) {
      return totals[curve].gross_profit;
    }

    return 0;
  };
  const curveStockQuantity = curve => {
    if (totals) {
      return totals[curve].stock_quantity;
    }

    return 0;
  };
  const curveStockValue = curve => {
    if (totals) {
      return totals[curve].stock_value;
    }

    return 0;
  };
  const curveNeededValue = curve => {
    if (totals) {
      return totals[curve].needed_value;
    }

    return 0;
  };

  const totalNeededItems = ["a", "b", "c"].reduce(
    (carry, curve) => carry + getNecessaryItemsForCurve(brands || {}, curve),
    0
  );
  const totalCurvesSoldValue = ["a", "b", "c"].reduce(
    (carry, curve) => carry + curvesTotalSoldValue[curve],
    0
  );
  const totalCurvesGrossProfit = ["a", "b", "c"].reduce(
    (carry, curve) => carry + curveGrossProfit(curve),
    0
  );
  const totalCurvesStockQuantity = ["a", "b", "c"].reduce(
    (carry, curve) => carry + curveStockQuantity(curve),
    0
  );
  const totalCurvesStockValue = ["a", "b", "c"].reduce(
    (carry, curve) => carry + curveStockValue(curve),
    0
  );
  const totalNeededValue = ["a", "b", "c"].reduce(
    (carry, curve) => carry + curveNeededValue(curve),
    0
  );

  const handleChangeCurve = curve => {
    handleSetCurrentBuyNecessityCurveData({
      items: getNecessaryItemsForCurve(brands, curve),
      totalSoldQuantity: curvesTotalSoldQuantity[curve],
      totalSoldValue: curvesTotalSoldValue[curve],
      stockQuantity: curvesStockQuantity[curve],
      grossProfit: curvesTotalGrossProfit[curve],
      stockValue: curvesStockValue[curve],
      ordersPercentage: prettyPercentage(
        totalSoldValue ? curvesTotalSoldValue[curve] / totalSoldValue : 0
      ),
      stockPercentage: totals ? totals[curve].stock_part : 0,
      cost: totals ? totals[curve].cost : 0,
      curve
    });
  };

  const handleBrandChange = (brand, data) => {
    const brandTotalSoldValue = sumBrandCurves(data, "value");

    handleSetCurrentBuyNecessityCurveData({
      brand,
      data,
      items: sumBrandCurves(data, "unique"),
      totalSoldQuantity: sumBrandCurves(data, "sold_quantity"),
      totalSoldValue: brandTotalSoldValue,
      stockQuantity: sumBrandCurves(data, "stock_quantity"),
      grossProfit: sumBrandCurves(data, "profit"),
      stockValue: sumBrandCurves(data, "stock"),
      ordersPercentage: totalSoldValue
        ? brandTotalSoldValue / totalSoldValue
        : 0,
      cost: sumBrandCurves(data, "cost")
    });
  };

  useImperativeHandle(ref, () => ({
    changeCurve: curve => handleChangeCurve(curve)
  }));

  return (
    show && (
      <Grid item xs={12}>
        <Card className="card-heavy-border">
          <CardHeader
            title={
              <>
                <CardHeaderTitle className="card-header-with-icon">
                  <LocalOfferIcon />
                  <span>
                    Produtos com Necessidade de Reposição – Sugestão de Compra
                  </span>
                </CardHeaderTitle>
                <div className="font-size-sm text-muted mt-2">
                  Aqui você encontra os itens que precisam{" "}
                  <strong>rapidamente</strong> da <strong>reposição</strong> dos
                  estoques.
                </div>
              </>
            }
          />
          <CardBody>
            {loading && (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}

            {!loading && !!brands && !Object.keys(brands).length && (
              <div className="empty-list">Nenhum dado encontrado.</div>
            )}

            {!loading && !!brands && !!Object.keys(brands).length && (
              <>
                <Grid container justify="center">
                  <Summary
                    items={totalNeededItems}
                    itemsValue={totalNeededValue}
                    sellsPart={
                      totalSoldValue ? totalCurvesSoldValue / totalSoldValue : 0
                    }
                    sellsValue={totalCurvesSoldValue}
                    grossProfitPart={
                      totalGrossProfit
                        ? totalCurvesGrossProfit / totalGrossProfit
                        : 0
                    }
                    grossProfitValue={totalCurvesGrossProfit}
                    stockValue={totalCurvesStockValue}
                    stockPart={
                      stockValue ? totalCurvesStockValue / stockValue : 0
                    }
                  />
                </Grid>
                <Grid item xs={12} style={{ padding: 25 }}>
                  <div className="overflow-auto">
                    <Table>
                      <TableHead>
                        <TableRow className="mb-10">
                          <TableCell className="text-left">
                            <span
                              className="font-size-lg font-weight-bolder"
                              style={{ marginLeft: 26, alignSelf: "flex-end" }}
                            >
                              Marca
                            </span>
                          </TableCell>
                          <TableCell>
                            <TableHeadCurve
                              curve="A"
                              color="curve-a"
                              items={getNecessaryItemsForCurve(brands, "a")}
                              orders={prettyPercentage(
                                totalSoldValue
                                  ? curvesTotalSoldValue.a / totalSoldValue
                                  : 0
                              )}
                              neededValue={totals?.a.needed_value || 0}
                              stockPart={totals?.a.stock_part || 0}
                              grossProfit={totals?.a.gross_profit || 0}
                              onClick={() => handleChangeCurve("a")}
                            />
                          </TableCell>
                          <TableCell>
                            <TableHeadCurve
                              curve="B"
                              color="curve-b"
                              items={getNecessaryItemsForCurve(brands, "b")}
                              orders={prettyPercentage(
                                totalSoldValue
                                  ? curvesTotalSoldValue.b / totalSoldValue
                                  : 0
                              )}
                              neededValue={totals?.b.needed_value || 0}
                              stockPart={totals?.b.stock_part || 0}
                              grossProfit={totals?.b.gross_profit || 0}
                              onClick={() => handleChangeCurve("b")}
                            />
                          </TableCell>
                          <TableCell>
                            <TableHeadCurve
                              curve="C"
                              color="curve-c"
                              items={getNecessaryItemsForCurve(brands, "c")}
                              orders={prettyPercentage(
                                totalSoldValue
                                  ? curvesTotalSoldValue.c / totalSoldValue
                                  : 0
                              )}
                              neededValue={totals?.c.needed_value || 0}
                              stockPart={totals?.c.stock_part || 0}
                              grossProfit={totals?.c.gross_profit || 0}
                              onClick={() => handleChangeCurve("c")}
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="p-3 text-right">
                            <span
                              className="font-size-lg font-weight-bolder"
                              style={{ marginRight: -20 }}
                            >
                              Total
                            </span>
                          </TableCell>
                          <TableCell className="text-center">
                            <span className="font-size-lg font-weight-bolder">
                              {itemsNumber(
                                getNecessaryItemsForCurve(brands, "a"),
                                "0 itens"
                              )}
                            </span>
                          </TableCell>
                          <TableCell className="text-center">
                            <span className="font-size-lg font-weight-bolder">
                              {itemsNumber(
                                getNecessaryItemsForCurve(brands, "b"),
                                "0 itens"
                              )}
                            </span>
                          </TableCell>
                          <TableCell className="text-center">
                            <span className="font-size-lg font-weight-bolder">
                              {itemsNumber(
                                getNecessaryItemsForCurve(brands, "c"),
                                "0 itens"
                              )}
                            </span>
                          </TableCell>
                          <TableCell className="text-center p-3 text-center">
                            <span className="font-size-lg font-weight-bolder">
                              {itemsNumber(
                                getNecessaryItemsForCurve(brands, "a") +
                                  getNecessaryItemsForCurve(brands, "b") +
                                  getNecessaryItemsForCurve(brands, "c"),
                                "0 itens"
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                        {Object.keys(brands).map(brandName => (
                          <TableRow key={brandName}>
                            <BrandCell
                              brand={brandName}
                              neededQuantity={sumBrandCurvesItems(
                                brands[brandName]
                              )}
                              neededValue={sumBrandCurvesValue(
                                brands[brandName]
                              )}
                              onClick={() =>
                                handleBrandChange(brandName, brands[brandName])
                              }
                            />
                            <TableCell className="text-center">
                              <span className="font-size-lg">
                                {itemsNumber(
                                  Math.floor(brands[brandName].a?.unique || 0)
                                )}
                              </span>
                            </TableCell>
                            <TableCell className="text-center">
                              <span className="font-size-lg">
                                {itemsNumber(
                                  Math.floor(brands[brandName].b?.unique || 0)
                                )}
                              </span>
                            </TableCell>
                            <TableCell className="text-center">
                              <span className="font-size-lg">
                                {itemsNumber(
                                  Math.floor(brands[brandName].c?.unique || 0)
                                )}
                              </span>
                            </TableCell>
                            <TableCell className="bg-light-primary p-3 text-center">
                              <span className="font-size-lg font-size-lg font-weight-bolder">
                                {itemsNumber(
                                  sumBrandCurvesItems(brands[brandName])
                                )}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              </>
            )}

            {!loading && !!brands && !!Object.keys(brands).length && (
              <div className="mt-4">
                <Pagination
                  page={currentPage}
                  count={pages}
                  onChange={onChangePaginationHandler}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </Grid>
    )
  );
}

export default forwardRef(BrandsAbcAnalysis);
