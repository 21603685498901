import React from 'react';
import DailyOrders from '../../Results/DailyOrders';
import Yearly from '../../Results/Yearly';

export default function Financial({ period, vision, setVision }) {
  return (
    period === 'current_month' ? <DailyOrders /> : <Yearly vision={vision} setVision={setVision} />
  );
}

