import { Button, ButtonGroup, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { ProductsManagementFiltersContext } from "../../../contexts/ProductsManagementFiltersProvider";
import { formatStoresToId } from "../../../helpers";
import { useSelectedStores } from "../../../hooks/stores";
import { getCurvesTotals } from "../../../services/abc";
import { exportToNeedToBuy } from "../../../services/product-management";
import ExportPopover from "../../Common/ExportPopover";
import Brands from "./Brands";
import Products from "./Products";

const types = {
  BRAND: "brand",
  ITEM: "item"
};

export default function NeedToBuy({
  curve,
  financialResultsData,
  setCurrentBuyNecessityCurveData,
  handleChangeCurve
}) {
  const { weeksInterval, category, subCategory, segment } = useContext(
    ProductsManagementFiltersContext
  );
  const [alertError, setAlertError] = useState(false);
  const [
    onlyProductsWithBuyNecessity,
    setOnlyProductsWithBuyNecessity
  ] = useState(true);
  const selectedStores = useSelectedStores();
  const containerRef = useRef(null);
  const [type, setType] = useState(types.ITEM);

  const [exporting, setExporting] = useState(null);
  const [exportHandle, setExportHandle] = useState(null);

  const openExportHandle = e => {
    setExportHandle(e.currentTarget);
  };

  const closeExportHandle = () => {
    setExportHandle(null);
  };

  const handleExport = async extension => {
    setExporting(extension);

    const storesId = formatStoresToId(selectedStores);

    try {
      const res = await exportToNeedToBuy(
        extension,
        storesId,
        weeksInterval,
        curve,
        category,
        subCategory,
        segment,
        null,
        onlyProductsWithBuyNecessity
      );
      fileDownload(res.data, `NB${moment().format("YYYYMMDD")}.${extension}`);
    } catch (e) {
      setAlertError(true);
    }
    setExporting(null);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseMessageError = () => {
    setAlertError(false);
  };

  const fetchTotals = async () => {
    try {
      const params = {
        weeks: weeksInterval,
        category,
        subcategory: subCategory,
        segment,
        only_with_buy_necessity: onlyProductsWithBuyNecessity ? 1 : 0
      };

      if (selectedStores.length) {
        params.stores = formatStoresToId(selectedStores).join(",");
      }

      const { data } = await getCurvesTotals(params);

      setCurrentBuyNecessityCurveData({
        items: data.totals[curve],
        totalSoldQuantity: data?.sold_quantity[curve],
        totalSoldValue: data?.total_value_without_discounts[curve],
        stockQuantity: data?.stock_quantity[curve],
        grossProfit: data?.gross_profit[curve],
        stockValue: data?.stock_value[curve],
        ordersPercentage: 100,
        stockPercentage: financialResultsData
          ? parseFloat(data?.stock_value[curve]) /
            parseFloat(financialResultsData.total_in_stock)
          : 0,
        cost: data?.cmv[curve],
        curve,
        totalTab: !onlyProductsWithBuyNecessity
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!onlyProductsWithBuyNecessity) {
      fetchTotals();
    } else {
      handleChangeCurve(curve);
    }
  }, [onlyProductsWithBuyNecessity]);

  useEffect(() => {
    if (!onlyProductsWithBuyNecessity) {
      setOnlyProductsWithBuyNecessity(true);
    }
  }, [curve]);

  return (
    <>
      <Card className="mt-5" ref={containerRef}>
        <CardHeader
          title={null}
          className="need-to-buy-header"
          toolbar={
            <CardHeaderToolbar className="d-print-none">
              <div>
                <ButtonGroup
                  className="d-print-none"
                  style={{ padding: "5px 15px 5px 0" }}
                >
                  <Button
                    color="secondary"
                    onClick={() => setOnlyProductsWithBuyNecessity(true)}
                    variant={
                      onlyProductsWithBuyNecessity ? "contained" : "outlined"
                    }
                  >
                    Com necessidade de compra
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setOnlyProductsWithBuyNecessity(false)}
                    variant={
                      !onlyProductsWithBuyNecessity ? "contained" : "outlined"
                    }
                  >
                    Todos
                  </Button>
                </ButtonGroup>
                <ButtonGroup style={{ padding: "5px 15px 5px 0" }}>
                  <Button
                    color="secondary"
                    onClick={() => setType(types.ITEM)}
                    variant={type === types.ITEM ? "contained" : "outlined"}
                  >
                    Item
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setType(types.BRAND)}
                    variant={type === types.BRAND ? "contained" : "outlined"}
                  >
                    Marca
                  </Button>
                </ButtonGroup>
              </div>
              <ExportPopover
                open={exportHandle}
                handleOpenExport={openExportHandle}
                handleCloseExport={closeExportHandle}
                handleExport={handleExport}
                exporting={exporting}
              />
            </CardHeaderToolbar>
          }
        />

        <CardBody>
          {type === types.ITEM && (
            <Products
              curve={curve}
              onlyProductsWithBuyNecessity={onlyProductsWithBuyNecessity}
            />
          )}
          {type === types.BRAND && (
            <Brands
              curve={curve}
              onlyProductsWithBuyNecessity={onlyProductsWithBuyNecessity}
            />
          )}
        </CardBody>
      </Card>
      <Snackbar
        open={alertError}
        autoHideDuration={2500}
        onClose={handleCloseMessageError}
      >
        <Alert onClose={handleCloseMessageError} severity="error">
          Erro ao carregar dados
        </Alert>
      </Snackbar>
    </>
  );
}
