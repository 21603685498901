import { Tooltip } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import clsx from "clsx";
import React, { useState } from "react";
import { prettyPercentage } from "../../../helpers";

export default function ComparisonTooltip({
  a,
  b,
  inverse = false,
  getPercentage,
  texts = {
    same: "Igual ao ano passado"
  }
}) {
  let text = "";
  const [open, setOpen] = useState(false);

  a = parseFloat(a);
  b = parseFloat(b);

  const percentage = a / b;

  let value;
  let sign;
  let colorClass;
  let icon;

  if (a === b) {
    text = texts.same;
    colorClass = "text-dark";
    icon = <TrendingFlatIcon size={12} className="text-dark" />;
  } else {
    if (a == 0) {
      value = 1;
      sign = "-";
      colorClass = inverse ? "text-success" : "text-danger";
      icon = inverse ? (
        <ArrowDownwardIcon size={12} className="text-success" />
      ) : (
        <ArrowDownwardIcon size={12} className="text-danger" />
      );
    } else if (b == 0) {
      value = 1;
      sign = "+";
      colorClass = inverse ? "text-danger" : "text-success";
      icon = inverse ? (
        <ArrowUpwardIcon size={12} className="text-danger" />
      ) : (
        <ArrowUpwardIcon size={12} className="text-success" />
      );
    } else if (percentage > 1) {
      value = percentage - 1;
      sign = "+";
      colorClass = inverse ? "text-danger" : "text-success";
      icon = inverse ? (
        <ArrowUpwardIcon size={12} className="text-danger" />
      ) : (
        <ArrowUpwardIcon size={12} className="text-success" />
      );
    } else if (percentage < 1) {
      value = 1 - percentage;
      sign = "-";
      colorClass = inverse ? "text-success" : "text-danger";
      icon = inverse ? (
        <ArrowDownwardIcon size={12} className="text-success" />
      ) : (
        <ArrowDownwardIcon size={12} className="text-danger" />
      );
    }

    if (percentage === 1) {
      text = texts.same;
      colorClass = "text-dark";
      icon = <TrendingFlatIcon size={12} className="text-dark" />;
    } else if (a !== b) {
      text = `${sign}${prettyPercentage(value)}`;
    }
  }

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      onClose={handleTooltipClose}
      onMouseEnter={handleTooltipOpen}
      open={open}
      disableFocusListener
      disableTouchListener
      title={`${text}${!!texts.obs ? ` (${texts.obs})` : ""}`}
      classes={{
        tooltip: clsx("bg-light", "h6", "p-3", colorClass)
      }}
      style={{ cursor: "pointer" }}
    >
      {icon}
    </Tooltip>
  );
}
