import api from "./api";

export function getClientIndicator(storesId) {
  return api.get(`client`, {
    params: {
      stores_id: storesId
    }
  });
}

export function getClientIndicatorYearly(storesId) {
  return api.get(`client/yearly`, {
    params: {
      stores_id: storesId
    }
  });
}

export function exportTo(extension, storesId, type) {
  return api.get(`client/export/${extension}`, {
    responseType: "blob",
    params: {
      stores_id: storesId,
      type
    }
  });
}
