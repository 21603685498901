import React, { useEffect, useState } from "react";
import useModule from "../hooks/module";
import { SiteRoutes } from "./SiteRoutes";
import { AdminRoutes } from "./AdminRoutes";
import useAuth from "../hooks/auth";
import { actions as authActions } from '../redux/Auth';
import { useDispatch } from "react-redux";

export function Routes() {
  const module = useModule();
  const auth = useAuth();
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.user && auth.authToken) {
      dispatch(authActions.login(auth.authToken));
    }

    if (!auth.authToken || (auth.user && auth.authToken)) {
      setLoaded(true);
    }
  }, [auth.user, auth.authToken]);

  if (!loaded) {
    return <></>;
  }
  
  switch (module) {
    case "admin":
      return <AdminRoutes />;
    case "user":
      return <SiteRoutes />;
    default:
      return <></>;
  }
}
