import api from "./api";

export function getSalesReceipt(storesId) {
  return api.get(`sales-receipt`, {
    params: {
      stores_id: storesId
    }
  });
}

export function getSalesReceiptYearly(storesId) {
  return api.get(`sales-receipt/yearly`, {
    params: {
      stores_id: storesId
    }
  });
}

export function exportTo(extension, storesId, type) {
  return api.get(`sales-receipt/export/${extension}`, {
    responseType: "blob",
    params: {
      stores_id: storesId,
      type
    }
  });
}
