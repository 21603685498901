import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { MenuItem, Popover, Button, CircularProgress } from "@material-ui/core";
import { CardHeaderToolbar } from "../../../_metronic/_partials/controls";
import "./styles.scss";

export default function ExportPopover({
  open,
  handleOpenExport,
  handleCloseExport,
  handleExport,
  exporting = false
}) {
  return (
    <CardHeaderToolbar className="d-print-none">
      <Button
        className="export-button bg-white"
        variant="outlined"
        onClick={handleOpenExport}
      >
        Exportar {!!open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Button>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseExport}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        classes={{
          paper: "export-popover-paper"
        }}
      >
        <MenuItem
          onClick={() => handleExport("xlsx")}
          className="menu-item d-flex justify-content-between"
          disabled={exporting}
        >
          <span>Excel</span>
          {exporting === "xlsx" && <CircularProgress size={16} />}
        </MenuItem>
        <MenuItem
          onClick={() => handleExport("csv")}
          className="menu-item d-flex justify-content-between"
          disabled={exporting}
        >
          <span>CSV</span>
          {exporting === "csv" && <CircularProgress size={16} />}
        </MenuItem>
      </Popover>
    </CardHeaderToolbar>
  );
}
