import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useAuth from '../../../hooks/auth';

export default function AnonRoute({ redirectTo, role, ...props }) {
  const auth = useAuth();

  return (
    !auth || auth.user?.role !== role ? <Route {...props} /> : <Redirect to={redirectTo} />
  );
}
