/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useStores, useSelectedStores } from "../../../../../hooks/stores";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useDispatch } from "react-redux";
import { actions } from "../../../../../redux/Stores";
import CheckIcon from '@material-ui/icons/Check';

export function StoresDropdown() {
  const currentStores = useSelectedStores();
  const stores = useStores();
  const dispatch = useDispatch();

  const setCurrentStore = store => {
    let setToSelect = true;
    currentStores.map(currentStore => {
      if (currentStore.id === store.id) {
        dispatch(actions.deleteSelectedStore(currentStore.id));
        setToSelect = false;
        return;
      }
    });
    setToSelect && dispatch(actions.setSelectedStore(store));
  };

  const handleSelectStore = (store) => (e) => {
    e.preventDefault();
    setCurrentStore(store);
  }

  const isSelected = storeId => !!currentStores.filter((store) => store.id === storeId).length;

  return (
    <Dropdown drop="down" alignRight className="mr-4">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="language-panel-tooltip">Selecione filiais</Tooltip>
          }
        >
          <div className="btn btn-icon btn-hover-transparent-white btn-lg px-md-2 w-md-auto">
            <span className="text-white text-center opacity-90 font-weight-bolder font-size-base">
              {currentStores?.length > 0
                ? "Filtrando análises por: " + currentStores.map(value => " " + value?.name)
                : "Filtrar análises por filiais"}
            </span>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
        <ul className="navi navi-hover py-4">
          {typeof stores !== undefined &&
            stores?.map(store => (
              <li
                key={store.id}
                className={clsx("navi-item", {
                  active: store.id === currentStores?.id
                })}
              >
                <a
                  href="#"
                  onClick={handleSelectStore(store)}
                  className="navi-link d-flex"
                >
                  <span className="navi-text">{store.name}</span>
                  {isSelected(store.id) && <CheckIcon />}
                </a>
              </li>
            ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
