import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { StoresDropdown } from "../extras/dropdowns/StoresDropdown";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function Topbar({ showSelectStores, showApiAccess = true }) {
  const uiService = useHtmlClassService();
  const {user} = useSelector(state => state.auth);

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display")
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {showSelectStores && !!user?.saas_business?.coverage && (
        <div className="topbar-item mr-4">
          <Link to="/account/saas_business" className="btn btn-icon btn-hover-transparent-white btn-lg px-md-2 w-md-auto">
            <span className="text-white text-center opacity-90 font-weight-bolder font-size-base">
              Cobertura de estoque: {user.saas_business.coverage}
            </span>
          </Link>
        </div>
      )}
      {showSelectStores && <StoresDropdown />}
      {layoutProps.viewUserDisplay && <QuickUserToggler showApiAccess={showApiAccess} />}
    </div>
  );
}
