import React, { useContext, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { MenuItem, Popover, CircularProgress, Tooltip } from "@material-ui/core";
import { CardHeaderToolbar } from "../../../_metronic/_partials/controls";
import { exportInconsistencies } from "../../../services/export";
import { useSelectedStores } from "../../../hooks/stores";
import clsx from "clsx";
import './styles.scss';
import { ProductsManagementFiltersContext } from "../../../contexts/ProductsManagementFiltersProvider";

export default function ExportInconsistenciesPopover({ hasInconsistencies }) {
  const { weeksInterval } = useContext(ProductsManagementFiltersContext);
  const [hasError, setHasError] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [anchor, setAnchor] = useState(undefined);
  const selectedStores = useSelectedStores();
  const open = !!anchor;
  
  const handleExport = async (extension) => {
    setExporting(extension);

    try {
      await exportInconsistencies(extension, weeksInterval, selectedStores);
    } catch (e) {
      setHasError(true);
    }

    setExporting(false);
  };

  const handleOpen = e => {
    setAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchor(undefined);
  };

  return (
    <CardHeaderToolbar className="d-print-none">
      <Tooltip
        title={
          hasInconsistencies
          ? 'Há inconsistências nos dados de estoque de alguns produtos.'
          : 'Não há inconsistências nos dados dos produtos.'
        }
        classes={{
          tooltip: 'export-inconsistencies-tooltip'
        }}
      >
        <button
          onClick={handleOpen}
          className={clsx("btn", "font-weight-bold", "py-3", "px-6", 'd-flex', 'align-items-center', {
            'btn-white': !hasInconsistencies,
            'btn-danger': hasInconsistencies,
          })}
          disabled={!hasInconsistencies}
        >
          Relatório de inconsistências
          {hasInconsistencies && (
            open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          )}
        </button>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        classes={{
          paper: "export-popover-paper"
        }}
      >
        <MenuItem
          onClick={() => handleExport("xlsx")}
          className="menu-item d-flex justify-content-between"
          disabled={exporting}
        >
          <span>Excel</span>
          {exporting === "xlsx" && <CircularProgress size={16} />}
        </MenuItem>
        <MenuItem
          onClick={() => handleExport("csv")}
          className="menu-item d-flex justify-content-between"
          disabled={exporting}
        >
          <span>CSV</span>
          {exporting === "csv" && <CircularProgress size={16} />}
        </MenuItem>
      </Popover>
    </CardHeaderToolbar>
  );
}
