import {
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { decimalMoney, formatStoresToId, prettyMoney } from "../../../../../helpers";
import { useSelectedStores } from "../../../../../hooks/stores";
import { getClientIndicator } from "../../../../../services/client";
import {
  dLessOneLastYearMoment, dLessOneMoment,
  firstOfMonthMoment
} from "../../../../../services/date-filters";
import {
  Card,
  CardBody,
  CardHeader, CardHeaderTitle, LoadingDialog
} from "../../../../../_metronic/_partials/controls";
import Comparison from "../../../../Common/Comparison";
import InfoTooltip from "../../../../Common/InfoTooltip";

const TIMES = {
  DAY: {
    PREVIOUS_DAY: dLessOneMoment.format("DD MMM YY"),
    PREVIOUS_DAY_LAST_YEAR: dLessOneLastYearMoment.format("DD MMM YY")
  },
  MONTH: {
    CURRENT_MONTH: dLessOneMoment.format("MMM YYYY"),
    CURRENT_MONTH_LAST_YEAR: dLessOneLastYearMoment.format("MMM YYYY")
  }
};

const ITEMS = {
  TICKET_NUMBER: "Nº Tickets",
  TICKET_MEDIA: "Ticket Médio",
  QUANTITY_MEDIA: "QTD média PROD vendidos",
  PRODUCT_VALUE_MEDIA: "Valor médio PROD vendidos",
  VALUE_MEDIA_PER_DAY: "Venda média dia",
  TICKET_MEDIA_DAY: "Nº tickets média dia",
  DAYS_WITH_SALES: "Nº dias com vendas"
};

const ITEMS_DESCRIPTION = {
  QUANTITY_MEDIA: "QTD média de produtos vendidos por ticket",
  PRODUCT_VALUE_MEDIA: "Valor médio de produtos vendidos",
  VALUE_MEDIA_PER_DAY: "Valor médio da venda por dia",
  TICKET_MEDIA_DAY: "Média de Nº de tickets por dia",
  DAYS_WITH_SALES: "Nº de dias com vendas realizadas"
};

export default function CurrentMonth() {
  const [alertError, setAlertError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const currentStores = useSelectedStores();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseAlertError = () => {
    setAlertError(false);
  };

  const loadClientIndicator = async storesId => {
    setLoading(true);
    try {
      const res = await getClientIndicator(storesId);
      setData(res.data);
    } catch (e) {
      setAlertError(true);
    }
    setLoading(false);
  };

  const getCardTitle = period => {
    switch (period) {
      case "DAY":
        return (
          <>
            <CardHeaderTitle className="card-header-with-icon">
              <CompareArrowsIcon />
              <span>
                Clientes <br />
                {dLessOneMoment.format("DD MMM YY")}
              </span>
            </CardHeaderTitle>
            <div className="font-size-sm text-muted mt-2">
              Comparação dos resultados de {dLessOneMoment.format("DD MMM YY")}{" "}
              com o mesmo período do ano anterior.
            </div>
          </>
        );
      case "MONTH":
        return (
          <>
            <CardHeaderTitle className="card-header-with-icon">
              <CalendarTodayIcon />
              <span>
                Clientes <br />
                {firstOfMonthMoment.format("DD")} a{" "}
                {dLessOneMoment.format("DD MMM YY")}
              </span>
            </CardHeaderTitle>
            <div className="font-size-sm text-muted mt-2">
              Comparação dos resultados de{" "}
              <strong>{firstOfMonthMoment.format("DD")}</strong> a{" "}
              <strong>{dLessOneMoment.format("DD MMM YY")}</strong> com o mesmo
              período do ano anterior.
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const storesId = formatStoresToId(currentStores);
    loadClientIndicator(storesId);
  }, [currentStores]);
console.log(data)
  return (
    <>
      {loading ? (
        <LoadingDialog isLoading={loading} text="Carregando..." />
      ) : (
        <>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {Object.keys(TIMES).map(time => (
                <>
                  <Grid item xs={12} md={6}>
                    <Card className="card-heavy-border" style={{ minHeight: '100%' }}>
                      <CardHeader title={getCardTitle(time)} />
                      <CardBody>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ border: 0, padding: 6 }} />
                                {Object.keys(TIMES[time]).map((period, idx) => (
                                  <TableCell
                                    key={idx}
                                    style={{ border: 0, padding: 6 }}
                                  >
                                    <span className="font-size-lg">
                                      <strong>{TIMES[time][period]}</strong>
                                    </span>
                                  </TableCell>
                                ))}
                                <TableCell
                                  style={{ border: 0, padding: 6 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data && Object.keys(data).length > 0 ? (
                                Object.keys(ITEMS).map((item, itemIdx) => (
                                  <TableRow key={itemIdx}>
                                    <TableCell
                                      style={{
                                        border: 0,
                                        padding: 6
                                      }}
                                    >
                                      <span className="font-size-lg">
                                        <strong>{ITEMS[item]}</strong>
                                        {ITEMS_DESCRIPTION[item] && (
                                          <InfoTooltip
                                            text={ITEMS_DESCRIPTION[item]}
                                            margin={"ml-2"}
                                          />
                                        )}
                                      </span>
                                    </TableCell>
                                    {Object.keys(TIMES[time]).map(
                                      (period, periodIdx) => (
                                        <TableCell
                                          key={periodIdx}
                                          style={{
                                            border: 0,
                                            padding: 6,
                                            backgroundColor: "#f7f7f7"
                                          }}
                                        >
                                          <span className="font-size-lg">
                                            {item == "TICKET_MEDIA" ||
                                            item == "PRODUCT_VALUE_MEDIA" ||
                                            item == "VALUE_MEDIA_PER_DAY"
                                              ? prettyMoney(parseFloat(data[period][item]))
                                              : (
                                                ['TICKET_NUMBER', 'DAYS_WITH_SALES', 'TICKET_MEDIA_DAY', 'QUANTITY_MEDIA'].includes(item)
                                                  ? parseFloat(data[period][item]).toFixed(0)
                                                  : decimalMoney(parseFloat(data[period][item]))
                                              )}
                                          </span>
                                        </TableCell>
                                      )
                                    )}
                                    <TableCell
                                      style={{
                                        border: 0,
                                        padding: 6,
                                        backgroundColor: "#f7f7f7"
                                      }}
                                    >
                                      {time == "DAY" ? (
                                        <Comparison
                                          a={data["PREVIOUS_DAY"][item]}
                                          b={
                                            data["PREVIOUS_DAY_LAST_YEAR"][item]
                                          }
                                        />
                                      ) : (
                                        <Comparison
                                          a={data["CURRENT_MONTH"][item]}
                                          b={
                                            data["CURRENT_MONTH_LAST_YEAR"][
                                              item
                                            ]
                                          }
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableCell>0</TableCell>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardBody>
                    </Card>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
          <Snackbar
            open={alertError}
            autoHideDuration={2500}
            onClose={handleCloseAlertError}
          >
            <Alert onClose={handleCloseAlertError} severity="error">
              Erro ao carregar dados
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}
