import { ButtonGroup, Grid } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useContext } from "react";
import ExportInconsistenciesPopover from "../../../components/Common/ExportInconsistenciesPopover";
import InfoTooltip from "../../../components/Common/InfoTooltip";
import AbcAnalysis from "../../../components/Site/AbcAnalysis";
import CategoriesSelector from "../../../components/Site/CategoriesSelector";
import ProductsManagementFiltersProvider, {
  ProductsManagementFiltersContext
} from "../../../contexts/ProductsManagementFiltersProvider";
import { formatStoresToId } from "../../../helpers";
import { useSelectedStores } from "../../../hooks/stores";
import { useHasInconsistencies } from "../../../services/inconsistencies";
import { useSubheader } from "../../../_metronic/layout";
import "./styles.scss";

function ProductManagement() {
  const { weeksInterval, setWeeksInterval } = useContext(
    ProductsManagementFiltersContext
  );
  const subheader = useSubheader();
  subheader.setTitle("Gestão de Produtos");

  const selectedStores = useSelectedStores();
  const params = { weeks: weeksInterval };

  if (selectedStores.length) {
    params.stores = formatStoresToId(selectedStores).join(",");
  }

  const { data: inconsistencies } = useHasInconsistencies(params);

  const maximumLowStockUpdateDate = moment()
    .subtract(1, "day")
    .subtract(weeksInterval, "weeks")
    .format("DD/MM/YY");

  const minimumLowStockUpdateDate = moment()
    .subtract(1, "day")
    .format("DD/MM/YY");

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          className="actions-container"
          justify="space-between"
        >
          <div className="filters-container">
            <div className="time-filter-container">
              <ButtonGroup>
                <button
                  onClick={() => setWeeksInterval(12)}
                  className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                    "btn-transparent-white": weeksInterval !== 12,
                    "btn-white": weeksInterval === 12
                  })}
                >
                  12 semanas
                </button>
                <button
                  onClick={() => setWeeksInterval(8)}
                  className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                    "btn-transparent-white": weeksInterval !== 8,
                    "btn-white": weeksInterval === 8
                  })}
                >
                  8 semanas
                </button>
                <button
                  onClick={() => setWeeksInterval(4)}
                  className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                    "btn-transparent-white": weeksInterval !== 4,
                    "btn-white": weeksInterval === 4
                  })}
                >
                  4 semanas
                </button>
              </ButtonGroup>
              <InfoTooltip
                text="Selecione o período para análise das Vendas e Estoques dos produtos.
                O PSI sugere que a análise seja realizada com o filtro de seleção em 12 semanas."
                light
                margin="ml-2"
              />
            </div>
            <CategoriesSelector />
          </div>
          <div className="d-flex align-items-center">
            <InfoTooltip
              text={`
              Inconsistências:
              1.	Itens com estoque QTD negativo na data ${minimumLowStockUpdateDate};  
              2.	Itens da Posição Curva C (baixo giro) sem necessidade de reposição com estoque QTD igual a zero na data ${minimumLowStockUpdateDate}.
              3.	Itens com VMM (Venda Média Mensal) menor ou igual a 1 no período ${maximumLowStockUpdateDate} a ${minimumLowStockUpdateDate}.
              `}
              light
              margin="mr-2"
            />
            <ExportInconsistenciesPopover
              hasInconsistencies={!!inconsistencies?.data.has}
            />
          </div>
        </Grid>
        <AbcAnalysis />
      </Grid>
    </>
  );
}

export default function() {
  return (
    <ProductsManagementFiltersProvider>
      <ProductManagement />
    </ProductsManagementFiltersProvider>
  );
}
