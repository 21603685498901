import React, { useState } from "react";
import { useSubheader } from "../../../_metronic/layout/_core/MetronicSubheader";
import { Grid, ButtonGroup, Button } from "@material-ui/core";
import Brand from "../../../components/Site/WithoutTurn/Brand";
import Item from "../../../components/Site/WithoutTurn/Item";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import clsx from "clsx";

const types = {
  BRAND: "brand",
  ITEM: "item"
};
export default function WithoutTurn() {
  const [type, setType] = useState(types.ITEM);
  const [weeksInterval, setWeeksInterval] = useState(12);
  const subheader = useSubheader();
  subheader.setTitle(
    type === "brand" ? "Marcas sem giro" : "Produtos sem giro"
  );

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ButtonGroup>
            <button
              onClick={() => setWeeksInterval(12)}
              className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                "btn-transparent-white": weeksInterval !== 12,
                "btn-white": weeksInterval === 12
              })}
            >
              12 semanas
            </button>
            <button
              onClick={() => setWeeksInterval(8)}
              className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                "btn-transparent-white": weeksInterval !== 8,
                "btn-white": weeksInterval === 8
              })}
            >
              8 semanas
            </button>
            <button
              onClick={() => setWeeksInterval(4)}
              className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                "btn-transparent-white": weeksInterval !== 4,
                "btn-white": weeksInterval === 4
              })}
            >
              4 semanas
            </button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <>
                  <CardHeaderTitle className="card-header-with-icon">
                    <TrendingDownIcon />
                    <span>
                      {type === "brand" ? "Marcas" : "Produtos"} sem giro
                    </span>
                  </CardHeaderTitle>
                  <div className="font-size-sm text-muted mt-2">
                    Aqui você encontra os itens que{" "}
                    <strong>não venderam</strong> nada no período analisado.
                  </div>
                </>
              }
              toolbar={
                <CardHeaderToolbar>
                  <ButtonGroup>
                    <Button
                      color="secondary"
                      onClick={() => setType(types.ITEM)}
                      variant={type === types.ITEM ? "contained" : "outlined"}
                    >
                      Item
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => setType(types.BRAND)}
                      variant={type === types.BRAND ? "contained" : "outlined"}
                    >
                      Marca
                    </Button>
                  </ButtonGroup>
                </CardHeaderToolbar>
              }
            />
            {type === types.BRAND && <Brand />}
            {type === types.ITEM && <Item />}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
