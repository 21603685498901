export default {
  header: {
    actions: 'Ações',
  },
  body: {
    editRow: {
      deleteText: 'Tem certeza que deseja deletar esse usuário?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Salvar'
    },
    addTooltip: 'Adicionar',
    deleteTooltip: 'Deletar',
    editTooltip: 'Editar',
    emptyDataSourceMessage: 'Nenhum resultado encontrado.'
  },
  toolbar: {
    searchPlaceholder: 'Buscar',
    searchTooltip: 'Buscar'
  },
  pagination: {
    labelRowsSelect: 'registros',
    labelRowsPerPage: 'Registros por página',
  }
};
