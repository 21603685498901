import React from 'react'
import { formatIntNumber, prettyMoney, prettyPercentage } from '../../../../helpers';
import InfoTooltip from '../../../Common/InfoTooltip';
import './styles.scss';
import { Grid } from '@material-ui/core';
import moment from 'moment';

export default function Summary({
  items,
  sellsValue,
  sellsPart,
  grossProfitPart,
  stockPart,
  itemsValue,
  grossProfitValue,
  stockValue
}) {
  return (
    <Grid item xs={12} lg={10} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }}>
      <Grid container spacing={2} className="summary">
        <Grid item xs={12} className="d-flex align-items-center justify-content-center">
          <p className="h2 text-uppercase font-weight-bolder text-dark" style={{ marginBottom: 0 }}>Resumo</p>
          <InfoTooltip text={`São ${formatIntNumber(items)} itens com necessidade de reposição.
          Esses itens foram responsáveis por ${prettyPercentage(sellsPart || 0)} da Venda ${prettyMoney(sellsValue)}, ${prettyPercentage(grossProfitPart || 0)} do Lucro Bruto ${prettyMoney(grossProfitValue)} no período analisado. 
O valor da Sugestão de Compra dos ${formatIntNumber(items)} itens é de ${prettyMoney(itemsValue)}. 
O valor do estoque destes itens é de ${prettyMoney(stockValue)} (${prettyPercentage(stockPart || 0, 2)} de Participação no estoque total) no dia ${moment().subtract(1, 'day').format('DD/MM/YYYY')}. `}
          margin="ml-2"
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={1}>
          <div className="d-flex align-items-center font-size-sm text-dark font-weight-bold">
            <span>Itens</span>
            <InfoTooltip
              text="Itens com necessidade de reposição"
              margin="ml-2"
            />
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{formatIntNumber(items)}</div>
        </Grid>
        <Grid item xs={6} sm={3} lg={3} className="buy-necessity-container">
          <div className="d-flex align-items-center font-size-sm text-dark font-weight-bold">
            <span>Necessidade de compra</span>
            <InfoTooltip
              text="Valor da Sugestão de Compra dos itens"
              margin="ml-2"
            />
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{prettyMoney(itemsValue || 0)}</div>
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <div className="d-flex align-items-center font-size-sm text-dark font-weight-bold">
            % das Vendas R$
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{prettyPercentage(sellsPart || 0)}</div>
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <div className="d-flex align-items-center font-size-sm text-dark font-weight-bold">
            % do Lucro Bruto R$
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{prettyPercentage(grossProfitPart || 0)}</div>
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <div className="d-flex align-items-center font-size-sm text-dark font-weight-bold">
            % do Estoque Total
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{prettyPercentage(stockPart || 0, 2)}</div>
        </Grid>
      </Grid>
    </Grid>
  );
}
