import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle
} from "../../../../../_metronic/_partials/controls";
import { getSalesReceipt } from "../../../../../services/sales-receipt";
import {
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { formatStoresToId } from "../../../../../helpers";
import "./styles.css";
import { useSelectedStores } from "../../../../../hooks/stores";
import {
  firstOfMonthMoment,
  dLessOneMoment,
  dLessOneLastYearMoment
} from "../../../../../services/date-filters";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import Comparison from "../../../../Common/Comparison";
import InfoTooltip from "../../../../Common/InfoTooltip";

const TIMES = {
  DAY: {
    PREVIOUS_DAY: dLessOneMoment.format("DD MMM YY"),
    PREVIOUS_DAY_LAST_YEAR: dLessOneLastYearMoment.format("DD MMM YY")
  },
  MONTH: {
    CURRENT_MONTH: `${dLessOneMoment.format("MMM YYYY")}`,
    CURRENT_MONTH_LAST_YEAR: `${dLessOneLastYearMoment.format("MMM YYYY")}`
  }
};

const PAYMENT_METHOD = {
  CASH: "% Venda R$",
  DEBIT_CARD: "% Venda CD",
  CREDIT_CARD: "% Venda CC",
  CREDIT_CARD_WITH_INSTALLMENTS: "% Venda CCP"
};

const PAYMENT_METHOD_DESCRIPTION = {
  CASH: "Dinheiro",
  DEBIT_CARD: "Cartão de débito",
  CREDIT_CARD: "Cartão de crédito",
  CREDIT_CARD_WITH_INSTALLMENTS: "Cartão de crédito parcelado"
};

export function CurrentMonth() {
  const [loading, setLoading] = useState(false);
  const [receiptData, setReceiptData] = useState([]);
  const [messageError, setErrorMessage] = useState(false);
  const currentStores = useSelectedStores();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseMessageError = () => {
    setErrorMessage(false);
  };

  const loadSalesReceipt = async storesId => {
    setLoading(true);
    try {
      const res = await getSalesReceipt(storesId);
      setReceiptData(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrorMessage(true);
    }
  };

  const getCardTitle = period => {
    switch (period) {
      case "DAY":
        return (
          <>
            <CardHeaderTitle className="card-header-with-icon">
              <CompareArrowsIcon />
              <span>
                Formas de Recebimento da Venda R$ <br />
                {dLessOneMoment.format("DD MMM YY")}
              </span>
            </CardHeaderTitle>
            <div className="font-size-sm text-muted mt-2">
              Comparação dos resultados de{" "}
              <strong>{dLessOneMoment.format("DD MMM YY")}</strong> com o mesmo
              período do ano anterior.
            </div>
          </>
        );
      case "MONTH":
        return (
          <>
            <CardHeaderTitle className="card-header-with-icon">
              <CalendarTodayIcon />
              <span>
                Formas de Recebimento da Venda R$ <br />
                {firstOfMonthMoment.format("DD")} a{" "}
                {dLessOneMoment.format("DD MMM YY")}
              </span>
            </CardHeaderTitle>
            <div className="font-size-sm text-muted mt-2">
              Comparação dos resultados de{" "}
              <strong>{firstOfMonthMoment.format("DD")}</strong> a{" "}
              <strong>{dLessOneMoment.format("DD MMM YY")}</strong> com o mesmo
              período do ano anterior.
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const storesId = formatStoresToId(currentStores);
    loadSalesReceipt(storesId);
  }, [currentStores]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {Object.keys(TIMES).map(time => (
            <>
              <Grid item xs={12} md={6}>
                <Card className="card-heavy-border" style={{ minHeight: "100%" }}>
                  <CardHeader title={getCardTitle(time)} />
                  <CardBody>
                    {loading && (
                      <div className="loading-container">
                        <CircularProgress />
                      </div>
                    )}
                    {!loading ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ border: 0, padding: 6 }} />
                              {Object.keys(TIMES[time]).map((period, idx) => (
                                <TableCell
                                  key={idx}
                                  style={{ border: 0, padding: 6 }}
                                >
                                  <span className="font-size-lg">
                                    <strong>{TIMES[time][period]}</strong>
                                  </span>
                                </TableCell>
                              ))}
                              <TableCell
                                style={{ border: 0, padding: 6 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {receiptData &&
                            Object.keys(receiptData).length > 0 ? (
                              Object.keys(PAYMENT_METHOD).map(
                                (method, methodIdx) => (
                                  <TableRow key={methodIdx}>
                                    <TableCell
                                      style={{
                                        border: 0,
                                        padding: 6
                                      }}
                                    >
                                      <span className="font-size-lg">
                                        <strong className="mr-2">
                                          {PAYMENT_METHOD[method]}
                                        </strong>
                                        <InfoTooltip
                                          text={
                                            PAYMENT_METHOD_DESCRIPTION[method]
                                          }
                                        />
                                      </span>
                                    </TableCell>
                                    {Object.keys(TIMES[time]).map(
                                      (period, periodIdx) => (
                                        <TableCell
                                          key={periodIdx}
                                          style={{
                                            border: 0,
                                            padding: 6,
                                            backgroundColor: "#f7f7f7"
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            <span className="font-size-lg">
                                              {parseFloat(
                                                (receiptData[period][method] /
                                                  receiptData[period][
                                                    "TOTAL"
                                                  ]) *
                                                  100
                                              ).toFixed(2) + "%"}
                                            </span>
                                            {receiptData[period][method] > 0 ? (
                                              <InfoTooltip
                                                fontSize={13}
                                                margin={"ml-2"}
                                                text={`${
                                                  receiptData[period][method]
                                                } vendas utilizando ${PAYMENT_METHOD_DESCRIPTION[
                                                  method
                                                ].toLowerCase()}`}
                                              />
                                            ) : null}
                                          </div>
                                        </TableCell>
                                      )
                                    )}
                                    <TableCell
                                      style={{
                                        border: 0,
                                        padding: 6,
                                        backgroundColor: "#f7f7f7"
                                      }}
                                    >
                                      {time == "DAY" ? (
                                        <Comparison
                                          a={
                                            receiptData["PREVIOUS_DAY"][method]
                                          }
                                          b={
                                            receiptData[
                                              "PREVIOUS_DAY_LAST_YEAR"
                                            ][method]
                                          }
                                        />
                                      ) : (
                                        <Comparison
                                          a={
                                            receiptData["CURRENT_MONTH"][method]
                                          }
                                          b={
                                            receiptData[
                                              "CURRENT_MONTH_LAST_YEAR"
                                            ][method]
                                          }
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              )
                            ) : (
                              <TableCell style={{ border: 0, padding: 6 }}>
                                {" "}
                                0.0%{" "}
                              </TableCell>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : null}
                  </CardBody>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
      <Snackbar
        open={messageError}
        autoHideDuration={2500}
        onClose={handleCloseMessageError}
      >
        <Alert onClose={handleCloseMessageError} severity="error">
          Erro ao carregar dados
        </Alert>
      </Snackbar>
    </>
  );
}
