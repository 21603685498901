import React from "react";

export function FooterCompact({
  today,
  footerClasses,
  footerContainerClasses
}) {
  return (
    <>
      <div
        className={`footer bg-white py-4 d-flex flex-lg-column  ${footerClasses}`}
        id="kt_footer"
      >
        <div
          className={`${footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">
              {today} &copy;
            </span>
            {` `}
            <span className="text-dark-75 text-hover-primary d-print-none">
              Made with lot of <span style={{ color: "#f42c51" }}>❤</span> and
              ☕ by Devio&reg;
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
