import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../components/Admin/Layout";
import AdminBasePage from "../pages/Admin/Layout/AdminBasePage";
import { AdminAuthPage } from "../pages/Admin/Auth";
import Logout from "../components/Site/Auth/Logout";
import ErrorsPage from "../app/modules/ErrorsExamples/ErrorsPage";

export function AdminRoutes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null && auth.user.role === 'ADMIN',
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        <Route>
          <AdminAuthPage />
        </Route>
      ) : (
        <Redirect from="/admin/auth/login" to="/admin/dashboard" />
      )}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/admin/logout" component={Logout} />
      {!isAuthorized ? (
        <Redirect to="/admin/auth/login" />
      ) : (
        <Layout>
          <AdminBasePage />
        </Layout>
      )}
    </Switch>
  );
}
