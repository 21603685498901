import api from "./api";

export const LOGIN_URL = "auth/login";
export const LOGOUT_URL = "logout";
export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = "auth/forgot-password";
export const RESET_PASSWORD_URL = "auth/reset-password";
export const REGENERATE_API_ACCESS_TOKEN = "me/api_access_token/regenerate";

export const ME_URL = "me";

export function login(email, password, role) {
  return api.post(LOGIN_URL, { email, password, role });
}

export function logoutUser() {
  return api.post(LOGOUT_URL);
}

export function register(email, fullname, username, password) {
  return api.post(REGISTER_URL, {
    email,
    fullname,
    username,
    password,
  });
}

export function requestPassword(email) {
  return api.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(data) {
  return api.post(RESET_PASSWORD_URL, data);
}

export function getUserByToken() {
  return api.get(ME_URL);
}

export function regenerateApiAccessToken() {
  return api.post(REGENERATE_API_ACCESS_TOKEN);
}
