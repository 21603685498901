import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import CnpjInputMask from "../../../../components/Common/Masks/CnpjInputMask";
import StoresList from "../../../../components/Site/StoresList";
import { createYupErrorsObject, getOnlyNumbers } from "../../../../helpers";
import useAuth from "../../../../hooks/auth";
import * as auth from "../../../../redux/Auth";
import { create } from "../../../../services/businesses";
import { useSubheader } from "../../../../_metronic/layout";
import Import from "./import";
import schema from "./schema";
import "./styles.scss";

const INITIAL_STORE_DATA = {
  name: "",
  slug: "",
  deposit: ""
};

const INITIAL_DATA = {
  name: "",
  fantasy_name: "",
  cnpj: "",
  state_registration: "",
  coverage: 30,
  stores: [INITIAL_STORE_DATA],
  bling_token: ""
};

function FirstAccess({ createBusiness }) {
  const subheader = useSubheader();
  subheader.setTitle("Primeiro acesso");

  const [data, setData] = useState(INITIAL_DATA);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  if (auth.user?.saas_business) {
    return <Import />;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      const fields = await schema.validate(data, { abortEarly: false });
      fields.cnpj = getOnlyNumbers(fields.cnpj);
      const res = await create(fields);

      createBusiness(res.data);
    } catch (e) {
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      } else {
        setErrors(e.response.data.errors);
      }
    }

    setLoading(false);
  };

  const handleUpdateStore = (store, index) => {
    const newStores = [...data.stores];
    newStores[index] = store;

    setData({ ...data, stores: newStores });
  };

  const handleAddStore = () => {
    const newStores = [...data.stores, INITIAL_STORE_DATA];

    setData({ ...data, stores: newStores });
  };

  const handleRemoveStore = storeIndex => {
    const newStores = data.stores.filter(
      (store, index) => index !== storeIndex
    );

    setData({ ...data, stores: newStores });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card className="card-heavy-border">
          <CardHeader subheader="Complete os dados da sua empresa" />

          <CardContent>
            <Grid
              component="form"
              container
              spacing={4}
              onSubmit={handleSubmit}
            >
              <Grid item xs={12}>
                <TextField
                  label="Razão Social"
                  variant="outlined"
                  value={data.name}
                  error={errors.name}
                  helperText={errors.name}
                  onChange={e => setData({ ...data, name: e.target.value })}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Nome fantasia"
                  variant="outlined"
                  error={errors.fantasy_name}
                  helperText={errors.fantasy_name}
                  value={data.fantasy_name}
                  onChange={e =>
                    setData({ ...data, fantasy_name: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="CNPJ"
                  variant="outlined"
                  error={errors.cnpj}
                  helperText={errors.cnpj}
                  value={data.cnpj}
                  onChange={e => setData({ ...data, cnpj: e.target.value })}
                  InputProps={{
                    inputComponent: CnpjInputMask
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Inscrição Estadual"
                  variant="outlined"
                  error={errors.state_registration}
                  helperText={errors.state_registration}
                  value={data.state_registration}
                  onChange={e =>
                    setData({ ...data, state_registration: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Semanas de cobertura de estoque"
                  variant="outlined"
                  error={errors.coverage}
                  helperText={errors.coverage}
                  value={data.coverage}
                  onChange={e => setData({ ...data, coverage: e.target.value })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card component="section" className="card-heavy-border">
          <CardHeader title="Filiais" />

          <CardContent>
            <StoresList
              stores={data.stores}
              handleUpdateStore={handleUpdateStore}
              addStore={handleAddStore}
              removeStore={handleRemoveStore}
              errors={errors}
            />
          </CardContent>
        </Card>
        <br />
        <Card component="section" className="card-heavy-border">
          <CardHeader title="Integrações" />

          <CardContent>
            <Grid item xs={12}>
              <TextField
                label="Token Bling"
                variant="outlined"
                value={data.bling_token}
                error={errors.bling_token}
                helperText={errors.bling_token}
                onChange={e =>
                  setData({ ...data, bling_token: e.target.value })
                }
                fullWidth
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Button
          color="secondary"
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          disabled={loading}
          size="large"
          fullWidth
        >
          {loading ? "Carregando..." : "Sincronizar ERP"}
        </Button>
      </Grid>
    </Grid>
  );
}

export default connect(null, {
  createBusiness: auth.actions.createBusiness
})(FirstAccess);
