import clsx from 'clsx'
import React from 'react'
import { prettyPercentage } from '../../../../helpers'
import CurveSelector from './CurveSelector'
import './styles.scss';

export default function CurveHeader({
  title,
  subtitle,
  curve,
  showCurveSelector,
  handleChangeCurve,
  curvesItems
}) {
  return (
    <>
      <div>
        <p
          className={`h1 mb-1 font-weight-bolder text-dark`}
        >
          {title}
          {showCurveSelector && <CurveSelector handleChangeCurve={handleChangeCurve} exclude={curve} curvesItems={curvesItems} />}
        </p>
        <p className="font-size-sm mt-2 text-dark">{subtitle}</p>
      </div>
    </>
  )
}
