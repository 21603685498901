import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatStoresToId } from "../helpers";
import { actions } from '../redux/Orders';
import { getOrders } from "../services/orders";
import { useSelectedStores } from "./stores";

export default function useOrders() {
  const orders = useSelector(state => state.orders);
  const selectedStores = useSelectedStores();
  const dispatch = useDispatch();

  const fetchOrders = async () => {
    try {
      const filters = { ...orders.filters };

      if (selectedStores.length) {
        filters.stores = formatStoresToId(selectedStores).join(',');
      }

      const res = await getOrders(filters);

      dispatch(actions.fulfillOrders(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [orders.filters, selectedStores]);

  return orders;
}
