import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  Button,
  TableBody,
  Table,
  TableContainer,
  IconButton
} from "@material-ui/core";
import "./styles.css";
import { read, remove } from "../../../../services/businesses";
import BusinessDialog from "../../../../components/Admin/Manage/BusinessDialog";
import { formatDate } from "../../../../helpers";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import DeleteDialog from "../../../../components/Common/Dialogs/DeleteDialog";

export default function BusinessPages() {
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [businessId, setBusinessId] = useState(0);
  const [status, setBusinessDialogStatus] = useState("");
  const [business, setBusiness] = useState({});

  const createSortHandler = (property, order) => event => {
    setOrderBy(property);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  const headRows = [
    { id: "name", label: "Razão social" },
    { id: "cnpj", label: "CNPJ" },
    { id: "created_at", label: "Criado em" },
    { id: "actions", label: "" }
  ];

  const loadList = async () => {
    try {
      const res = await read(order, orderBy);

      setData(res.data);
    } catch (e) {}
  };

  const onClickModalHandler = (status, businessObj) => {
    setBusinessDialogStatus(status);
    setBusiness({ ...businessObj });
    setModalHandler(true);
  };

  const handleClose = load => {
    setModalHandler(false);

    if (load) {
      loadList();
    }
  };

  const handleDeleteDialog = (businessId = null) => {
    setDeleteDialog(!deleteDialog);

    if (businessId) {
      setBusinessId(businessId);
    }
  };

  const deleteUser = async () => {
    setDeleteDialog(false);

    try {
      await remove(businessId);
      loadList();
    } catch (e) {}
  };

  useEffect(() => {
    loadList();
  }, [order, orderBy]);

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Tooltip title="Adicionar empresa">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onClickModalHandler("ADD", {})}
          >
            Adicionar
          </Button>
        </Tooltip>
      </div>
    </div>
  );

  return (
    <>
      <BusinessDialog
        open={modalHandler}
        handleClose={handleClose}
        status={status}
        business={business}
      />
      <DeleteDialog
        title={"Remover empresa"}
        description={"Deseja realmente remover a empresa?"}
        open={deleteDialog}
        handleClose={handleDeleteDialog}
        handleDelete={deleteUser}
      />
      <div className="row">
        <div className="col-md">
          <Card className="card-heavy-border">
            <CardHeader title={"Empresas"} toolbar={toolbar} />
            <CardBody>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headRows.map(row => (
                        <TableCell
                          key={row.id}
                          sortDirection={orderBy === row.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id, order)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map(data => (
                        <TableRow key={data.name}>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.cnpj}</TableCell>
                          <TableCell>
                            {formatDate(new Date(data.created_at))}
                          </TableCell>
                          <TableCell>
                            <Tooltip title={"Editar empresa"}>
                              <IconButton
                                aria-label="Create"
                                color="secondary"
                                onClick={() =>
                                  onClickModalHandler("EDIT", data)
                                }
                              >
                                <CreateIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Deletar empresa"}>
                              <IconButton
                                aria-label="Delete"
                                onClick={() => handleDeleteDialog(data.id)}
                                style={{ color: "#f44336" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {!data.length && (
                  <div className="empty-data">Nenhuma empresa encontrada.</div>
                )}
              </TableContainer>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
