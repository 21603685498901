import api from "./api";

export function read(order, orderBy) {
  return api.get(`/admin/users?active=${orderBy}&direction=${order}`);
}
export function create(data) {
  return api.post("/admin/users", data);
}

export function activeStatus(id) {
  return api.put(`/admin/users/status/${id}`);
}

export function edit(data, id) {
  return api.put(`/admin/users/${id}`, data);
}

export function remove(id) {
  return api.delete(`/admin/users/${id}`);
}
