import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia, Grid
} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LaunchIcon from "@material-ui/icons/Launch";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AnimatedHeight from "../../../components/Common/AnimatedHeight";
import { truncate } from "../../../helpers";
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import './styles.scss';

const MAX_CARD_LENGTH = 100;

function NavCard({ url, icon, image, title, description }) {
  const [showDescription, setShowDescription] = useState(false);
  const history = useHistory();

  const toggleHover = (e) => {
    e.stopPropagation();
    setShowDescription(!showDescription)
  };

  return (
    <div style={{ position: 'relative', minHeight: description.length > MAX_CARD_LENGTH ? "338px" : '342.5px', display: "flex" }}>
      <Card
        className="d-flex flex-column justify-content-start align-items-start card-heavy-border"
        style={{ minHeight: "100%", backgroundColor: "#f9f9f9", flexGrow: 1 }}
      >
        <CardActionArea
          onClick={() => history.push(url)}
          className="d-flex flex-column justify-content-start align-items-start flex-fill"
        >
          <CardMedia component="img" height="160" alt={title} image={image} />
          <CardContent>
            <AnimatedHeight show={showDescription} className="nav-card-content">
              <div>
                <p
                  className={clsx("h5", "text-weight-bolder", {
                    "d-flex": !!icon,
                    "align-items-center": !!icon
                  })}
                >
                  <span className={clsx({ "mr-2": !!icon })}>{title}</span>
                  {!!icon && icon}
                </p>
                <p className="text-dark">
                  {showDescription ? description : truncate(description, MAX_CARD_LENGTH)}
                </p>
              </div>
              {description.length > MAX_CARD_LENGTH && (
                <p className="text-center mt-4 mb-0">
                  <button
                    onClick={toggleHover}
                    className="show-more-btn"
                  >{showDescription ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}</button>
                </p>
              )}
            </AnimatedHeight>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

export default function ManagementPanel() {
  const subheader = useSubheader();

  useEffect(() => {
    subheader.setBreadcrumbs(null);
  }, [])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={3}>
        <NavCard
          title="Indicadores de Resultados"
          description="Confira os resultados do seu negócio olhando os principais indicadores de desempenho de Vendas, Custo e Lucro Bruto."
          url="/strategic"
          image={`${toAbsoluteUrl("/media/menu/indicadores-1.jpg")}`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <NavCard
          title="Gestão de Produtos"
          description="Nesta função você terá a sua disposição uma sugestão assertiva para Compra dos produtos com necessidade de reposição, visibilidade dos produtos sem giro (itens sem venda) e também dos itens com baixo giro. Com isso em mãos, você poderá melhorar o desempenho do seu negócio."
          url="/management"
          image={`${toAbsoluteUrl("/media/menu/indicadores-3.jpg")}`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <NavCard
          title="CRM"
          icon={<LaunchIcon />}
          description="Em desenvolvimento."
          url="/"
          image="https://image.freepik.com/vetores-gratis/projeto-de-conceito-de-crm-icones-planas-do-sistema-de-contabilidade-clientes-suporte-lidar_126283-98.jpg"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <NavCard
          title="Fornecedores"
          description="Em desenvolvimento."
          url="/"
          image={`${toAbsoluteUrl("/media/menu/indicadores-6.jpg")}`}
        />
      </Grid>
    </Grid>
  );
}
