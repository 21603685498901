import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./Auth";
import * as stores from "./Stores";
import * as orders from "./Orders";
import * as products from "./Products";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  stores: stores.reducer,
  orders: orders.reducer,
  products: products.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
