import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../../../redux/Auth";
import { login } from "../../../services/auth";
import schema from "./schema";
import { createYupErrorsObject } from "../../../helpers";
import "./styles.css";
import { FormHelperText, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [snackbar, setSnackbar] = useState(false);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const authenticate = async e => {
    enableLoading();
    e.preventDefault();
    try {
      await schema.validate({ email, password }, { abortEarly: false });
      const res = await login(email, password, "ADMIN");
      props.login(res.data.access_token);
      disableLoading();
    } catch (e) {
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      } else {
        setSnackbar(true);
      }
      disableLoading();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <>
      <div className="login-form login-signin" id="kt_login_signin_form">
        <div className="mb-5 mb-lg-10">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            Ambiente administrativo
          </h3>
          <p className="text-muted font-weight-bold">
            Entre com seu e-mail e senha
          </p>
        </div>
        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form-group fv-plugins-icon-container">
            <label className="font-size-h6 font-weight-bolder text-dark">
              Seu email
            </label>
            <input
              placeholder="Email"
              type="email"
              className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg`}
              onChange={e => setEmail(e.target.value)}
            />
            {errors && errors?.email ? (
              <FormHelperText className="error-font" error>
                {errors.email}
              </FormHelperText>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <label className="font-size-h6 font-weight-bolder text-dark">
              Sua senha
            </label>
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg`}
              onChange={e => setPassword(e.target.value)}
            />
            {errors && errors?.password ? (
              <FormHelperText className="error-font" error>
                {errors.password}
              </FormHelperText>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={loading}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              onClick={authenticate}
            >
              <span className="signIn-text">Entrar</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        open={snackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={"error"}>
          {"Usuário e/ou senha incorretos."}
        </Alert>
      </Snackbar>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
