import { Button, ButtonGroup, CircularProgress, Grid } from '@material-ui/core';
import fileDownload from "js-file-download";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ProductsManagementFiltersContext } from '../../../../contexts/ProductsManagementFiltersProvider';
import { formatStoresToId } from '../../../../helpers';
import { useSelectedStores } from '../../../../hooks/stores';
import { getBrandsNeeds } from '../../../../services/abc';
import { getBrandFinancialResults } from '../../../../services/brands';
import { exportToNeedToBuy } from '../../../../services/product-management';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import ExportPopover from '../../../Common/ExportPopover';
import CurveComparison from '../../AbcAnalysis/CurveComparison';
import CurveHeader from '../../AbcAnalysis/CurveHeader';
import CurveResume from '../../AbcAnalysis/CurveResume';
import ProductsList from '../ProductsList';

export default function NeedToBuyBrand({
  brand,
  currentBuyNecessityCurveData,
  financialResultsData,
  totalPeriodValue,
  totalPeriodCost
}) {
  const [onlyProductsWithBuyNecessity, setOnlyProductsWithBuyNecessity] = useState(true);
  const { weeksInterval, category, subCategory, segment } = useContext(ProductsManagementFiltersContext);
  const [brandFinancialResultsData, setBrandFinancialResultsData] = useState(null);
  const [totalFinancialResultsData, setTotalFinancialResultsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState(null);
  const selectedStores = useSelectedStores();

  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);

  const [exporting, setExporting] = useState(null);
  const [exportHandle, setExportHandle] = useState(null);

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const openExportHandle = e => {
    setExportHandle(e.currentTarget);
  };

  const closeExportHandle = () => {
    setExportHandle(null);
  };

  const handleExport = async extension => {
    setExporting(extension);
    const storesId = formatStoresToId(selectedStores);

    try {
      const res = await exportToNeedToBuy(extension, storesId, weeksInterval, currentBuyNecessityCurveData.curve, category, subCategory, segment, brand);
      fileDownload(res.data, `LT${moment().format("YYYYMMDD")}.${extension}`);
    } catch (e) {
      console.error(e.message)
    }
    setExporting(null);
  };

  const getProducts = async (page = 1) => {
    setLoading(true);

    const storesId = formatStoresToId(selectedStores);

    const params = {
      weeks: weeksInterval,
      brand,
      category,
      subcategory: subCategory,
      segment,
      stores: storesId,
      only_with_buy_necessity: onlyProductsWithBuyNecessity ? 1 : 0,
    };

    try {
      const { data } = await getBrandsNeeds(page, params);

      cachePage(page, data.data);
      setProducts(data.data);
      setPages(data.pages);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const getCurrentFinancialResults = async () => {
    try {
      const storesId = formatStoresToId(selectedStores);

      const params = {
        weeks: weeksInterval,
        brand,
        category,
        subcategory: subCategory,
        segment,
        stores: storesId,
        only_with_buy_necessity: onlyProductsWithBuyNecessity ? 1 : 0,
      };

      const { data } = await getBrandFinancialResults(params);

      setBrandFinancialResultsData(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getTotalFinancialResults = async () => {
    try {
      const storesId = formatStoresToId(selectedStores);

      const params = {
        weeks: weeksInterval,
        brand,
        category,
        subcategory: subCategory,
        segment,
        stores: storesId,
        only_with_buy_necessity: 0
      };

      const { data } = await getBrandFinancialResults(params);

      setTotalFinancialResultsData(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (cachedPages.hasOwnProperty(currentPage)) {
      setProducts(cachedPages[currentPage]);
      return;
    }

    getProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCachedPages({});
    setCurrentPage(1);
    getProducts(1);
    getTotalFinancialResults();
    getCurrentFinancialResults();
  }, [selectedStores, weeksInterval, category, subCategory, segment, onlyProductsWithBuyNecessity]);

  const formatedBrandFinancialResults = {
    totalSoldQuantity: brandFinancialResultsData?.total_items,
    totalSoldValue: brandFinancialResultsData?.total_sold_value,
    cost: brandFinancialResultsData?.total_cost,
    grossProfit: brandFinancialResultsData?.gross_profit,
    stockValue: brandFinancialResultsData?.total_in_stock,
    stockQuantity: brandFinancialResultsData?.total_in_stock_quantity,
  };

  const suffix = brand ? `da Marca ${brand || 'Sem marca'}` : 'de produtos sem marca'

  return (
    <Card className="card-heavy-border">
      <CardHeader containerClassName="bg-light-primary curve-title">
        <CurveHeader
          color="primary"
          textClass="text-dark"
          title={brand || <span style={{ fontStyle: 'italic' }}>Sem marca</span>}
          subtitle={`Detalhamento dos Produtos com Necessidade de Reposição – Sugestão de Compra ${suffix}`}
        />
      </CardHeader>
      <CardBody>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            {!brandFinancialResultsData && (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}
            {!!brandFinancialResultsData && (
              <CurveResume
                items={brandFinancialResultsData?.items}
                orders={totalPeriodValue ? brandFinancialResultsData.total_sold_value / totalPeriodValue : 0}
                grossProfit={financialResultsData?.gross_profit
                  ? brandFinancialResultsData.gross_profit / financialResultsData?.gross_profit
                  : 0}
                stock={brandFinancialResultsData.stock_percentage}
                ordersValue={totalPeriodValue}
                grossProfitValue={financialResultsData?.gross_profit}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <CardBody>
                {!brandFinancialResultsData && (
                  <div className="loading-container">
                    <CircularProgress />
                  </div>
                )}

                {!!brandFinancialResultsData && !!totalFinancialResultsData && (
                  <CurveComparison
                    curveName={brand}
                    currentBuyNecessityCurveData={formatedBrandFinancialResults}
                    financialResultsData={totalFinancialResultsData}
                    totalPeriodValue={totalFinancialResultsData.total_sold_value}
                    totalPeriodCost={totalFinancialResultsData.total_cost}
                    secondRowName={onlyProductsWithBuyNecessity ? false : brand}
                    showTotalSuffix={onlyProductsWithBuyNecessity}
                  />
                )}
              </CardBody>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={null}
                className="need-to-buy-header"
                toolbar={
                  <CardHeaderToolbar>
                    <ButtonGroup style={{ padding: "5px 15px 5px 0" }}>
                      <Button
                        color="secondary"
                        onClick={() => setOnlyProductsWithBuyNecessity(true)}
                        variant={onlyProductsWithBuyNecessity ? "contained" : "outlined"}
                      >
                        Com necessidade de compra
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => setOnlyProductsWithBuyNecessity(false)}
                        variant={!onlyProductsWithBuyNecessity ? "contained" : "outlined"}
                      >
                        Todos
                      </Button>
                    </ButtonGroup>
                    <ExportPopover
                      open={exportHandle}
                      handleOpenExport={openExportHandle}
                      handleCloseExport={closeExportHandle}
                      handleExport={handleExport}
                      exporting={exporting}
                    />
                  </CardHeaderToolbar>
                }
              />
              <CardBody>
                <ProductsList
                  loading={loading}
                  currentPage={currentPage}
                  pages={pages}
                  products={products}
                  onChangePaginationHandler={onChangePaginationHandler}
                  withCurveColumn
                />
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
}
