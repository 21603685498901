import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import {
  Tooltip,
  Button,
  TableContainer,
  IconButton,
  Switch,
  Snackbar
} from "@material-ui/core";
import Table from "material-table";
import "./styles.css";
import { read, activeStatus, remove } from "../../../../services/users";
import UserDialog from "../../../../components/Admin/Manage/UserDialog";
import { formatDate } from "../../../../helpers";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import DeleteDialog from "../../../../components/Common/Dialogs/DeleteDialog";
import MuiAlert from "@material-ui/lab/Alert";
import localization from "../../../../localization/table";

export default function UserPages({ onRequestSort }) {
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [userId, setUserId] = useState(0);
  const [status, setUserDialogStatus] = useState("");
  const [user, setUser] = useState({});
  const [snackbar, setSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const roles = {
    ADMIN: "Administrador",
    DIRECTOR: "Diretor",
    MANAGER: "Gerente"
  };
  const createSortHandler = (property, order) => event => {
    setOrderBy(property);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const loadList = async () => {
    try {
      const res = await read(order, orderBy);
      setData(res.data);
    } catch (e) {}
  };

  const onClickModalHandler = (status, userObj) => {
    setUserDialogStatus(status);
    setUser({ ...userObj });
    setModalHandler(true);
  };

  const handleClose = (load, alertMessage) => {
    setModalHandler(false);
    if (load) {
      setAlertMessage(alertMessage);
      setSeverity("success");
      setSnackbar(true);
      loadList();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const handleDeleteDialog = (userId = null) => {
    setDeleteDialog(!deleteDialog);
    if (userId) {
      setUserId(userId);
    }
  };

  const deleteUser = async () => {
    setDeleteDialog(false);
    try {
      await remove(userId);
      loadList();
      setAlertMessage("Usuário deletado com sucesso");
      setSeverity("success");
      setSnackbar(true);
    } catch (e) {
      setAlertMessage("Erro ao deletar usuário");
      setSeverity("error");
      setSnackbar(true);
    }
  };

  const changeActiveStatus = async userId => {
    try {
      await activeStatus(userId);
      loadList();
    } catch (e) {}
  };

  useEffect(() => {
    loadList();
  }, []);
  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Tooltip title="Adicionar usuário">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onClickModalHandler("ADD", {})}
          >
            Adicionar
          </Button>
        </Tooltip>
      </div>
    </div>
  );

  return (
    <>
      <UserDialog
        user={user}
        status={status}
        open={modalHandler}
        handleClose={handleClose}
      />
      <DeleteDialog
        title={"Remover usuário"}
        description={"Deseja realmente remover o usuário?"}
        open={deleteDialog}
        handleClose={handleDeleteDialog}
        handleDelete={deleteUser}
      />
      <div className="row">
        <div className="col-md">
          <Card className="card-heavy-border">
            <CardHeader title={"Usuários"} toolbar={toolbar} />
            <CardBody>
              {data.length > 0 ? (
                <Table
                  title=""
                  options={{
                    search: false,
                    pageSizeOptions: [10, 25, 50, 100],
                    pageSize: 10,
                    paginationType: "stepped",
                    emptyRowsWhenPaging: false
                  }}
                  columns={[
                    {
                      title: "Usuário",
                      field: "name",
                      render: rowData => rowData.name
                    },
                    {
                      title: "E-mail",
                      field: "email",
                      render: rowData => rowData.email
                    },
                    {
                      title: "Ativo",
                      field: "active",
                      render: rowData => (
                        <Switch
                          checked={Boolean(rowData.active)}
                          onChange={() => changeActiveStatus(rowData.id)}
                          color="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      )
                    },
                    {
                      title: "Empresa",
                      field: "saas_business_id",
                      render: rowData => rowData.saas_business?.name || "-"
                    },
                    {
                      title: "Tipo",
                      field: "role",
                      render: rowData => roles[rowData.role]
                    },
                    {
                      title: "Criado em",
                      field: "created_at",
                      render: rowData =>
                        formatDate(new Date(rowData.created_at))
                    },
                    {
                      title: "",
                      render: rowData => (
                        <>
                          <Tooltip title={"Editar usuário"}>
                            <IconButton
                              aria-label="Create"
                              color="secondary"
                              onClick={() =>
                                onClickModalHandler("EDIT", rowData)
                              }
                            >
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Deletar usuário"}>
                            <IconButton
                              aria-label="Delete"
                              onClick={() => handleDeleteDialog(rowData.id)}
                              style={{ color: "#f44336" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    }
                  ]}
                  data={data}
                  localization={localization}
                  components={{
                    Container: props => <div {...props} />,
                    Toolbar: props => <></>
                  }}
                />
              ) : (
                <div className="empty-list">Nenhum usuário encontrado</div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      <Snackbar
        open={snackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
