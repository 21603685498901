import qs from 'qs';
import api, { useFetch } from './api';

export function useHasInconsistencies(params) {
  params = qs.stringify(params);
  
  return useFetch('get', `/management/inconsistency?${params}`);
}

export function getInconsistenciesReport(extension, weeksInterval, stores) {
  return api.get(`management/inconsistency/export/${extension}`, {
    responseType: "blob",
    params: {
      weeks: weeksInterval,
      stores,
    },
  });
}
