import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  withStyles
} from "@material-ui/core";
import React from "react";
import {
  decimalMoney,
  formatIntNumber,
  prettyPercentage
} from "../../../../helpers";
import InfoTooltip from "../../../Common/InfoTooltip";

const TableCell = withStyles(() => ({
  root: {
    padding: 6,
    border: 0
  }
}))(({ children, ...props }) => (
  <MuiTableCell {...props}>
    <span className="font-size-lg">{children}</span>
  </MuiTableCell>
));

export default function CurveComparison({
  curveName,
  secondRowName = false,
  currentBuyNecessityCurveData,
  financialResultsData,
  totalPeriodValue,
  totalPeriodCost,
  showTotalSuffix = true
}) {
  return (
    <div className="overflow-auto">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Venda QTD</TableCell>
            <TableCell>Venda R$</TableCell>
            <TableCell>
              <div className="d-flex align-items-center">
                CMV R$
                <InfoTooltip
                  text="Venda R$ a CMV (Custo da Mercadoria Vendida)"
                  margin="ml-1"
                />
              </div>
            </TableCell>
            <TableCell>Lucro Bruto R$</TableCell>
            <TableCell>Estoque QTD</TableCell>
            <TableCell>Estoque R$</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="font-weight-bolder">
              {curveName || "Sem marca"} {showTotalSuffix && " - Total"}
            </TableCell>
            <TableCell>
              {formatIntNumber(financialResultsData?.total_items)}
            </TableCell>
            <TableCell>{decimalMoney(totalPeriodValue)}</TableCell>
            <TableCell>{decimalMoney(totalPeriodCost)}</TableCell>
            <TableCell>
              {decimalMoney(financialResultsData?.gross_profit || 0)}
            </TableCell>
            <TableCell>
              {formatIntNumber(financialResultsData?.total_in_stock_quantity) ||
                0}
            </TableCell>
            <TableCell>
              {decimalMoney(financialResultsData?.total_in_stock || 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-weight-bolder">
              {secondRowName || (
                <div className="d-flex align-items-center">
                  Itens com NC
                  <InfoTooltip
                    text="Informações dos itens com necessidade de reposição"
                    margin="ml-1"
                  />
                </div>
              )}
            </TableCell>
            <TableCell>
              {formatIntNumber(currentBuyNecessityCurveData.totalSoldQuantity)}
            </TableCell>
            <TableCell>
              {decimalMoney(currentBuyNecessityCurveData.totalSoldValue)}
            </TableCell>
            <TableCell>
              {decimalMoney(currentBuyNecessityCurveData.cost)}
            </TableCell>
            <TableCell>
              {decimalMoney(currentBuyNecessityCurveData.grossProfit)}
            </TableCell>
            <TableCell>
              {formatIntNumber(currentBuyNecessityCurveData.stockQuantity)}
            </TableCell>
            <TableCell>
              {decimalMoney(currentBuyNecessityCurveData.stockValue)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>
              {prettyPercentage(
                financialResultsData?.total_items
                  ? currentBuyNecessityCurveData.totalSoldQuantity /
                      financialResultsData?.total_items
                  : 0
              )}
            </TableCell>
            <TableCell>
              {prettyPercentage(
                totalPeriodValue
                  ? currentBuyNecessityCurveData.totalSoldValue /
                      totalPeriodValue
                  : 0
              )}
            </TableCell>
            <TableCell>
              {prettyPercentage(
                totalPeriodCost
                  ? currentBuyNecessityCurveData.cost / totalPeriodCost
                  : 0
              )}
            </TableCell>
            <TableCell>
              {prettyPercentage(
                financialResultsData?.gross_profit
                  ? currentBuyNecessityCurveData.grossProfit /
                      financialResultsData?.gross_profit
                  : 0
              )}
            </TableCell>
            <TableCell>
              {prettyPercentage(
                financialResultsData?.total_in_stock_quantity
                  ? currentBuyNecessityCurveData.stockQuantity /
                      financialResultsData?.total_in_stock_quantity
                  : 0
              )}
            </TableCell>
            <TableCell>
              {prettyPercentage(
                financialResultsData?.total_in_stock
                  ? currentBuyNecessityCurveData.stockValue /
                      financialResultsData?.total_in_stock
                  : 0
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
