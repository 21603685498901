import { formatStoresToId } from "../helpers";
import { exportOrders } from "./orders";
import downloadFile from "js-file-download";
import { exportTo } from "./client";
import moment from 'moment';
import { getInconsistenciesReport } from './inconsistencies';
import * as html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export async function exportPeriodOrders(exportType, timeInterval, timeIntervalName, stores, extraParams = {}) {
  try {
    const params = { ...timeInterval, ...extraParams };

    if (stores.length) {
      params.stores = formatStoresToId(stores).join(",");
    }

    const res = await exportOrders(exportType, timeIntervalName, params);

    downloadFile(res.data, `F${moment().format("YYYYMMDD")}.${exportType}`);
  } catch (e) {
    console.log(e);
  }
}

export async function exportClients(exportType, timeIntervalName, stores) {
  try {
    const storesId = formatStoresToId(stores);
    const res = await exportTo(exportType, storesId, timeIntervalName);

    downloadFile(res.data, `CL${moment().format("YYYYMMDD")}.${exportType}`);
  } catch (e) {
    return false;
  }
}

export async function exportInconsistencies(exportType, weeksInterval, stores) {
  try {
    const storesId = formatStoresToId(stores);
    const res = await getInconsistenciesReport(exportType, weeksInterval, storesId);

    downloadFile(res.data, `IC${moment().format("YYYYMMDD")}.${exportType}`);
  } catch (e) {
    return false;
  }
}

export function printElement(elem){
  const content = elem.innerHTML,
  newWindow = window.open('about:blank');
  const currentDocumentClone = document.cloneNode(true);

  const links = Array.from(currentDocumentClone.querySelectorAll('link'));
  const styles = Array.from(currentDocumentClone.querySelectorAll('style'));
  

  const root = newWindow.document.createElement('html');
  const head = newWindow.document.createElement('head');
  
  for (let link of links) {
    head.appendChild(link);
  }
  
  for (let style of styles) {
    head.appendChild(style);
  }
  
  root.appendChild(head);
  const body = newWindow.document.createElement('body');
  
  body.innerHTML = content;

  const pagination = body.querySelector('.MuiPagination-root');

  if (pagination) {
    pagination.outerHTML = '';
  }

  const noPrint = Array.from(body.querySelectorAll('.d-print-none'));
  const overflow = Array.from(body.querySelectorAll('.overflow-auto'));
  const cards = Array.from(body.querySelectorAll('.MuiGrid-grid-md-6'));

  for (let el of noPrint) {
    el.outerHTML = '';
  }

  for(let el of overflow) {
    el.classList.remove('overflow-auto');
  }

  for(let card of cards) {
    card.classList.remove('MuiGrid-grid-md-6');
    card.classList.add('MuiGrid-grid-xs-12');
  }

  root.appendChild(body);
  
  newWindow.document.write(root.innerHTML);
  
  // newWindow.window.print();
  // newWindow.window.close();
}

export function htmlToPdf(el, direction = 'p') {
  const htmlWidth = el.offsetWidth;
  const htmlHeight = el.offsetHeight;
  const topLeftMargin = 15;
  const pdfWidth = htmlWidth + (topLeftMargin * 2);
  const pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2);
  const canvasImageWidth = htmlWidth;
  const canvasImageHeight = htmlHeight;

  const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

  html2canvas(el).then(function (canvas) {
      // const imgData = canvas.toDataURL("image/jpeg", 1.0);
      
      // const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);

      // pdf.addImage(imgData, 'JPG', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight);

      // for (let i = 1; i <= totalPDFPages; i++) { 
      //     pdf.addPage(pdfWidth, pdfHeight);
      //     pdf.addImage(imgData, 'JPG', topLeftMargin, -(pdfHeight*i)+(topLeftMargin*4),canvasImageWidth,canvasImageHeight);
      // }

      // pdf.save("Your_PDF_Name.pdf");
      var img = canvas.toDataURL("image/jpeg", );
      var doc = new jsPDF(direction, 'px', [htmlWidth, htmlHeight]);
      doc.addImage(img, 'JPEG', 0, 0, htmlWidth, htmlHeight);
      doc.save('report.pdf');
  });
}
