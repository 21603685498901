export const actionTypes = {
  LoadedProducts: "[Loaded Products]",
};

const intialProductsState = {
  data: null,
};

export const reducer = (state = intialProductsState, action) => {
  switch (action.type) {
    case actionTypes.LoadedProducts:
      const { products } = action.payload;

      return { ...state, data: products };
    default:
      return state;
  }
};

export const actions = {
  fulfillProducts: (products) => ({ type: actionTypes.LoadedProducts, payload: { products } }),
};
