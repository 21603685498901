import React from 'react';
import PaginationBase from '@material-ui/lab/Pagination';
import useStyles from './styles';

export default function Pagination({ ...props }) {
  const classes = useStyles();

  return (
    <PaginationBase
      color="secondary"
      shape="rounded"
      classes={{
        ul: classes.paginationUl
      }}
      {...props}
    />
  );
};
