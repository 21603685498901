import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Snackbar,
  Typography
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import { syncErp } from "../../../../services/businesses";

export default function Import() {
  const [step, setStep] = useState(0);
  const [alertSuccess, setAlertSuccess] = useState(false);

  const handleSync = useCallback(async () => {
    setStep(1);
    await syncErp();

    setStep(2);
    setAlertSuccess(true);
  });

  if (!step) {
    handleSync();
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseMessageSuccess = () => {
    setAlertSuccess(false);
    return <Redirect to="/" />;
  };

  return (
    <Grid container>
      <Grid item md={3}></Grid>
      <Grid item xs={12} md={6}>
        <Card className="card-heavy-border">
          <CardHeader subheader="Estamos importando seus produtos e vendas" />

          <CardContent>
            <Grid container className="text-center">
              <Grid item xs={6}>
                <Typography variant="h6">
                  <ScheduleIcon color="secondary" /> Produtos
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  <ScheduleIcon color="secondary" /> Vendas
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Snackbar open={alertSuccess} onClose={handleCloseMessageSuccess}>
          <Alert onClose={handleCloseMessageSuccess} severity="success">
            A importação dos dados será feita em segundo plano, fique a vontade
            para tomar um café! Em breve lhe enviaremos uma notificação por
            e-mail sobre os próximos passos.
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}
