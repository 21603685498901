import React, { useState, useEffect } from 'react';
import {
  Button,
  DialogTitle,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import schema from './schema';
import { create, edit } from '../../../../services/businesses';
import { createYupErrorsObject } from '../../../../helpers';

const INITIAL_DATA = {
  name: '',
  cnpj: '',
};

export default function BusinessDialog({
  open,
  handleClose,
  status,
  business,
}) {
  const [data, setData] = useState(INITIAL_DATA);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const onClickHandler = async () => {
    if (status === 'ADD') {
      onClickAddHandler();
    } else {
      onClickEditHandler();
    }
  };

  const onClickAddHandler = async () => {
    setLoading(true);

    try {
      await schema.validate(data, { abortEarly: false });
      await create(data);

      handleClose(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.name === 'ValidationError' && e.inner) {
        setErrors(createYupErrorsObject(e));
      }
    }
  };

  const onClickEditHandler = async () => {
    setLoading(true);
    try {
      await schema.validate(data, { abortEarly: false });
      await edit(data, data.id);

      handleClose(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.name === 'ValidationError' && e.inner) {
        setErrors(createYupErrorsObject(e));
      }
    }
  };

  useEffect(() => {
    setData(business);
  }, [business]);

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>
          {status === 'ADD' ? 'Criar empresa' : 'Editar empresa'}
        </DialogTitle>
        <DialogContent>
          <div className='row'>
            <div className='col-md'>
              <TextField
                autoFocus
                label='Razão social'
                value={data.name}
                error={errors?.name}
                helperText={errors?.name || ''}
                variant='outlined'
                color='secondary'
                fullWidth
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>
            <div className='col-md'>
              <TextField
                label='CNPJ'
                value={data.cnpj}
                error={errors?.cnpj}
                helperText={errors?.cnpj || ''}
                variant='outlined'
                color='secondary'
                onChange={(e) => setData({ ...data, cnpj: e.target.value })}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button onClick={() => handleClose(false)}>Cancelar</Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={onClickHandler}
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Salvar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
