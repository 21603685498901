import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { formatIntNumber, prettyMoney } from '../../../helpers';
import InfoTooltip from '../../Common/InfoTooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#BEEBD2',
    borderRadius: '0.42rem',
    padding: theme.spacing(2)
  },
}));

export default function FinancialResults({ items, value, grossProfit, inStock }) {
  const classes = useStyles();
  const grossMargin = value ? (grossProfit / value) * 100 : 0;
  const markUp = grossProfit ? value / grossProfit : 0;

  return (
    <Grid item xs={12} lg={10} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} className="text-center">
          <p className="h2 text-uppercase font-weight-bolder text-dark">Total</p>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div className="d-flex align-items-center font-size-sm text-dark font-weight-bold">
            Nº de itens
            <InfoTooltip
              text="Nº de itens únicos vendidos no período analisado."
              margin="ml-2"
            />
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{formatIntNumber(items)}</div>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div className="d-flex font-size-sm text-dark font-weight-bold">
            Venda
            <InfoTooltip
              text="Venda R$ Total no período analisado."
              margin="ml-2"
            />
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{prettyMoney(value)}</div>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div className="d-flex font-size-sm text-dark font-weight-bold">
            Lucro bruto
            <InfoTooltip
              text={`Lucro Bruto R$ obtido no período analisado - MG Bruta (%) ${grossMargin.toFixed(2)} - MarkUp ${markUp.toFixed(2)}`}
              margin="ml-2"
            />
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{prettyMoney(grossProfit || 0)}</div>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div className="d-flex font-size-sm text-dark font-weight-bold">
            Estoque
            <InfoTooltip
              text={`Valor do Estoque a CMV (Custo da Mercadoria Vendida) no dia ${moment().subtract(1, 'day').format('DD/MM/YY')}.`}
              margin="ml-2"
            />
          </div>
          <div className="font-size-h3 font-weight-bolder text-dark">{prettyMoney(inStock || 0)}</div>
        </Grid>
      </Grid>
    </Grid>
  );
}
