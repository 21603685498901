import React, { useState } from 'react';
import { Card } from "../../../../_metronic/_partials/controls";
import clsx from 'clsx';
import InfoTooltip from '../../../Common/InfoTooltip';
import { prettyMoney, prettyPercentage } from '../../../../helpers';
import moment from 'moment';

export default function TableHeadCurve({ curve, orders, neededValue, stockPart, items, grossProfit, color, onClick }) {
  const endDate = moment()
    .subtract(1, 'day')
    .format('DD/MM/YY');

  const [hoverActive, setHoverActive] = useState(false);
  const background = hoverActive ? `bg-${color}` : `bg-light-${color}`;
  const fontColor = hoverActive ? `text-white` : `text-dark`;

  const toggleHover = () => items && setHoverActive(!hoverActive);

  return (
    <Card
      className={`d-flex flex-row align-items-center justify-content-center ${background} ${!!items ? 'cursor-pointer' : ''} shadow-sm`}
      style={{ minHeight: 50, minWidth: 160 }}
      onMouseOver={toggleHover}
      onMouseOut={toggleHover}
      onClick={!!items ? onClick : () => {}}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}>
        <span
          className={clsx('font-weight-bolder', fontColor, 'text-center')}
          style={{ fontSize: '2rem', lineHeight: 1 }}
        >
          Curva {curve}
        </span>
        <InfoTooltip
          text={`Esses ${items} itens foram responsáveis por ${orders} da Venda R$ 
          e do Lucro Bruto ${prettyMoney(grossProfit)} no período analisado.
          O valor da Sugestão de Compra dos ${items} itens é de ${prettyMoney(neededValue)}. ${prettyPercentage(stockPart)} 
          é a participação dos ${items} itens no valor do Estoque Total do dia ${endDate}`}
          margin="ml-2"
          light={hoverActive}
        />
      </div>
    </Card>
  );
}
