import { makeStyles, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: 12,
    minWidth: 150
  },
  lightIcon: {
    color: "#F3F6F9",

    "&:hover": {
      color: "#B5B5C3"
    }
  }
}));

export default function InfoTooltip({
  text,
  fontSize = 16,
  margin,
  style,
  light = false
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      onClose={handleTooltipClose}
      onMouseEnter={handleTooltipOpen}
      open={open}
      disableFocusListener
      title={<div style={{ whiteSpace: "pre-line" }}>{text}</div>}
      style={{ cursor: "pointer" }}
      classes={{
        tooltip: classes.tooltip
      }}
    >
      <InfoIcon
        style={{ fontSize, ...style }}
        className={clsx("tooltip-icon", "d-print-none", margin, {
          [classes.lightIcon]: light
        })}
      />
    </Tooltip>
  );
}
