import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, select, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../services/auth";
import { actions as storeActions } from "../Stores";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  DirectorCreatedBusiness: "[Director Created Business] Action",
  DirectorUpdatedBusiness: "[Director Updated Business] Action",
  RegeneratedApiAccessToken: "[Regenerated API Access Token] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.DirectorCreatedBusiness: {
        const { business } = action.payload;

        return {
          ...state,
          user: {
            ...state.user,
            saas_business: business,
            saas_business_id: business.id
          }
        };
      }

      case actionTypes.DirectorUpdatedBusiness: {
        const { business } = action.payload;

        return {
          ...state,
          user: {
            ...state.user,
            saas_business: business
          }
        };
      }

      case actionTypes.RegeneratedApiAccessToken: {
        const { accessToken } = action.payload;

        return {
          ...state,
          user: {
            ...state.user,
            api_token: accessToken,
          },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user }
  }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  createBusiness: business => ({
    type: actionTypes.DirectorCreatedBusiness,
    payload: { business }
  }),
  updateApiAccessToken: accessToken => ({
    type: actionTypes.RegeneratedApiAccessToken,
    payload: { accessToken },
  }),
  updateBusiness: business => ({
    type: actionTypes.DirectorUpdatedBusiness,
    payload: { business }
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.UserLoaded, function* userLoaded() {
    const state = yield select();
    const stores = state.auth.user.saas_business?.stores;

    yield put(storeActions.fillStores(stores));
  });

  yield takeLatest(actionTypes.DirectorCreatedBusiness, function* userLoaded() {
    const state = yield select();
    const stores = state.auth.user.saas_business?.stores;

    yield put(storeActions.fillStores(stores));
  });

  yield takeLatest(actionTypes.Logout, function* userLoggedOut() {
    yield put(storeActions.clearSelectedStores());
  });
}
