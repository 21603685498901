import { Button, CircularProgress, Divider, Grid, IconButton, InputBase, makeStyles, Paper, Snackbar } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useEffect, useState } from 'react';
import Clipboard from 'react-clipboard.js';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader, CardHeaderTitle
} from '../../../../_metronic/_partials/controls';
import { useSubheader } from '../../../../_metronic/layout';
import SwitchLoading from '../../../../components/Common/SwitchLoading';
import GenericModal from '../../../../components/Site/Modal/GenericModal';
import useAuth from '../../../../hooks/auth';
import * as auth from '../../../../redux/Auth';
import { regenerateApiAccessToken } from '../../../../services/auth';
import StockUpdates from './stockUpdates';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    marginLeft: theme.spacing(1),
  },
  divider: {
    height: 28,
    margin: 4,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  regenerateButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 2)
  }
}));

function ApiAccess({ updateApiAccessToken }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [showAccessToken, setShowAccessToken] = useState(false);
  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState(false);
  const [regenerating, setRegenerating] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const auth = useAuth();
  const subheader = useSubheader();

  subheader.setTitle('Acesso à API');

  useEffect(() => {
    if (auth.user) {
      setLoading(false);
    }
  }, [auth.user]);

  useEffect(() => {
    if (showCopySuccessMessage) {
      setTimeout(() => {
        setShowCopySuccessMessage(false);
      }, 2500);
    }
  }, [showCopySuccessMessage]);

  const toggleShowAccessToken = () => setShowAccessToken(!showAccessToken);

  const handleCopyAccessTokenSuccess = () => {
    setShowCopySuccessMessage(true);
  };

  const handleCloseCopyAccessTokenMessage = () => {
    setShowCopySuccessMessage(false);
  };

  const handleOpenRegenerateModal = useCallback(() => {
    setShowRegenerateModal(true);
  }, [showRegenerateModal]);

  const handleCloseRegenerateModal = useCallback(() => {
    setShowRegenerateModal(false);
  }, [showRegenerateModal]);

  const regenerateAccessToken = useCallback(async () => {
    setRegenerating(true);

    try {
      const res = await regenerateApiAccessToken();

      updateApiAccessToken(res.data.access_token);
      setShowRegenerateModal(false);
    } catch (e) {

    }

    setRegenerating(false);
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item md={2}></Grid>
      <Grid item xs={12} md={8}>
        <Card className="card-heavy-border">
          <CardHeader title={
            <CardHeaderTitle className="card-header-with-icon">
              <VpnKeyIcon />
              <span>
                Autorizar Cliente
              </span>
            </CardHeaderTitle>
          } />

          <CardBody>
            {loading && (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}

            {!loading && !!auth.user?.api_token && (
              <Grid container>
                <Grid item xs={12}>
                  <p>
                    Utilize o token abaixo para realizar a autenticação na API. Não o revele a ninguém. Em caso de vazamento, gere um novo.
                  </p>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                  <Paper component="form" className={classes.paperRoot}>
                    <InputBase
                      value={auth.user.api_token}
                      type={!showAccessToken ? 'password' : 'text'}
                      disabled
                      className={classes.input}
                    />
                    <Divider className={classes.divider} />
                    <IconButton onClick={toggleShowAccessToken} className={classes.iconButton}>
                      {showAccessToken ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    <Clipboard
                      component={IconButton}
                      data-clipboard-text={auth.user.api_token}
                      onSuccess={handleCopyAccessTokenSuccess}
                      button-title="Copiar"
                    >
                      <AssignmentIcon />
                    </Clipboard>
                  </Paper>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.regenerateButton}
                    startIcon={<RefreshIcon />}
                    onClick={handleOpenRegenerateModal}
                  >
                    Gerar outro
                  </Button>
                </Grid>
              </Grid>
            )}
          </CardBody>
        </Card>
        <br />
        <StockUpdates />
        <Snackbar
          open={showCopySuccessMessage}
          autoHideDuration={2500}
        >
          <Alert onClose={handleCloseCopyAccessTokenMessage} severity="success">
            Token copiado com sucesso!
          </Alert>
        </Snackbar>
        <GenericModal
          open={showRegenerateModal}
          title="Gerar novo token de acesso à API"
          handleClose={handleCloseRegenerateModal}
          buttons={(
            <>
              <Button
                size="large"
                variant="contained"
                color="default"
                onClick={handleCloseRegenerateModal}
              >Não</Button>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={regenerateAccessToken}
                className="ml-3"
                disabled={regenerating}
              >
                <SwitchLoading size={24} loading={regenerating}>Sim</SwitchLoading>
              </Button>
            </>
          )}>
          <p>Deseja mesmo gerar um novo token de acesso à API? O token anterior não poderá ser mais utilizado.</p>
        </GenericModal>
      </Grid>
    </Grid>
  );
}

export default connect(null, {
  updateApiAccessToken: auth.actions.updateApiAccessToken,
})(ApiAccess);
