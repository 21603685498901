import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import React from "react";
import {
  formatStoresToId,
  prettyMoney,
  prettyPercentage
} from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import {
  getCurrentMonthBrandsOrders,
  getLastDayBrandsOrders
} from "../../../../services/brands";
import {
  dLessOneLastYearMoment,
  dLessOneMoment,
  firstOfMonthMoment
} from "../../../../services/date-filters";
import {
  filters as orderFilters,
  useOrders
} from "../../../../services/orders";
import {
  getCurrentMonthProducts,
  getLastDayProducts
} from "../../../../services/products";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle
} from "../../../../_metronic/_partials/controls";
import Comparison from "../../../Common/Comparison";
import InfoTooltip from "../../../Common/InfoTooltip";
import BrandsOrders from "../BrandsOrders";
import CurrentMonth from "../CurrentMonth";
import OrdersChart from "../OrdersChart";
import { CurrentMonth as SalesReceiptCurrentMonth } from "../SalesReceipt/CurrentMonth";

const { LAST_DAY, LAST_DAY_ONE_YEAR_AGO, CURRENT_MONTH, CURRENT_MONTH_LAST_YEAR, LAST_TWO_WEEKS, LAST_TWO_WEEKS_ONE_YEAR_AGO } = orderFilters.time;

function getTotalValueOfOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.total_period_value);
  }, 0);
}

function getDiscountOfOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.returned_value);
  }, 0);
}

function getTotalCostOfOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.total_products_cost);
  }, 0);
}

function getTotalOfOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.total);
  }, 0);
}

function getTotalDiscountForOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.discount);
  }, 0);
}

function getTotalGrossProfit(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.gross_profit);
  }, 0);
}

const groupKeyExtractor = group => {
  const zeroPad = number => (number < 10 ? `0${number}` : number.toString());

  return `${zeroPad(group.month)}-${zeroPad(group.day)}`;
};

export default function DailyOrders() {
  const selectedStores = useSelectedStores();
  let extraParams = {};

  if (selectedStores?.length) {
    extraParams.stores = formatStoresToId(selectedStores).join(",");
  }

  const { data: currentPeriodOrders } = useOrders({ ...LAST_DAY, ...extraParams });
  const { data: oldPeriodOrders } = useOrders({
    ...LAST_DAY_ONE_YEAR_AGO,
    ...extraParams
  });
  const { data: currentYearChartOrders } = useOrders({ ...LAST_TWO_WEEKS, ...extraParams });
  const { data: lastYearChartOrders } = useOrders({ ...LAST_TWO_WEEKS_ONE_YEAR_AGO, ...extraParams });

  const loading = !(currentPeriodOrders && oldPeriodOrders);

  const currentPeriodComparisonGroups = currentPeriodOrders?.groups || [];
  const oldPeriodComparisonGroups = oldPeriodOrders?.groups || [];
  const totalValueCurrentYear = getTotalValueOfOrders(
    currentPeriodComparisonGroups
  );
  const totalValueLastYear = getTotalValueOfOrders(oldPeriodComparisonGroups);
  const totalCurrentYear = getTotalOfOrders(currentPeriodComparisonGroups);

  const totalLastYear = getTotalOfOrders(oldPeriodComparisonGroups);
  const totalGrossProfitCurrentYear = getTotalGrossProfit(
    currentPeriodComparisonGroups
  );
  const totalGrossProfitLastYear = getTotalGrossProfit(
    oldPeriodComparisonGroups
  );
  const discountCurrentYear = getTotalDiscountForOrders(
    currentPeriodComparisonGroups
  );
  const discountLastYear = getTotalDiscountForOrders(oldPeriodComparisonGroups);
  const returnedCurrentYear = getDiscountOfOrders(
    currentPeriodComparisonGroups
  );
  const returnedLastYear = getDiscountOfOrders(oldPeriodComparisonGroups);
  const totalWithoutDiscountsCurrentYear =
    totalValueCurrentYear - (returnedCurrentYear + discountCurrentYear);
  const totalWithoutDiscountsLastYear =
    totalValueLastYear - (returnedLastYear + discountLastYear);
  const totalCostCurrentYear = getTotalCostOfOrders(
    currentPeriodComparisonGroups
  );
  const totalCostLastYear = getTotalCostOfOrders(oldPeriodComparisonGroups);
  const markUpCurrentYear = !totalCostCurrentYear
    ? 0
    : totalWithoutDiscountsCurrentYear / totalCostCurrentYear;
  const markUpLastYear = !totalCostLastYear
    ? 0
    : totalWithoutDiscountsLastYear / totalCostLastYear;

  // const fetchOrders = async () => {
  //   setLoading(true);

  //   try {
  //     let extraParams = {};

  //     if (selectedStores.length) {
  //       extraParams.stores = formatStoresToId(selectedStores).join(",");
  //     }

  //     const res1 = await getOrders({ ...LAST_DAY, ...extraParams });
  //     const res2 = await getOrders({
  //       ...LAST_DAY_ONE_YEAR_AGO,
  //       ...extraParams
  //     });

  //     setCurrentPeriodOrders(res1.data);
  //     setOldPeriodOrders(res2.data);
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   setLoading(false);
  // };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="card-heavy-border" style={{ minHeight: "100%" }}>
              <CardHeader
                title={
                  <>
                    <CardHeaderTitle className="card-header-with-icon">
                      <CompareArrowsIcon />
                      <span>
                        Resultado de Vendas - CMV - Lucro Bruto <br />
                        {dLessOneMoment.format("DD MMM YY")}
                      </span>
                    </CardHeaderTitle>
                    <div className="font-size-sm text-muted mt-2">
                      Comparação dos resultados de{" "}
                      <strong>{dLessOneMoment.format("DD MMM YY")}</strong> com
                      o mesmo período do ano anterior.
                    </div>
                  </>
                }
              />

              <CardBody>
                {loading && (
                  <div className="loading-container">
                    <CircularProgress />
                  </div>
                )}

                {!loading && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }} />
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>
                                {dLessOneMoment.format("DD MMM YY")}
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>
                                {dLessOneLastYearMoment.format("DD MMM YY")}
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>Nº de tickets</strong>
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {totalCurrentYear}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {totalLastYear}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={totalCurrentYear}
                              b={totalLastYear}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>Venda Total</strong>
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyMoney(totalValueCurrentYear)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyMoney(totalValueLastYear)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={totalValueCurrentYear}
                              b={totalValueLastYear}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg text-danger">
                              <strong>Descontos</strong>
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg text-dark-danger">
                              {prettyMoney(
                                discountCurrentYear > 0
                                  ? -Math.abs(discountCurrentYear)
                                  : discountCurrentYear
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg text-dark-danger">
                              {prettyMoney(
                                discountLastYear > 0
                                  ? -Math.abs(discountLastYear)
                                  : discountLastYear
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={discountCurrentYear}
                              b={discountLastYear}
                              inverse
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg text-danger">
                              <strong>Troca & Devolução</strong>
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg text-dark-danger">
                              {prettyMoney(
                                returnedCurrentYear > 0
                                  ? -Math.abs(returnedCurrentYear)
                                  : returnedCurrentYear
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg text-dark-danger">
                              {prettyMoney(
                                returnedLastYear > 0
                                  ? -Math.abs(returnedLastYear)
                                  : returnedLastYear
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={returnedCurrentYear}
                              b={returnedLastYear}
                              inverse
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="d-flex align-items-center font-size-lg">
                              <strong className="mr-2">Venda</strong>
                              <InfoTooltip
                                text={"Vendas - Descontos, Trocas e Devoluções"}
                              />
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyMoney(totalWithoutDiscountsCurrentYear)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyMoney(totalWithoutDiscountsLastYear)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={totalWithoutDiscountsCurrentYear}
                              b={totalWithoutDiscountsLastYear}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="d-flex align-items-center font-size-lg">
                              <strong className="mr-2  text-danger">CMV</strong>
                              <InfoTooltip
                                text={"Custo de mercadoria vendida"}
                              />
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg text-dark-danger">
                              {prettyMoney(
                                totalCostCurrentYear > 0
                                  ? -Math.abs(totalCostCurrentYear)
                                  : totalCostCurrentYear
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg text-dark-danger">
                              {prettyMoney(
                                totalCostLastYear > 0
                                  ? -Math.abs(totalCostLastYear)
                                  : totalCostLastYear
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={totalCostCurrentYear}
                              b={totalCostLastYear}
                              inverse
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>Lucro bruto</strong>
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyMoney(totalGrossProfitCurrentYear)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyMoney(totalGrossProfitLastYear)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={totalGrossProfitCurrentYear}
                              b={totalGrossProfitLastYear}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong className="mr-2">MG Bruta</strong>
                              <InfoTooltip text={"Margem Bruta (%)"} />
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyPercentage(currentPeriodOrders?.gross_margin || 0)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {prettyPercentage(oldPeriodOrders?.gross_margin || 0)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={currentPeriodOrders?.gross_margin || 0}
                              b={oldPeriodOrders?.gross_margin || 0}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong className="mr-2">Mark Up</strong>
                              <InfoTooltip text={"Mark Up bruto"} />
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {markUpCurrentYear.toFixed(2)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <span className="font-size-lg">
                              {markUpLastYear.toFixed(2)}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              border: 0,
                              padding: 6,
                              backgroundColor: "#f7f7f7"
                            }}
                          >
                            <Comparison
                              a={markUpCurrentYear}
                              b={markUpLastYear}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </CardBody>
            </Card>
          </Grid>
          <CurrentMonth />
        </Grid>
      </Grid>

      <SalesReceiptCurrentMonth />

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <BrandsOrders
            brandsFetcher={getLastDayBrandsOrders}
            productsFetcher={getLastDayProducts}
            cardTitle={
              <>
                <CardHeaderTitle>
                  Detalhamento da venda R$ <br />{" "}
                  {dLessOneMoment.format("DD MMM YY")}
                </CardHeaderTitle>
                <div className="font-size-sm text-muted mt-2">
                  Análise da venda dos produtos de{" "}
                  <strong>{dLessOneMoment.format("DD MMM YY")}</strong>.
                </div>
              </>
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <BrandsOrders
            brandsFetcher={getCurrentMonthBrandsOrders}
            productsFetcher={getCurrentMonthProducts}
            cardTitle={
              <>
                <CardHeaderTitle>
                  Detalhamento da venda R$ <br />
                  {firstOfMonthMoment.format("DD")} a{" "}
                  {dLessOneMoment.format("DD MMM YY")}
                </CardHeaderTitle>
                <div className="font-size-sm text-muted mt-2">
                  Análise da venda dos produtos de{" "}
                  <strong>{firstOfMonthMoment.format("DD")}</strong> a{" "}
                  <strong>{dLessOneMoment.format("DD MMM YY")}</strong>.
                </div>
              </>
            }
          />
        </Grid>
      </Grid>

      <OrdersChart
        currentPeriodOrders={currentYearChartOrders}
        oldPeriodOrders={lastYearChartOrders}
      />
    </>
  );
}
