import { useSelector } from "react-redux";

export function useSelectedStores() {
  const stores = useSelector(state => state.stores);

  return stores.selectedStores;
}

export function useStores() {
  const stores = useSelector(state => state.stores);

  return stores.data;
}
