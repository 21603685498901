import { Grid } from "@material-ui/core";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import moment from "moment";
import React from "react";
import { Line } from "react-chartjs-2";
import { getDaysBetween, groupByDate, prettyMoney } from "../../../../helpers";
import { filters as orderFilters } from "../../../../services/orders";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle
} from "../../../../_metronic/_partials/controls";

const { LAST_TWO_WEEKS } = orderFilters.time;

const groupKeyExtractor = group => {
  const zeroPad = number => (number < 10 ? `0${number}` : number.toString());

  return `${zeroPad(group.month)}-${zeroPad(group.day)}`;
};

function getChartData(currentYearGroups, lastYearGroups) {
  const allDays = getDaysBetween(
    LAST_TWO_WEEKS.start_date,
    LAST_TWO_WEEKS.end_date
  );
  const labels = allDays.map(date => moment(date).format('DD/MM - dddd'));
  const formatDataset = dataset =>
    Object.keys(dataset).map(
      groupName => dataset[groupName]?.total_value_without_discounts || 0
    );
  const currentYear = groupByDate(
    currentYearGroups,
    allDays,
    groupKeyExtractor
  );
  const lastYear = groupByDate(lastYearGroups, allDays, groupKeyExtractor);
  const currentYearOrders = formatDataset(currentYear);
  const lastYearOrders = formatDataset(lastYear);

  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: moment().format("Y"),
          data: currentYearOrders,
          borderColor: "#3783E7",
          backgroundColor: "#3783E7",
          fill: false,
        },
        {
          label: moment()
            .subtract(1, "year")
            .format("Y"),
          data: lastYearOrders,
          borderColor: "#F64E60",
          backgroundColor: '#F64E60',
          fill: false,
        }
      ]
    },
    options: {
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function(tooltipItems, data) {
            return prettyMoney(parseFloat(tooltipItems.yLabel));
          }
        },
        multiKeyBackground: 'transparent',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function(value, index, values) {
                return prettyMoney(parseFloat(value));
              },
              stepSize: 200,
              beginAtZero: true
            }
          }
        ]
      }
    }
  };
}

export default function OrdersChart({ currentPeriodOrders, oldPeriodOrders }) {
  const { data: chartData, options: chartOptions } = getChartData(
    currentPeriodOrders?.groups || [],
    oldPeriodOrders?.groups || []
  );

  return (
    <Grid item xs={12}>
      <Card className="card-heavy-border">
        <CardHeader
          title={
            <>
              <CardHeaderTitle className="card-header-with-icon">
                <TrendingUpIcon />
                <span>Visão Semanal da Venda R$ (Sazonalidade)</span>
              </CardHeaderTitle>
              <div className="font-size-sm text-muted mt-2">
                Visão gráfica da venda por dia da semana dos últimos 15 dias
                comparando com mesmo período ano anterior.
              </div>
            </>
          }
        />

        <CardBody>
          <div className="p-2 chart-container" style={{ height: 350 }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        </CardBody>
      </Card>
    </Grid>
  );
}
