import {
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { useCallback, useEffect, useState } from "react";
import {
  formatStoresToId,
  prettyMoney,
  prettyPercentage
} from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import InfoTooltip from "../../../Common/InfoTooltip";
import Pagination from "../../../Common/Pagination";
import BrandProducts from "../../Modal/BrandProducts";
import Products from "../DailyOrders/Products";

const FixedTh = withStyles(theme => ({
  root: {
    border: 0,
    padding: 6,
    position: "sticky"
  }
}))(TableCell);

function TableHeadCell({
  id,
  label,
  orderBy,
  tooltip,
  orderDirection,
  handleSort
}) {
  return [
    <FixedTh
      style={{ border: 0, padding: 6 }}
      sortDirection={orderBy === id ? orderDirection : false}
    >
      <TableSortLabel
        active={orderBy === id}
        direction={orderBy === id ? orderDirection : "asc"}
        onClick={() => handleSort(id)}
      >
        <span className="font-size-lg">{label}</span>
        {!!tooltip && <InfoTooltip text={tooltip} margin="ml-2" />}
      </TableSortLabel>
    </FixedTh>
  ];
}

function Row({ brand, productsFetcher,detailVision }) {
  const [open, setOpen] = useState(false);
  const [productData, setProductData] = useState(null);

  const handleOpen = async () => {
    setOpen(prevOpen => {
      return !prevOpen;
    });

    if (!open && brand.id && !productData) {
      try {

        const params = {
          brand: brand.id,
          detailVision: detailVision
        };
        
        const data = await productsFetcher(params);
        setProductData(data);
      } catch (error) {
      }
    }
  };

  return (
    <>
      <TableRow key={brand.brand || "-"}>
        <TableCell style={{ border: 0, padding: 6 }}>
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">{brand.brand || "-"}</span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">{brand.total_quantity}</span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {prettyMoney(brand.total_value_without_discounts)}
          </span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {prettyMoney(brand.total_cost_value)}
          </span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {prettyMoney(brand.total_gross_profit)}
          </span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {prettyPercentage(brand.gross_margin)}
          </span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {brand.mark_up && +brand.mark_up > 0
              ? parseFloat(brand.mark_up).toFixed(2)
              : "-"}
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0, padding: 6 }}
          colSpan={8}
        >
          <Collapse
            in={open}
            classes={{
              hidden: "hiddenCollapse"
            }}
          >
            <BrandProducts
              brand={brand.brand || "-"}
              data={productData}
              fetcher={productsFetcher}
              detailVision={detailVision}
              />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function toggleDirection(direction) {
  if (direction === "asc") {
    return "desc";
  } else if (direction === "desc") {
    return "asc";
  }
}

const visions = {
  BRAND: "brand",
  ITEM: "item"
};
export default function BrandsOrders({
  brandsFetcher,
  productsFetcher,
  cardTitle
}) {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState(null);
  const selectedStores = useSelectedStores();
  const [orderBy, setOrderBy] = useState("total_value");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [footerData, setFooterData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage(prevState => prevState + 1);
  }
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);
  const [detailVision, setDetailVision] = useState(visions.BRAND);

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const fetchBrands = async (page = 1) => {
    setLoading(true);

    const params = {
      order_by: orderBy,
      order: orderDirection,
    };

    if (selectedStores.length) {
      params.stores = formatStoresToId(selectedStores).join(",");
    }

    try {
      const { data } = await brandsFetcher(page, params);

      cachePage(page, data.data);
      setBrands(data.data);
      setPages(data.pages);
      setFooterData(data.totals);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (cachedPages.hasOwnProperty(currentPage)) {
      setBrands(cachedPages[currentPage]);
      return;
    }

    fetchBrands(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (brands) {
      fetchBrands();
    }
  }, [orderBy, orderDirection]);

  useEffect(() => {
    setCachedPages({});
    setCurrentPage(1);
    fetchBrands();
  }, [selectedStores]);


  const handleSort = column => {
    const newOrderDirection =
      column === orderBy ? toggleDirection(orderDirection) : "desc";

    if (column !== orderBy) {
      setOrderBy(column);
    }

    setOrderDirection(newOrderDirection);
  };

  const headColumns = [
    { id: "brand", label: "Marca" },
    { id: "total_quantity", label: "Venda QTD" },
    { id: "total_value", label: "Venda R$" },
    { id: "total_cost_value", label: "Venda CMV" },
    { id: "total_gross_profit", label: "Lucro Bruto" },
    { id: "gross_margin", label: "MB", tooltip: "Margem Bruta (%)" },
    { id: "markup", label: "Mark Up", tooltip: "Mark Up bruto" }
  ];

  return (
    <>
      <Grid item xs={12}>
        <Card style={{ minHeight: "100%" }} className="card-heavy-border">
          <CardHeader
            title={
              <CardHeaderTitle className="card-header-with-icon">
                <ShoppingCartIcon />
                <span>{cardTitle}</span>
              </CardHeaderTitle>
            }
            toolbar={
              <CardHeaderToolbar className="mr-10 d-print-none">
                <ButtonGroup>
                  <Button
                    color="secondary"
                    onClick={() => setDetailVision(visions.BRAND)}
                    variant={
                      detailVision === visions.BRAND ? "contained" : "outlined"
                    }
                  >
                    Marca
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setDetailVision(visions.ITEM)}
                    variant={
                      detailVision === visions.ITEM ? "contained" : "outlined"
                    }
                  >
                    Item
                  </Button>
                </ButtonGroup>
              </CardHeaderToolbar>
            }
          />

          <CardBody
            className={
              !loading &&
              !brands?.length &&
              "d-flex justify-content-center align-items-center"
            }
            style={{ height: "100%" }}
          >
            {loading && (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}

            {!loading && !brands?.length && (
              <div className="empty-list">Nenhuma marca encontrada.</div>
            )}

            {!loading &&
              !!brands?.length &&
              (detailVision === "brand" ? (
                <div className="overflow-auto">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ border: 0, padding: 6 }} />
                        {headColumns.map(column => (
                          <TableHeadCell
                            key={column.id}
                            id={column.id}
                            label={column.label}
                            tooltip={column.tooltip}
                            handleSort={handleSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                          />
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {footerData && (
                        <TableRow>
                          <TableCell
                            style={{ border: 0, padding: 6 }}
                            colSpan={2}
                          >
                            <span className="font-size-lg"></span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>{footerData.total_items}</strong>
                            </span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>
                                {prettyMoney(
                                  footerData.total_value_without_discounts
                                )}
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>
                                {prettyMoney(footerData.total_cost)}
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>
                                {prettyMoney(footerData.gross_profit)}
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>
                                {prettyPercentage(footerData.gross_margin)}
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell style={{ border: 0, padding: 6 }}>
                            <span className="font-size-lg">
                              <strong>
                                {parseFloat(footerData.markup).toFixed(2)}
                              </strong>
                            </span>
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading &&
                        brands?.map(brand => (
                          <Row
                            key={brand.brand || "-"}
                            brand={brand}
                            productsFetcher={productsFetcher}
                            detailVision={detailVision}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <Products productsFetcher={productsFetcher}   detailVision={detailVision} />
              ))}

            {!loading && !!brands?.length && detailVision === "brand" && (
              <div className="mt-5">
                <Pagination
                  page={currentPage}
                  count={pages}
                  onChange={onChangePaginationHandler}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </Grid>
    </>
  );
}
