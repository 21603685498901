import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { formatIntNumber, prettyMoney, prettyPercentage, roundSign } from '../../../../helpers'
import { Card, CardBody, CardHeader, CardHeaderTitle } from '../../../../_metronic/_partials/controls'
import InfoTooltip from '../../../Common/InfoTooltip';
import moment from 'moment';
import RoundSignTooltip from '../../../Common/RoundSignTooltip'

export default function CurveResume({
  items,
  orders,
  grossProfit,
  stock,
  ordersValue,
  grossProfitValue,
  itemsLabelSuffix = 'para reposição',
  tooltip = false
}) {
  const itemsSuffix = items === 1 ? "item" : "itens";
  const thisSuffix = items === 1 ? "deste" : "destes";

  return (
    <Card>
      <CardHeader title={
        <>
        <CardHeaderTitle className="d-flex align-items-center">
          Resumo
          <InfoTooltip text={!!tooltip ? tooltip : `${formatIntNumber(items)} ${itemsSuffix} para reposição que foi responsável por ${prettyPercentage(orders || 0)} da Venda R$ ${prettyMoney(ordersValue)} e ${prettyPercentage(grossProfit)} do Lucro Bruto ${prettyMoney(grossProfitValue)}.​
 Em ${moment().subtract(1, 'day').format('DD/MM/YYYY')} o valor do estoque ${thisSuffix} ${formatIntNumber(items)} ${itemsSuffix} representava ${prettyPercentage(stock || 0)} do valor do estoque total.`} margin="ml-2" />
        </CardHeaderTitle>
        </>
      } />
      <CardBody>
        <Grid container spacing={2}>
          {items !== undefined && (
            <Grid
              item
              xs={6}
              sm={3}
              className={clsx("font-weight-bolder", "text-right")}
            >
              <p className="mb-1 h4">
                {formatIntNumber(items) || 0}
              </p>
              <p className="mb-0 font-size-sm">
                {items === 1
                  ? "item"
                  : "itens"}{" "}
                {itemsLabelSuffix}
              </p>
            </Grid>
          )}

          {orders !== undefined && (
            <Grid
              item
              xs={6}
              sm={3}
              className={clsx("font-weight-bolder", "text-right")}
            >
              <RoundSignTooltip number={orders * 100}>
                <p className="mb-1 h4">
                  {prettyPercentage(orders || 0)}
                </p>
              </RoundSignTooltip>
              <p className="mb-0 font-size-sm">das vendas</p>
            </Grid>
          )}

          {grossProfit !== undefined && (
            <Grid
              item
              xs={6}
              sm={3}
              className={clsx("font-weight-bolder", "text-right")}
            >
              <RoundSignTooltip number={grossProfit * 100}>
                <p className="mb-1 h4">
                  {prettyPercentage(grossProfit)}
                </p>
              </RoundSignTooltip>
              <p className="mb-0 font-size-sm">do lucro bruto</p>
            </Grid>
          )}
          
          {stock !== undefined && (
            <Grid
              item
              xs={6}
              sm={3}
              className={clsx("font-weight-bolder", "text-right")}
            >
              <RoundSignTooltip number={stock * 100}>
                <p className="mb-1 h4">
                  {prettyPercentage(stock || 0, 2)}
                </p>
              </RoundSignTooltip>
              <p className="mb-0 font-size-sm">do estoque</p>
            </Grid>
          )}
      </Grid>
    </CardBody>
    </Card>
  )
}
