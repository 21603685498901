import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { hasDecimal } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 12,
  },
}));

export default function RoundSignTooltip({ number, children }) {
  const classes = useStyles();
  const title = hasDecimal(number) ? `${number}%` : '';

  return (
    <Tooltip
      title={title}
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      {children}
    </Tooltip>
  );
}
