/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { actions as authActions } from "../../../../../redux/Auth";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BusinessIcon from '@material-ui/icons/Business';

export function UserProfileDropdown({ showApiAccess = true }) {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light"
    };
  }, [uiService]);

  const handleLogout = e => {
    e.preventDefault();

    dispatch(authActions.logout());
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Olá,
          </span>
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user?.name}
          </span>
          <span className="symbol symbol-35">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
              {user?.name[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
        <>
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/default.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user?.name}
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
        {showApiAccess && (
          <>
            <Link className="navi-item px-8 cursor-pointer" to="/account/api_access">
              <div className="navi-link d-flex">
                <div className="navi-icon mr-2">
                  <VpnKeyIcon className="text-success" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">Acesso à API</div>
                  <div className="text-muted">
                    Obtenha ou gere novamente sua chave de acesso à API.
                  </div>
                </div>
              </div>
            </Link>

            <div className="separator separator-solid mt-5"></div>
            </>
          )}

          <div className="navi-footer px-8 py-5">
            <Link
              to=""
              onClick={handleLogout}
              className="btn btn-light-primary font-weight-bold"
            >
              Sair
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
