import moment from "moment";
import qs from "qs";
import api, { useFetch } from "./api";

export function getOrders(params = {}) {
  const queryString = qs.stringify(params);

  return api.get(`order?${queryString}`);
}

export function useOrders(params) {
  const queryString = qs.stringify(params);

  return useFetch('get', `order?${queryString}`);
}

const dateFormat = "YYYY-MM-DD HH:mm:ss";

export const filters = {
  time: {
    LAST_FOUR_WEEKS: {
      start_date: moment()
        .subtract(1, "day")
        .subtract(4, "weeks")
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat)
    },

    LAST_EIGHT_WEEKS: {
      start_date: moment()
        .subtract(1, "day")
        .subtract(8, "weeks")
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat)
    },

    LAST_TWELVE_WEEKS: {
      start_date: moment()
        .subtract(1, "day")
        .subtract(12, "weeks")
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat)
    },

    /**
     * Last two weeks until D - 1.
     */
    LAST_TWO_WEEKS: {
      start_date: moment()
        .subtract(15, "days")
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat),
      type: "days"
    },

    /**
     * Same two weeks ago D - 1 period of last year.
     */
    LAST_TWO_WEEKS_ONE_YEAR_AGO: {
      start_date: moment()
        .subtract(15, "days")
        .subtract(1, "year")
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .subtract(1, "year")
        .endOf("day")
        .format(dateFormat),
      type: "days"
    },

    LAST_DAY: {
      start_date: moment()
        .subtract(1, "day")
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat),
      type: "hours"
    },

    LAST_DAY_ONE_YEAR_AGO: {
      start_date: moment()
        .subtract(1, "day")
        .subtract(1, 'year')
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .subtract(1, 'year')
        .endOf("day")
        .format(dateFormat),
      type: "hours"
    },

    /**
     * Current month until D - 1.
     * If current day is 1, use last month until last day.
     */
    CURRENT_MONTH: {
      start_date: moment()
        .subtract(1, "day")
        .startOf("month")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat),
      type: "days"
    },

    /**
     * Current month until D - 1.
     * If current day is 1, use last month until last day.
     */
    CURRENT_MONTH_LAST_YEAR: {
      start_date: moment()
        .subtract(1, "day")
        .subtract(1, "year")
        .startOf("month")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .subtract(1, "year")
        .endOf("day")
        .format(dateFormat),
      type: "days"
    },

    /**
     * Last two years and current year until D - 1.
     * If current day is 1, use last day of last month.
     */
    LAST_TWO_YEARS_WITH_CURRENT_YEAR: {
      start_date: moment()
        .subtract(1, "day")
        .subtract(2, "years")
        .startOf("year")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat),
      type: "months"
    }
  }
};

export function getGrossProfitFromProducts(products) {
  return products.reduce((prevProfit, currProduct) => {
    return prevProfit + parseFloat(currProduct.pivot.total_gross_profit);
  }, 0);
}

export function getTotalGrossProfitForOrders(orders) {
  return orders.reduce((prev, curr) => {
    return prev + getGrossProfitFromProducts(curr.products);
  }, 0);
}

export function exportOrders(fileType, type, params) {
  const queryString = qs.stringify({ ...params, file_type: fileType });

  return api.get(`order/export/${type}?${queryString}`, {
    responseType: "blob"
  });
}
