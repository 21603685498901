import api from './api';
import qs from 'qs';

export function getAbcAnalysis(page = 1, extraParams) {
  const params = qs.stringify({ page, ...extraParams });

  return api.get(`abc_analysis?${params}`);
}

export function getBrandsAbcAnalysis(page = 1, extraParams = {}) {
  const params = qs.stringify({ page, ...extraParams });

  return api.get(`abc_brands_analysis?${params}`);
}

export function getBrandsNeeds(page = 1, params) {
  return api.get('management/need-to-buy/brand', { params: { page, ...params } });
}

export function getCurvesTotals(params) {
  return api.get('management/curves_totals', { params });
}
