import qs from 'qs';
import api from './api';

export function getCurrentMonthBrandsOrders(page = 1, extraParams = {}) {
  const params = qs.stringify({ page, ...extraParams });
  return api.get(`brand/current_month?${params}`);
}

export function getLastDayBrandsOrders(page = 1, params) {
  return api.get(`brand/last_day`, {
    params: {
      ...params,
      page
    }
  });
}

export function getBrandFinancialResults(params) {
  return api.get('brand/totals', {
    params
  });
}
