import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../services/products';
import { actions as productsActions } from '../redux/Products';

export default function useProducts() {
  const products = useSelector(state => state.products);
  const dispatch = useDispatch();

  const fetchProducts = async () => {
    try {
      const res = await getProducts();

      dispatch(productsActions.fulfillProducts(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!products.data) {
      fetchProducts();
    }
  }, [products.data]);

  return products.data;
}
