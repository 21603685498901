import React, { useEffect, useState } from 'react';
import { makeStyles, MenuItem, Popover, Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { itemsNumber } from '../../../../../helpers';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 12,
  },
}));

export default function CurveSelector({ exclude, handleChangeCurve, curvesItems }) {
  const classes = useStyles();
  const curves = ['a', 'b', 'c'].filter(curve => curve !== exclude && (!curvesItems || curvesItems[curve] > 0));
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const [anchor, setAnchor] = useState(undefined);
  const open = !!anchor;

  const handleOpen = (e) => setAnchor(e.currentTarget);

  const handleClose = () => setAnchor(undefined);

  const handleChangeCurveInternal = (curve) => {
    handleChangeCurve(curve);
    handleClose();
  };

  useEffect(() => {
    setTimeout(() => {
      setTooltipOpen(false);
    }, 5000);
  }, []);

  return (
    <>
      <Tooltip
        open={tooltipOpen}
        title="Alterne entre curvas"
        placement="right"
        arrow
        classes={{
          tooltip: classes.tooltip
        }}
      >
        <ArrowDropDownIcon
          onClick={handleOpen}
          style={{ fontSize: 25, cursor: 'pointer' }}
          onMouseOver={() => setTooltipOpen(true)}
          onMouseOut={() => setTooltipOpen(false)}
          className="ml-2"
        />
      </Tooltip>
      <Popover
        open={!!open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        classes={{
          paper: "popover-paper"
        }}
      >
        {!curves.length && (
          <p style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 0, textAlign: 'center' }}>Não há itens presentes em outras curvas.</p>
        )}
        {curves.map((curve) => (
          <MenuItem
            key={curve}
            onClick={() => handleChangeCurveInternal(curve)}
            className="menu-item d-flex justify-content-between"
          >
            <span>Curva {curve.toUpperCase()}</span>
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
