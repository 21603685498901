import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    minWidth: '800px',

    [theme.breakpoints.down('sm')]: {
      minWidth: '90%',
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.main,
  },
  closeButton: {
    fontSize: '1.2rem',
    width: '24px',
    height: '24px',
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    color: '#333',

    '&:hover': {
        color: theme.palette.text.main,
    },
  },
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
    },
  },
  buttonsContainer: {
      display: 'flex',
      marginTop: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
      },
  },
  productName: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: 0,
    lineHeight: 1
  },
  productIdentification: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.5),
    }
  },
  productSku: {
    fontSize: '0.8rem',
    marginLeft: theme.spacing(2),
    marginBottom: 0,
    lineHeight: 1
  },
  productValueDetails: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  productValueDetail: {
    marginRight: theme.spacing(3),

    '&:last-child': {
      marginRight: 0
    },

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1.5),

      '&:last-child': {
        marginBottom: 0
      },
    }
  },
  productValueDetailTitle: {
    fontSize: '1rem',
  },
  productValueDetailContent: {
    fontSize: '1.3rem',
  },
  productsContainer: {
    listStyle: 'none',
  },
  product: {
    marginBottom: theme.spacing(4),

    '&:last-child': {
      marginBottom: 0
    }
  },
  sectionTitle: {
    marginBottom: theme.spacing(2)
  },
  section: {
    marginBottom: theme.spacing(4),

    '&:last-child': {
      marginBottom: 0
    }
  }
}));
