import clsx from 'clsx';
import React from 'react';
import InfoTooltip from '../../Common/InfoTooltip';

export default function YearlyTableSideTitle({ titles }) {
  return (
    <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', marginTop: 52 }}>
      {titles.map((title) => (
        <div
          key={title.title}
          className="d-flex align-items-center justify-content-start"
          style={{ width: 150, height: 152.267 }}
        >
          <h6 className={clsx('font-weight-bold', 'd-flex', 'align-items-center', {
            'text-danger': !!title.negative
          })}>
            <span className="mr-2">{title.title}</span>
            {!!title.tooltip && (
              <InfoTooltip text={title.tooltip} />
            )}
          </h6>
        </div>
      ))}
    </div>
  );
}
