import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import SwitchLoading from "../../../../components/Common/SwitchLoading";
import { createYupErrorsObject } from "../../../../helpers";
import useAuth from "../../../../hooks/auth";
import * as auth from "../../../../redux/Auth";
import { updateSaasBusinessData } from "../../../../services/businesses";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import schema from "./schema";

function SaasBusinessSettings({ updateBusiness }) {
  const subheader = useSubheader();
  subheader.setTitle("Empresa");

  const auth = useAuth();
  const [data, setData] = useState({
    coverage: auth.user.saas_business.coverage
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    try {
      await schema.validate(data, { abortEarly: false });
      await updateSaasBusinessData(data);

      const newBusiness = { ...auth.user.saas_business, ...data };
      setRedirect(true);
      updateBusiness(newBusiness);
    } catch (e) {
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      } else {
        setErrors(e.response.data.errors);
      }
    }

    setLoading(false);
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container spacing={4} justify="center">
      <Grid item xs={12} md={8} lg={6}>
        <Card className="card-heavy-border">
          <CardHeader title="Gestão de Estoque - Cobertura​" />

          <CardBody>
            <Grid
              component="form"
              container
              spacing={4}
              onSubmit={handleSubmit}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  inputProps={{
                    min: 30,
                    step: 5
                  }}
                  label="Dias de cobertura de estoque"
                  variant="outlined"
                  value={data.coverage}
                  error={errors.coverage}
                  helperText={errors.coverage}
                  onChange={e => setData({ ...data, coverage: e.target.value })}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  color="secondary"
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  fullWidth
                >
                  <SwitchLoading loading={loading} size={18}>
                    Salvar
                  </SwitchLoading>
                </Button>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
}

export default connect(null, {
  updateBusiness: auth.actions.updateBusiness
})(SaasBusinessSettings);
