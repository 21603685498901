import React, { useContext, useState } from 'react';
import { ListItemAvatar, MenuItem, Popover, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import { ProductsManagementFiltersContext } from '../../../contexts/ProductsManagementFiltersProvider';
import { useCategoriesAndSegments } from '../../../services/products';
import clsx from 'clsx';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import './styles.scss';
import InfoTooltip from '../../Common/InfoTooltip';

function ListPopover({ disabled = false, disabledTooltip, buttonLabel, selected, setSelected, items, emptyMessage }) {
  const [anchor, setAnchor] = useState(undefined);
  const open = !!anchor;
  
  const handleOpen = (e) => {
    setAnchor(e.currentTarget);
  };

  const handleClose = (e) => {
    setAnchor(undefined);
  }

  const handleSelect = (item) => {
    setSelected(item);
    handleClose();
  };

  const button = (
    <button
      onClick={handleOpen}
      className={clsx("btn", "font-weight-bold", "py-3", "px-6", 'd-flex', 'align-items-center', 'btn-white', 'mr-2')}
      disabled={disabled}
    >
      {selected || buttonLabel}
      {open ? <KeyboardArrowUpIcon className="ml-1" /> : <KeyboardArrowDownIcon className="ml-1" />}
    </button>
  );

  return (
    <>
      {!!disabledTooltip && disabled && (
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          title={disabledTooltip}
          style={{ cursor: "pointer" }}
        >
          {button}
        </Tooltip>
      )}
      
      {!disabled && button}

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        classes={{
          paper: "export-popover-paper"
        }}
      >
        {!items?.length && !!emptyMessage && (
          <div className="px-5" style={{ fontSize: 12 }}>{emptyMessage}</div>
        )}

        {items?.map((item) => (
          <MenuItem
            key={item}
            onClick={() => handleSelect(item)}
            className="menu-item d-flex justify-content-between"
          >
            <span>{item}</span>
            {item === selected && <CheckIcon size={16} />}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}

export default function CategoriesSelector() {
  const {
    category: selectedCategory,
    subCategory: selectedSubCategory,
    segment: selectedSegment,
    setCategory,
    setSubCategory,
    setSegment
  } = useContext(ProductsManagementFiltersContext);
  const { data: categoriesAndSegmentsData } = useCategoriesAndSegments();
  const categories = categoriesAndSegmentsData?.data?.categories;
  const segments = categoriesAndSegmentsData?.data?.segments;

  const selectedCategoryData = categories?.filter(({ category }) => category === selectedCategory)[0];
  const subcategories = selectedCategoryData?.subcategories;

  const handleSelectCategory = (category) => {
    if (category === selectedCategory) {
      setCategory(undefined);
      setSubCategory(undefined);

      return;
    }
    
    setSubCategory(undefined);
    setCategory(category);
  };

  const handleSelectSubCategory = (subCategory) => {
    if (subCategory === selectedSubCategory) {
      setSubCategory(undefined);

      return;
    }
    
    setSubCategory(subCategory);
  };

  const handleSelectSegment  = (segment) => {
    if (segment === selectedSegment) {
      setSegment(undefined);

      return;
    }
    
    setSegment(segment);
  };

  return (
    <div className="d-flex">
      <ListPopover
        selected={selectedCategory}
        items={categories?.map(({ category }) => category) || []}
        setSelected={handleSelectCategory}
        buttonLabel="Filtrar por categoria"
        emptyMessage="Nenhuma categoria encontrada."
      />

      <ListPopover
        disabled={!selectedCategory}
        selected={selectedSubCategory}
        items={subcategories}
        setSelected={handleSelectSubCategory}
        buttonLabel="Filtrar por subcategoria"
        disabledTooltip="Selecione uma categoria"
        emptyMessage="Nenhuma subcategoria encontrada para a categoria."
      />

      <ListPopover
        selected={selectedSegment}
        items={segments?.map(({ segment }) => segment) || []}
        setSelected={handleSelectSegment}
        buttonLabel="Filtrar por segmento"
        emptyMessage="Nenhum segmento encontrado."
      />
    </div>
  );
}
