import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as auth from "../../../../redux/Auth";
import { requestPassword } from "../../../../services/auth";
import schema from './schema';
import { FormHelperText } from "@material-ui/core";
import { createYupErrorsObject } from "../../../../helpers";
import CheckIcon from '@material-ui/icons/Check';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    setSending(true);

    try {
      await schema.validate({ email }, { abortEarly: false });
      await requestPassword(email);

      setIsRequested(true);
    } catch (e) {
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      } else {
        setErrors(e.response.data.errors);
      }
    }

    setSending(false);
  }, [email]);

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-5 mb-lg-10">
          <h3 className="font-size-h1">Esqueceu sua senha?</h3>
          <div className="text-muted font-weight-bold">
            Digite seu email para alterar sua senha
          </div>
        </div>
        {isRequested && <div className="text-center flex flex-align-center">
          <CheckIcon className="mr-3" color="primary" />
          Foi enviado um link de recuperação de senha para seu e-mail.
        </div>}

        {!isRequested && <form
          onSubmit={handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email  ? (
              <FormHelperText className="error-font mt-2" error>
                {errors.email}
              </FormHelperText>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={sending}
            >
              <span className="signIn-text">Alterar</span>
              {sending && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancelar
              </button>
            </Link>
          </div>
        </form>}
      </div>
    </>
  );
}

export default connect(null, auth.actions)(ForgotPassword);
