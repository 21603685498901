import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { DashboardPage } from "../../DashboardPage";
import ManagePage from "../ManagePage";
import Logout from "../../../components/Site/Auth/Logout";

export default function AdminBasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/admin" to="/admin/dashboard" />}
        <ContentRoute path="/admin/dashboard" component={DashboardPage} />
        <Route path="/admin/manage" component={ManagePage} />
        <Route path="/admin/logout" component={Logout} />
        <Redirect to="admin/error" />
      </Switch>
    </Suspense>
  );
}
