import {
  Button,
  ButtonGroup, CircularProgress, Grid,
  Table, TableBody, TableCell as MuiTableCell, TableHead, TableRow, withStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { decimalMoney, formatStoresToId } from "../../../../../helpers";
import { useSelectedStores } from "../../../../../hooks/stores";
import { getClientIndicatorYearly } from "../../../../../services/client";
import {
  dLessOneMoment, yearlyVision
} from "../../../../../services/date-filters";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar,
  LoadingDialog
} from "../../../../../_metronic/_partials/controls";
import ComparisonTooltip from "../../../../Common/ComparisonTooltip";
import YearlyTableSideTitle from "../../../YearlyTableSideTitle";
import "./styles.scss";

const ITEMS = {
  TICKET_NUMBER: "Nº Tickets",
  TICKET_MEDIA: "Ticket Médio",
  QUANTITY_MEDIA: "QTD média PROD vendidos",
  PRODUCT_VALUE_MEDIA: "Valor médio PROD vendidos",
  VALUE_MEDIA_PER_DAY: "Venda média dia",
  TICKET_MEDIA_DAY: "Nº tickets média dia",
  DAYS_WITH_SALES: "Nº dias com vendas"
};

const ITEMS_DESCRIPTION = {
  QUANTITY_MEDIA: "QTD média de produtos vendidos por ticket",
  PRODUCT_VALUE_MEDIA: "Valor médio de produtos vendidos",
  VALUE_MEDIA_PER_DAY: "Valor médio da venda por dia",
  TICKET_MEDIA_DAY: "Média de Nº de tickets por dia",
  DAYS_WITH_SALES: "Nº de dias com vendas realizadas"
};

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: 0
  }
}))(MuiTableCell);

const FixedColumn = withStyles(theme => ({
  root: {
    width: 60,
    position: 'sticky',
    left: 0,
    background: '#fff',
  },
}))(TableCell);

const visions = {
  ANUAL: "anual",
  TRIMESTRAL: "trimestral"
};

export default function Yearly() {
  const yearlies = yearlyVision();
  const [dataYearly, setDataYearly] = useState([]);
  const [dataTrimester, setDataTrimester] = useState([]);
  const selectedStores = useSelectedStores();
  const [loading, setLoading] = useState(false);
  const [vision, setVision] = useState(visions.ANUAL);

  const loadClientIndicatorYearly = async () => {
    setLoading(true);
    try {
      const storesId = formatStoresToId(selectedStores);
      const res = await getClientIndicatorYearly(storesId);
      setDataYearly(res.data.yearly);
      setDataTrimester(res.data.trimester);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const formatValue = (method, value) => {
    return method == "TICKET_MEDIA" ||
      method == "PRODUCT_VALUE_MEDIA" ||
      method == "VALUE_MEDIA_PER_DAY"
        ? decimalMoney(parseFloat(value))
        : (
          ['TICKET_NUMBER', 'DAYS_WITH_SALES', 'TICKET_MEDIA_DAY', 'QUANTITY_MEDIA'].includes(method)
            ? parseFloat(value).toFixed(0)
            : decimalMoney(parseFloat(value))
        )
  };

  const tableHead = (
    <TableHead className="table-head-row">
      <TableRow style={{ backgroundColor: '#fff' }}>
        <TableCell />
        <TableCell>Jan</TableCell>
        <TableCell>Fev</TableCell>
        <TableCell>Mar</TableCell>
        <TableCell>Abr</TableCell>
        <TableCell>Mai</TableCell>
        <TableCell>Jun</TableCell>
        <TableCell>Jul</TableCell>
        <TableCell>Ago</TableCell>
        <TableCell>Set</TableCell>
        <TableCell>Out</TableCell>
        <TableCell>Nov</TableCell>
        <TableCell>Dez</TableCell>
        <TableCell>Total</TableCell>
      </TableRow>
    </TableHead>
  );

  const quartersTableHead = (
    <TableHead className="table-head-row">
      <TableRow style={{ backgroundColor: '#fff' }}>
        <TableCell />
        <TableCell>1º trimestre</TableCell>
        <TableCell>2º trimestre</TableCell>
        <TableCell>3º trimestre</TableCell>
        <TableCell>4º trimestre</TableCell>
        <TableCell>Total</TableCell>
      </TableRow>
    </TableHead>
  );

  const sideTitles = Object.keys(ITEMS).map(method => ({
    title: ITEMS[method],
    tooltip: ITEMS_DESCRIPTION[method]
  }));

  useEffect(() => {
    loadClientIndicatorYearly();
  }, [selectedStores]);

  return (
    <>
      {loading ? (
        <LoadingDialog isLoading={loading} text="Carregando..." />
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className="card-heavy-border">
                <CardHeader
                  title={
                    <>
                      <CardHeaderTitle>
                        <span className="mt-4">Clientes</span>
                      </CardHeaderTitle>
                      <div className="font-size-sm text-muted mt-2">
                        Registros dos resultados de clientes dos anos de{" "}
                        {dLessOneMoment.format("YYYY") - 2},
                        {dLessOneMoment.format("YYYY") - 1} e{" "}
                        {dLessOneMoment.format("YYYY")} até{" "}
                        {dLessOneMoment.format("MMMM").toLowerCase()} com
                        indicadores de variação de desempenho, tanto na visão
                        trimestral como anual.
                      </div>
                    </>
                  }
                  toolbar={
                    <CardHeaderToolbar>
                      <ButtonGroup>
                        <Button
                          color="secondary"
                          onClick={() => setVision(visions.ANUAL)}
                          variant={
                            vision === visions.ANUAL ? "contained" : "outlined"
                          }
                        >
                          Anual
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => setVision(visions.TRIMESTRAL)}
                          variant={
                            vision === visions.TRIMESTRAL
                              ? "contained"
                              : "outlined"
                          }
                        >
                          Trimestral
                        </Button>
                      </ButtonGroup>
                    </CardHeaderToolbar>
                  }
                />
                <CardBody>
                  {loading && (
                    <div className="loading-container">
                      <CircularProgress />
                    </div>
                  )}
                  {!loading &&
                    (vision === "anual" ? (
                      <div className="d-flex">
                        <YearlyTableSideTitle titles={sideTitles} />

                        <div className="overflow-auto">
                          {Object.keys(ITEMS).map((method, idx) => (
                            <Table style={{ position: 'relative' }}>
                              {method == "TICKET_NUMBER" ? tableHead : null}
                              <TableBody className="yearly-table-body">
                                {yearlies.map(year => (
                                  <TableRow key={year}>
                                    <FixedColumn>
                                      <strong>{year}</strong>
                                    </FixedColumn>
                                    {dataYearly &&
                                    Object.keys(dataYearly).length > 0 ? (
                                      Object.keys(dataYearly[method][year]).map(
                                        (value, idx) => (
                                          <TableCell
                                            key={`${year}-${idx}`}
                                            style={{
                                              minWidth: 150,
                                              backgroundColor: "#f7f7f7"
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              <span className="mr-2">
                                                {value != 13
                                                  ? formatValue(
                                                      method,
                                                      dataYearly[method][year][
                                                        value
                                                      ]
                                                    )
                                                  : formatValue(
                                                      method,
                                                      dataYearly[method][
                                                        year
                                                      ][13]
                                                    )}
                                              </span>

                                              {dataYearly[method][year - 1] !==
                                                undefined && (
                                                <ComparisonTooltip
                                                  a={
                                                    dataYearly[method][year][
                                                      value
                                                    ]
                                                  }
                                                  b={
                                                    dataYearly[method][
                                                      year - 1
                                                    ][value]
                                                  }
                                                />
                                              )}
                                            </div>
                                          </TableCell>
                                        )
                                      )
                                    ) : (
                                      <TableCell key={year} style={{
                                        minWidth: 150,
                                        backgroundColor: "#f7f7f7"
                                      }}> - </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <YearlyTableSideTitle titles={sideTitles} />

                        <div className="overflow-auto">
                          {Object.keys(ITEMS).map((method, idx) => (
                            <Table>
                              {method === "TICKET_NUMBER"
                                ? quartersTableHead
                                : null}
                              <TableBody className="yearly-table-body">
                                {yearlies.map(year => (
                                  <TableRow key={year}>
                                    <FixedColumn>
                                      <strong>{year}</strong>
                                    </FixedColumn>
                                    {Object.keys(
                                      dataTrimester[method][year]
                                    ).map((value, idx) => (
                                      <TableCell
                                        key={`${year}-${idx}`}
                                        style={{
                                          minWidth: 150,
                                          backgroundColor: "#f7f7f7"
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <span className="mr-2">
                                            {value != 5
                                              ? (dataTrimester[method][
                                                  year
                                                ][5] != 0
                                                ? formatValue(
                                                  method,
                                                  dataTrimester[method][year][
                                                    value
                                                  ]
                                                )
                                                :  formatValue(
                                                  method,
                                                  0
                                                )
                                              )
                                              : formatValue(
                                                  method,
                                                  dataTrimester[method][year][
                                                    value
                                                  ]
                                                )}
                                          </span>

                                          {dataTrimester[method][year - 1] !==
                                            undefined && (
                                            <ComparisonTooltip
                                              a={
                                                dataTrimester[method][year][
                                                  value
                                                ]
                                              }
                                              b={
                                                dataTrimester[method][year - 1][
                                                  value
                                                ]
                                              }
                                            />
                                          )}
                                        </div>
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          ))}
                        </div>
                      </div>
                    ))}
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
