import axios from "axios";
import useSWR from 'swr';
export const baseURL = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json"
  }
});

export default api;

export function useFetch(method, url, body = {}) {
  const { data, error } = useSWR(url, async (_url) => {
    const response = await api[method](_url, body);

    return response.data;
  }, {
    revalidateOnFocus: false,
  });

  return { data, error };
}
