import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import useAuth from '../../../hooks/auth';

export default function PrivateRoute({ redirectTo, role, ...props }) {
  const auth = useAuth();

  return (
    auth.user?.role === role ? <Route {...props} /> : <Redirect to={redirectTo} />
  );
}
