import { filters } from '../../services/orders';

export const actionTypes = {
  LoadedOrders: "[Loaded Orders]",
  UpdateFilters: "[Update Filters]",
};

const initialTimeFilter = filters.time.CURRENT_MONTH;
const intialOrdersState = {
  data: null,
  filters: {
    ...initialTimeFilter,
    time_type: 'CURRENT_MONTH',
    payment_method: '',
  },
};

export const reducer = (state = intialOrdersState, action) => {
  switch (action.type) {
    case actionTypes.LoadedOrders:
      const { orders } = action.payload;

      return { ...state, data: orders };
    case actionTypes.UpdateFilters:
      const { filters } = action.payload;

      return {
        ...state,
        filters,
      };
    default:
      return state;
  }
};

export const actions = {
  fulfillOrders: (orders) => ({ type: actionTypes.LoadedOrders, payload: { orders } }),
  updateFilters: (filters) => ({ type: actionTypes.UpdateFilters, payload: { filters } }),
};
