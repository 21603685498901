import React, { useState, useEffect } from "react";
import {
  Button,
  DialogTitle,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar
} from "@material-ui/core";
import schema from "./schema";
import { create, edit } from "../../../../services/users";
import { createYupErrorsObject } from "../../../../helpers";
import MuiAlert from "@material-ui/lab/Alert";

const INITIAL_DATA = {
  name: "",
  email: ""
};

export default function UserDialog({ open, handleClose, status, user }) {
  const [data, setData] = useState(INITIAL_DATA);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const onClickHandler = async () => {
    if (status === "ADD") {
      onClickAddHandler();
    } else {
      onClickEditHandler();
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const onClickAddHandler = async () => {
    setLoading(true);
    try {
      await schema.validate(data, { abortEarly: false });
      await create(data);
      handleClose(true, "Usuário adicionado com sucesso");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setAlertMessage("Erro ao adicionar usuário");
      setSnackbar(true);
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      }
    }
  };

  const onClickEditHandler = async () => {
    setLoading(true);
    try {
      await schema.validate(data, { abortEarly: false });
      await edit(data, data.id);
      handleClose(true, "Usuário editado com sucesso");
      setLoading(false);
    } catch (e) {
      setAlertMessage("Erro ao editar usuário");
      setSnackbar(true);
      setLoading(false);
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      }
    }
  };

  useEffect(() => {
    setData(user);
  }, [user]);

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>
          {status === "ADD" ? "Criar usuário" : "Editar usuário"}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md">
              <TextField
                autoFocus
                label="Nome"
                value={data.name}
                error={errors?.name}
                helperText={errors?.name || ""}
                variant="outlined"
                color="secondary"
                fullWidth
                onChange={e => setData({ ...data, name: e.target.value })}
              />
            </div>
            <div className="col-md">
              <TextField
                label="E-mail"
                type="email"
                value={data.email}
                error={errors?.email}
                helperText={errors?.email || ""}
                variant="outlined"
                color="secondary"
                onChange={e => setData({ ...data, email: e.target.value })}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "20px" }}>
          <Button onClick={() => handleClose(false)}>Cancelar</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickHandler}
          >
            {loading ? "Carregando..." : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={"error"}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
