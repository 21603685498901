import {
  capitalize,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React, { useEffect, useState } from "react";
import {
  formatStoresToId,
  prettyMoney,
  prettyPercentage
} from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import {
  dLessOneLastYearMoment, dLessOneMoment, firstOfMonthMoment
} from "../../../../services/date-filters";
import {
  filters as orderFilters, useOrders
} from "../../../../services/orders";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  LoadingDialog
} from "../../../../_metronic/_partials/controls";
import Comparison from "../../../Common/Comparison";
import InfoTooltip from "../../../Common/InfoTooltip";
import "./styles.css";

const { CURRENT_MONTH, CURRENT_MONTH_LAST_YEAR } = orderFilters.time;

function getTotalValueOfOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.total_period_value);
  }, 0);
}

function getTotalCostOfOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.total_products_cost);
  }, 0);
}

function getTotalOfOrders(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.total);
  }, 0);
}

function getTotalGrossProfit(groups) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr?.gross_profit);
  }, 0);
}

export default function CurrentMonth() {
  const selectedStores = useSelectedStores();
  let extraParams = {};

  if (selectedStores?.length) {
    extraParams.stores = formatStoresToId(selectedStores).join(",");
  }

  const { data: currentYearData } = useOrders({ ...CURRENT_MONTH, ...extraParams });
  const { data: lastYearData } = useOrders({
    ...CURRENT_MONTH_LAST_YEAR,
    ...extraParams
  });

  const [loading, setLoading] = useState(true);
  const currentYearGroups = currentYearData?.groups || [];
  const lastYearGroups = lastYearData?.groups || [];
  const totalValueCurrentYear = getTotalValueOfOrders(currentYearGroups);
  const totalValueLastYear = getTotalValueOfOrders(lastYearGroups);
  const totalCostCurrentYear = getTotalCostOfOrders(currentYearGroups);
  const totalCostLastYear = getTotalCostOfOrders(lastYearGroups);
  const totalCurrentYear = getTotalOfOrders(currentYearGroups);
  const totalLastYear = getTotalOfOrders(lastYearGroups);
  const totalGrossProfitCurrentYear = getTotalGrossProfit(currentYearGroups);
  const totalGrossProfitLastYear = getTotalGrossProfit(lastYearGroups);
  const totalWithoutDiscountsCurrentYear =
    totalValueCurrentYear -
    ((currentYearData?.returned_value || 0) + (currentYearData?.discount || 0));
  const totalWithoutDiscountsLastYear =
    totalValueLastYear -
    ((lastYearData?.returned_value || 0) + (lastYearData?.discount || 0));
  const markUpCurrentYear = !totalCostCurrentYear
    ? 0
    : totalWithoutDiscountsCurrentYear / totalCostCurrentYear;
  const markUpLastYear = !totalCostLastYear
    ? 0
    : totalWithoutDiscountsLastYear / totalCostLastYear;

  useEffect(() => {
    if (currentYearData && lastYearData) {
      setLoading(false);
    }
  }, [currentYearData, lastYearData]);

  return (
    <>
      <LoadingDialog isLoading={loading} text="Carregando..." />

      <Grid item xs={12} md={6}>
        <Card className="card-heavy-border" style={{ minHeight: "100%" }}>
          <CardHeader
            title={
              <>
                <CardHeaderTitle className="card-header-with-icon">
                  <CalendarTodayIcon />
                  <span>
                    Resultado de Vendas - CMV - Lucro Bruto <br />
                    {firstOfMonthMoment.format("DD")} a{" "}
                    {capitalize(dLessOneMoment.format("DD MMM YY"))}
                  </span>
                </CardHeaderTitle>
                <div className="font-size-sm text-muted mt-2">
                  Comparação dos resultados de{" "}
                  <strong>{firstOfMonthMoment.format("DD")}</strong> a{" "}
                  <strong>{dLessOneMoment.format("DD MMM YY")}</strong> com o
                  mesmo período do ano anterior.
                </div>
              </>
            }
          />

          <CardBody>
            {loading && (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}

            {!loading && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }} />
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          <strong>{dLessOneMoment.format("MMM YYYY")}</strong>
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          <strong>
                            {dLessOneLastYearMoment.format("MMM YYYY")}
                          </strong>
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          <strong>Nº de tickets</strong>
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">{totalCurrentYear}</span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">{totalLastYear}</span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison a={totalCurrentYear} b={totalLastYear} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          <strong>Venda Total</strong>
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyMoney(totalValueCurrentYear)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyMoney(totalValueLastYear)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison
                          a={totalValueCurrentYear}
                          b={totalValueLastYear}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg text-danger">
                          <strong>Descontos</strong>
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg text-dark-danger">
                          {prettyMoney(
                            currentYearData?.discount > 0
                              ? -Math.abs(currentYearData?.discount)
                              : currentYearData?.discount || 0
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg text-dark-danger">
                          {prettyMoney(
                            lastYearData?.discount > 0
                              ? -Math.abs(lastYearData?.discount)
                              : lastYearData?.discount || 0
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison
                          a={parseFloat(currentYearData?.discount || 0)}
                          b={parseFloat(lastYearData?.discount || 0)}
                          inverse
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg text-danger">
                          <strong>Troca & Devolução</strong>
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg text-dark-danger">
                          {prettyMoney(
                            currentYearData?.returned_value > 0
                              ? -Math.abs(currentYearData?.returned_value)
                              : currentYearData?.returned_value || 0
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg text-dark-danger">
                          {prettyMoney(
                            lastYearData?.returned_value > 0
                              ? -Math.abs(lastYearData?.returned_value)
                              : lastYearData?.returned_value || 0
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison
                          a={currentYearData?.returned_value || 0}
                          b={lastYearData?.returned_value}
                          inverse
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="d-flex align-items-center font-size-lg">
                          <strong className="mr-2">Venda</strong>
                          <InfoTooltip
                            text={"Vendas - Descontos, Trocas e Devoluções"}
                          />
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyMoney(totalWithoutDiscountsCurrentYear)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyMoney(totalWithoutDiscountsLastYear)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison
                          a={totalWithoutDiscountsCurrentYear}
                          b={totalWithoutDiscountsLastYear}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="d-flex align-items-center font-size-lg">
                          <strong className="mr-2 text-danger">CMV</strong>
                          <InfoTooltip text={"Custo de mercadoria vendida"} />
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg text-dark-danger">
                          {prettyMoney(
                            totalCostCurrentYear > 0
                              ? -Math.abs(totalCostCurrentYear)
                              : totalCostCurrentYear
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg text-dark-danger">
                          {prettyMoney(
                            totalCostLastYear > 0
                              ? -Math.abs(totalCostLastYear)
                              : totalCostLastYear
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison
                          a={totalCostCurrentYear}
                          b={totalCostLastYear}
                          inverse
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          <strong>Lucro bruto</strong>
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyMoney(totalGrossProfitCurrentYear)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyMoney(totalGrossProfitLastYear)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison
                          a={totalGrossProfitCurrentYear}
                          b={totalGrossProfitLastYear}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          <strong className="mr-2">MG Bruta</strong>
                          <InfoTooltip text={"Margem Bruta (%)"} />
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyPercentage(currentYearData?.gross_margin || 0)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {prettyPercentage(lastYearData?.gross_margin || 0)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison
                          a={currentYearData?.gross_margin || 0}
                          b={lastYearData?.gross_margin || 0}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          <strong className="mr-2">Mark Up</strong>
                          <InfoTooltip text={"Mark Up bruto"} />
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {markUpCurrentYear.toFixed(2)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <span className="font-size-lg">
                          {markUpLastYear.toFixed(2)}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: 0,
                          padding: 6,
                          backgroundColor: "#f7f7f7"
                        }}
                      >
                        <Comparison a={markUpCurrentYear} b={markUpLastYear} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardBody>
        </Card>
      </Grid>
    </>
  );
}
