import {
  ButtonGroup, Card as MuiCard, CardActionArea, CardContent, CardHeader, Collapse, Divider, Grid,
  Grow, List,
  ListItem
} from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ExportPopover from "../../../../components/Common/ExportPopover";
import Clients from "../../../../components/Site/Indicators/Clients";
import Financial from "../../../../components/Site/Indicators/Financial";
import { useSelectedStores } from "../../../../hooks/stores";
import { dLessOneMoment } from "../../../../services/date-filters";
import { exportClients, exportPeriodOrders } from "../../../../services/export";
import { filters } from "../../../../services/orders";
import { useSubheader } from "../../../../_metronic/layout/_core/MetronicSubheader";
import "./styles.scss";

function validatePeriod(period) {
  return ["current_month", "yearly"].includes(period);
}

function validateIndicator(indicator) {
  return ["financial", "clients"].includes(indicator);
}

function getDefaultIndicator(period, indicator) {
  if (validatePeriod(period)) {
    if (validateIndicator(indicator)) {
      return indicator;
    }
  }

  return undefined;
}

function FilterCard({ title, active, onClick, children }) {
  return (
    <MuiCard className="card-heavy-border">
      <CardActionArea onClick={onClick}>
        <CardHeader
          title={title}
          className={clsx("pl-15", "pr-15", {
            "bg-primary text-white": active,
            "bg-white text-primary": !active
          })}
        />
        <Divider />
        <CardContent className="pl-15 pr-15">{children}</CardContent>
      </CardActionArea>
    </MuiCard>
  );
}

const visions = {
  ANUAL: "anual",
  TRIMESTRAL: "trimestral"
};

export default function Strategic({ match }) {
  const defaultIndicator = getDefaultIndicator(
    match.params.period,
    match.params.indicator
  );
  const [period, setPeriod] = useState(match.params.period);
  const [indicator, setIndicator] = useState(defaultIndicator);
  const [vision, setVision] = useState(visions.ANUAL);
  const [exporting, setExporting] = useState(null);
  const [exportHandle, setExportHandle] = useState(null);
  const selectedStores = useSelectedStores();
  const subheader = useSubheader();
  const history = useHistory();
  const containerRef = useRef();
  const chartRef = useRef();

  subheader.setTitle("Indicadores de resultados");

  const handleSetPeriod = newPeriod => {
    history.push(`/strategic/${newPeriod}/${indicator}`);
  };

  const handleSetIndicator = newIndicator => {
    history.push(`/strategic/${period}/${newIndicator}`);
  };

  const handleExport = useCallback(
    async exportType => {
      setExporting(exportType);

      if (indicator === "financial") {
        if (period === "current_month") {
          await exportPeriodOrders(
            exportType,
            filters.time.LAST_TWO_WEEKS,
            "current_month",
            selectedStores
          );
        } else if (period === "yearly") {
          await exportPeriodOrders(
            exportType,
            filters.time.LAST_TWO_YEARS_WITH_CURRENT_YEAR,
            "yearly",
            selectedStores,
            { trimestral: vision === visions.TRIMESTRAL ? 1 : 0 },
          );
        }
      } else if (indicator === "clients") {
        if (period === "current_month") {
          await exportClients(exportType, "current_month", selectedStores);
        } else if (period === "yearly") {
          await exportClients(exportType, "yearly", selectedStores);
        }
      }

      setExporting(false);
    },
    [vision, indicator, period, selectedStores]
  );

  const openExportHandle = e => {
    setExportHandle(e.currentTarget);
  };

  const closeExportHandle = () => {
    setExportHandle(null);
  };

  useEffect(() => {
    if (period === 'yearly') {
      setVision(visions.ANUAL);
    }
  }, [period]);

  return (
    <>
      {(!period || !indicator) && (
        <Grow in={!period || !indicator}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <FilterCard
                title="Visão Mês Corrente"
                active={period === "current_month"}
                onClick={() => setPeriod("current_month")}
              >
                Análise dos dados de vendas referentes a{" "}
                {dLessOneMoment.format("MMM YYYY")}.
              </FilterCard>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FilterCard
                title="Visão Anual"
                active={period === "yearly"}
                onClick={() => setPeriod("yearly")}
              >
                Análise dos dados de vendas referentes até{" "}
                {dLessOneMoment.format("YYYY")} (até {dLessOneMoment.format("MMM")}),{" "}
                {dLessOneMoment.format("YYYY") - 1} e{" "}
                {dLessOneMoment.format("YYYY") - 2}.
              </FilterCard>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Collapse in={!!period}>
                <FilterCard
                  title="Vendas"
                  active={indicator === "financial"}
                  onClick={() => setIndicator("financial")}
                >
                  <List dense>
                    <ListItem>Resultado da Venda - CMV - Lucro Bruto</ListItem>
                    <ListItem>Formas de Recebimento da Venda R$</ListItem>
                    <ListItem>Movimentação de Estoque R$</ListItem>
                    <ListItem>Detalhamento de Venda R$</ListItem>
                    <ListItem>
                      Visão Semanal da Venda R$ (Sazonalidade)
                    </ListItem>
                  </List>
                </FilterCard>
              </Collapse>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Collapse in={!!period}>
                <FilterCard
                  title="Clientes"
                  active={indicator === "clients"}
                  onClick={() => setIndicator("clients")}
                >
                  <List dense>
                    <ListItem>Número de Tickets</ListItem>
                    <ListItem>Ticket Médio</ListItem>
                    <ListItem>
                      QTD média de produtos vendidos por ticket
                    </ListItem>
                    <ListItem>
                      Valor médio de produtos vendidos por ticket
                    </ListItem>
                    <ListItem>Média de tickets por dia</ListItem>
                  </List>
                </FilterCard>
              </Collapse>
            </Grid>
          </Grid>
        </Grow>
      )}

      {!!period && !!indicator && (
        <Grid container spacing={4} ref={containerRef}>
          <Grid item xs={12} className="header-card d-print-none">
            <div className="filters-container">
              <ButtonGroup>
                <button
                  onClick={() => handleSetPeriod("current_month")}
                  className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                    "btn-transparent-white": period !== "current_month",
                    "btn-white": period === "current_month"
                  })}
                >
                  Visão do Mês Corrente
                </button>
                <button
                  onClick={() => handleSetPeriod("yearly")}
                  className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                    "btn-transparent-white": period !== "yearly",
                    "btn-white": period === "yearly"
                  })}
                >
                  Visão Anual
                </button>
              </ButtonGroup>

              <ButtonGroup className="indicators-container">
                <button
                  onClick={() => handleSetIndicator("financial")}
                  className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                    "btn-transparent-white": indicator !== "financial",
                    "btn-white": indicator === "financial"
                  })}
                >
                  Vendas
                </button>
                <button
                  onClick={() => handleSetIndicator("clients")}
                  className={clsx("btn", "font-weight-bold", "py-3", "px-6", {
                    "btn-transparent-white": indicator !== "clients",
                    "btn-white": indicator === "clients"
                  })}
                >
                  Clientes
                </button>
              </ButtonGroup>
            </div>

            <ExportPopover
              open={exportHandle}
              handleOpenExport={openExportHandle}
              handleCloseExport={closeExportHandle}
              handleExport={handleExport}
              exporting={exporting}
            />
          </Grid>

          {indicator === "financial" && <Financial period={period} ref={chartRef} vision={vision} setVision={setVision} />}
          {indicator === "clients" && <Clients period={period} />}
        </Grid>
      )}
    </>
  );
}
