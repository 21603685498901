import api from './api';

export function createStore(data) {
  return api.post('store', data);
}

export function updateStore(data, id) {
  data = { ...data, _method: 'PUT' };

  return api.post(`store/${id}`, data);
}

export function deleteStore(id) {
  return api.post(`store/${id}`, { _method: 'DELETE' });
}
