import {
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ProductsManagementFiltersContext } from "../../../../contexts/ProductsManagementFiltersProvider";
import {
  formatStoresToId,
  prettyMoney,
  prettyPercentage
} from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import { getBrandsWithoutTurn } from "../../../../services/product-management";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import InfoTooltip from "../../../Common/InfoTooltip";
import Pagination from "../../../Common/Pagination";
import BrandProductsWithoutTurn from "./BrandProductsWithoutTurn";

function TableHeadCell({
  id,
  label,
  orderBy,
  tooltip,
  orderDirection,
  handleSort,
  disableOrder = false
}) {
  return [
    <TableCell
      style={{ border: 0, padding: 6 }}
      sortDirection={orderBy === id ? orderDirection : false}
    >
      {disableOrder && (
        <>
          <span className="font-size-lg">{label}</span>
          {!!tooltip && <InfoTooltip text={tooltip} margin="ml-2" />}
        </>
      )}

      {!disableOrder && (
        <TableSortLabel
          active={orderBy === id}
          direction={orderBy === id ? orderDirection : "asc"}
          onClick={() => {
            if (!disableOrder) {
              handleSort(id);
            }
          }}
        >
          <span className="font-size-lg">{label}</span>
          {!!tooltip && <InfoTooltip text={tooltip} margin="ml-2" />}
        </TableSortLabel>
      )}
    </TableCell>
  ];
}

function Row({ brand }) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <TableRow>
        <TableCell style={{ border: 0, padding: 6 }}>
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">{brand.ranking}</span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">{brand.brand || "-"}</span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {prettyMoney(brand.total_sold_value)}
          </span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          {brand.total_stock_value > 0 ? (
            <span className="font-size-lg">
              {prettyMoney(brand.total_stock_value)}
            </span>
          ) : (
            <span className="font-size-lg">-</span>
          )}
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          {brand.stock_value > 0 ? (
            <span className="font-size-lg">
              {prettyMoney(brand.stock_value_without_turn)}
            </span>
          ) : (
            <span className="font-size-lg">-</span>
          )}
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {prettyPercentage(brand.stock_part)}
          </span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {prettyPercentage(brand.accumulated_stock_part)}
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0, padding: 6 }}
          colSpan={8}
        >
          <Collapse
            in={open}
            classes={{
              hidden: "hiddenCollapse"
            }}
          >
            <BrandProductsWithoutTurn brand={brand.brand} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function toggleDirection(direction) {
  if (direction === "asc") {
    return "desc";
  } else if (direction === "desc") {
    return "asc";
  }
}

export default function Brands({ totalStock, withoutTurnStock }) {
  const { weeksInterval, category, subCategory, segment } = useContext(
    ProductsManagementFiltersContext
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState(null);
  const [brandsTotalValue, setBrandsTotalValue] = useState(null);
  const [brandsTotal, setBrandsTotal] = useState(null);
  const [pages, setPages] = useState(0);
  const selectedStores = useSelectedStores();
  const [orderBy, setOrderBy] = useState('stock_value_without_turn');
  const [orderDirection, setOrderDirection] = useState("desc");

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const getBrands = async (page = 1) => {
    setLoading(true);
    const storesId = formatStoresToId(selectedStores).join(",");
    try {
      const { data } = await getBrandsWithoutTurn(
        page,
        storesId,
        weeksInterval,
        category,
        subCategory,
        segment,
        orderBy,
        orderDirection
      );
      cachePage(page, data.data);
      setBrands(data.data);
      setBrandsTotalValue(data.brands_total);
      setBrandsTotal(data.total);
      setPages(data.pages);
    } catch (e) {}

    setLoading(false);
  };

  useEffect(() => {
    if (cachedPages.hasOwnProperty(currentPage)) {
      setBrands(cachedPages[currentPage]);
      return;
    }
    getBrands(currentPage);
  }, [currentPage, selectedStores]);

  useEffect(() => {
    setCurrentPage(1);
    getBrands(1);
  }, [selectedStores, weeksInterval, orderBy, orderDirection]);

  const handleSort = column => {
    const newOrderDirection =
      column === orderBy ? toggleDirection(orderDirection) : "desc";

    if (column !== orderBy) {
      setOrderBy(column);
    }

    setOrderDirection(newOrderDirection);
  };

  const headColumns = [
    {
      id: "ranking",
      label: "Ranking Vendas R$",
      tooltip: "Ranking de Vendas R$ por marca no período analisado"
    },
    { id: "brand", label: "Marca" },
    { id: "total_value", label: "Venda R$" },
    {
      id: "total_stock_value",
      label: "Estoque R$",
      tooltip: "Estoque R$ a Custo de Mercadoria Vendida"
    },
    { id: "stock_value_without_turn", label: "Estoque R$ Sem Giro" },
    { id: "gross_margin", label: "Peso Estoque Sem Giro", disableOrder: true },
    {
      id: "gross_margin",
      label: "Peso Estoque Sem Giro Acumulado",
      disableOrder: true
    }
  ];

  return (
    <>
      <Card>
        <CardBody
          className={
            !loading &&
            !brands?.length &&
            "d-flex justify-content-center align-items-center"
          }
          style={{ height: "100%" }}
        >
          {loading && (
            <div className="loading-container">
              <CircularProgress />
            </div>
          )}

          {!loading && !brands?.length && (
            <div className="empty-list">Nenhuma marca encontrada.</div>
          )}

          {!loading && !!brands?.length && (
            <div className="overflow-auto">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: 0, padding: 6 }} />
                    {headColumns.map(column => (
                      <TableHeadCell
                        key={column.id}
                        id={column.id}
                        label={column.label}
                        tooltip={column.tooltip}
                        handleSort={handleSort}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                        disableOrder={column.disableOrder}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    brands?.map(brand => (
                      <Row
                        key={brand.brand || "-"}
                        brand={brand}
                        brandsTotalValue={brandsTotalValue}
                        totalStock={totalStock}
                        withoutTurnStock={withoutTurnStock}
                      />
                    ))}
                </TableBody>
              </Table>
            </div>
          )}

          {!loading && !!brands?.length && (
            <div className="mt-5">
              <Pagination
                page={currentPage}
                count={pages}
                onChange={onChangePaginationHandler}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
