import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute, Layout } from "../_metronic/layout";
import SiteBasePage from "../pages/Site/SiteBasePage";
import { Auth } from "../pages/Site/Auth";
import PrivateRoute from "../components/Common/PrivateRoute";
import AnonRoute from "../components/Common/AnonRoute";
import FirstAccess from "../pages/Site/Dashboard/FirstAccess";

export function SiteRoutes() {
  return (
    <Switch>
      <AnonRoute
        role="DIRECTOR"
        path="/auth"
        redirectTo="/"
        component={Auth}
      />
      <PrivateRoute
        role="DIRECTOR"
        path="/first-access"
        redirectTo="/auth/login"
        component={props => (
          <Layout>
            <Switch>
              <ContentRoute exact path="*" component={FirstAccess} {...props} />
            </Switch>
          </Layout>
        )}
      />

      <PrivateRoute
        role="DIRECTOR"
        redirectTo="/auth/login"
        component={props => (
          <Layout>
            <SiteBasePage {...props} />
          </Layout>
        )}
      />
    </Switch>
  );
}
