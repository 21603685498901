import moment from "moment";
import momentI18n from '../localization/moment';

moment.defineLocale('pt-br', momentI18n.pt_BR);

const currentMonthDay = moment().date();
const firstDayOfMonth = currentMonthDay === 1;
const lastMonth = moment().subtract(1, "month");
const yesterday = moment().subtract(1, "day");
const firstDayOfYear = moment().startOf("year");
const dateFormat = "YYYY-MM-DD";
const twoYearsAgo = moment().subtract(2, "years");
const lastYear = moment().subtract(1, "year");

export const dLessOne = firstDayOfMonth
  ? lastMonth.endOf("month").format(dateFormat)
  : yesterday.format(dateFormat);

export const dLessOneMoment = yesterday.clone();
export const dLessOneLastYearMoment = dLessOneMoment.clone().subtract(1, 'year');
export const firstOfMonthMoment = dLessOneMoment.clone().startOf('month');

export const dLessOneLastYear = firstDayOfMonth
  ? lastMonth
    .endOf("month")
    .subtract(1, "years")
    .format(dateFormat)
  : yesterday.subtract(1, "years").format(dateFormat);

export const filters = {
  time: {
    LAST_DAY: {
      start_date: moment()
        .subtract(1, "day")
        .startOf("day")
        .format(dateFormat),
      end_date: moment()
        .subtract(1, "day")
        .endOf("day")
        .format(dateFormat),
      type: "hours"
    },
    /**
     * Current month until D - 1.
     * If current day is 1, use last month until last day.
     */
    CURRENT_MONTH: {
      start_date: firstDayOfMonth
        ? lastMonth.startOf("month").format(dateFormat)
        : moment()
          .startOf("month")
          .format(dateFormat),
      end_date: dLessOne,
      type: "days"
    },

    /**
     * Current year until D - 1.
     * If current day is 1, use last day of last month.
     */
    CURRENT_YEAR: {
      start_date: firstDayOfYear.format(dateFormat),
      end_date: dLessOne,
      type: "months"
    },

    /**
     * Last year.
     */
    LAST_YEAR: {
      start_date: lastYear.startOf("year").format(dateFormat),
      end_date: lastYear.endOf("year").format(dateFormat),
      type: "months"
    },

    /**
     * Last two years and current year until D - 1.
     * If current day is 1, use last day of last month.
     */
    LAST_TWO_YEARS_WITH_CURRENT_YEAR: {
      start_date: twoYearsAgo.startOf("year").format(dateFormat),
      end_date: dLessOne,
      type: "months"
    }
  }
};

export function dayToBRFormat(input) {
  const date = input.split("-");
  return `${date[2]}/${date[1]}`;
}

export function firstDayCurrentMonth(input) {
  const date = input.split("-");
  return `01/${date[1]}/${date[0]}`;
}

export const months = [
  "Jan",
  "Fev",
  "Mar",
  "Abril",
  "Maio",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez"
];

export function dateToBRFormat(input) {
  const date = input.split("-");
  return `${date[2]} ${months[date[1] - 1].toLowerCase()} ${date[0]}`;
}

export function yearlyVision() {
  return [
    moment()
      .subtract(2, "years")
      .format("YYYY"),
    moment()
      .subtract(1, "years")
      .format("YYYY"),
    moment().format("YYYY")
  ];
}
