import React, { useState } from 'react'
import { Card } from "../../../../_metronic/_partials/controls";
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

export default function HintsAndTipsCard({ text, color, onClick }) {
  const [hoverActive, setHoverActive] = useState(false);
  const background = hoverActive ? `bg-${color}` : `bg-light-${color}`;

  const toggleHover = () => setHoverActive(!hoverActive);

  return (
    <Grid
      item
      xs={12}
      md={4}
      className="position-relative"
      onMouseOver={toggleHover}
      onMouseOut={toggleHover}
      onClick={onClick}
    >
      <Card
        className={`cursor-pointer d-flex flex-row align-items-center justify-content-center ${background} shadow-sm`}
        style={{ minHeight: 50 }}
      >
        <span className={clsx('font-weight-bolder', 'text-light' )}>{text}</span>
      </Card>
    </Grid>
  )
}
