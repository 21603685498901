import {
  Button, Dialog,
  DialogActions,
  DialogContent, DialogTitle, Grid, TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createYupErrorsObject } from "../../../../helpers";
import { actions as storeActions } from "../../../../redux/Stores";
import { createStore, updateStore } from "../../../../services/stores";
import schema from "./schema";

const INITIAL_DATA = {
  name: "",
  slug: "",
  deposit: ""
};

export default function StoreDialog({ open, handleClose, status, store }) {
  const [data, setData] = useState(INITIAL_DATA);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onClickHandler = async () => {
    if (!store.id) {
      onClickAddHandler();
    } else {
      onClickEditHandler();
    }
  };

  const onClickClose = () => {
    setErrors({});
    handleClose();
  }

  const onClickAddHandler = async () => {
    setLoading(true);

    try {
      await schema.validate(data, { abortEarly: false });
      const res = await createStore(data);
      const newStore = res.data;

      dispatch(storeActions.createStore(newStore));

      handleClose();
    } catch (e) {
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      } else {
        setErrors(e.response.data.errors);
      }
    }

    setLoading(false);
  };

  const onClickEditHandler = async () => {
    setLoading(true);

    try {
      await schema.validate(data, { abortEarly: false });
      const res = await updateStore(data, data.id);
      const newStore = res.data;

      dispatch(storeActions.updateStore(newStore));

      handleClose();
    } catch (e) {
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      } else {
        setErrors(e.response.data.errors);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setData(store);
  }, [store]);

  return (
    <div>
      <Dialog open={open} onClose={onClickClose}>
        <DialogTitle>
          {!store.id ? "Criar filial" : "Editar filial"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Nome"
                value={data.name}
                error={errors?.name}
                helperText={errors?.name || ""}
                variant="outlined"
                color="secondary"
                onChange={e => setData({ ...data, name: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Slug"
                value={data.slug}
                error={errors?.slug}
                helperText={errors?.slug || ""}
                variant="outlined"
                color="secondary"
                onChange={e => setData({ ...data, slug: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Depósito"
                value={data.deposit}
                error={errors?.deposit}
                helperText={errors?.deposit || ""}
                variant="outlined"
                color="secondary"
                onChange={e => setData({ ...data, deposit: e.target.value })}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "20px" }}>
          <Button onClick={onClickClose}>Cancelar</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickHandler}
            disabled={loading}
          >
            {loading ? "Carregando..." : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
