import React from 'react';
import { Grid } from '@material-ui/core';
import { Card } from "../../../../_metronic/_partials/controls";
import clsx from 'clsx';
import InfoTooltip from '../../../Common/InfoTooltip';
import { formatIntNumber, roundSign } from '../../../../helpers';
import RoundSignTooltip from '../../../Common/RoundSignTooltip';

export default function CurveTab({
  curve,
  orders,
  items,
  stock_part,
  grossProfit,
  color,
  tip,
}) {
  return (
    <Grid
      item
      xs={12}
      md={3}
      className="position-relative"
    >
      <Card
        className={`d-flex flex-row align-items-center justify-content-center bg-${color} shadow-sm`}
        style={{ minHeight: 160 }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}>
          <span className={clsx('font-weight-bolder', 'text-white', 'text-center')} style={{ fontSize: '4rem', lineHeight: 1 }}>{curve}</span>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
          textAlign: 'right',
          width: '100%',
          height: '100%',
          paddingRight: 30
        }}>
          <span className={clsx('mb-3', 'font-weight-bolder', 'text-light')}>{formatIntNumber(items)} itens</span>

          <RoundSignTooltip number={orders}>
            <span className={clsx('mb-3', 'font-weight-bolder', 'text-light')}>{parseFloat(orders).toFixed(2)}% das vendas</span>
          </RoundSignTooltip>

          <RoundSignTooltip number={grossProfit}>
            <span className={clsx('mb-3', 'font-weight-bolder', 'text-light')}>{parseFloat(grossProfit).toFixed(2)}% do lucro bruto</span>
          </RoundSignTooltip>

          <RoundSignTooltip number={stock_part}>
            <span className={clsx('mb-3', 'font-weight-bolder', 'text-light')}>
              {parseFloat(stock_part).toFixed(2)}% do estoque
            </span>
          </RoundSignTooltip>
        </div>
      </Card>
      <InfoTooltip
        text={tip}
        style={{
          position: 'absolute',
          top: 25,
          left: 25,
        }}
        light
        fontSize={18}
      />
    </Grid>
  );
}
