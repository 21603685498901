import { Grid } from '@material-ui/core'
import React from 'react'
import { useSubheader } from '../../_metronic/layout'
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls'

export default function Error({ status, text }) {
  const subheader = useSubheader();
  subheader.setTitle(`Erro`);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className="card-heavy-border">
          <CardHeader title={`Erro ${status}`} />
          <CardBody>
            {text}
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}
