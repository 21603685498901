import api from "./api";

export function read(order, orderBy) {
  return api.get(`/admin/businesses?active=${orderBy}&direction=${order}`);
}

export function create(data) {
  return api.post("/admin/businesses", data);
}

export function edit(data, id) {
  return api.put(`/admin/businesses/${id}`, data);
}

export function remove(id) {
  return api.delete(`/admin/businesses/${id}`);
}

export function updateSaasBusinessData(data) {
  return api.post("/me/saas-business", { _method: "PUT", ...data });
}

export function syncErp() {
  return api.get("/me/saas-business/sync-erp");
}
