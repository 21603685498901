import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    loading: {
        color: '#FFF',
    }
}));

export default function SwitchLoading({ loading, children, size }) {
    const classes = useStyles();

    return loading ? (
        <CircularProgress size={size || 42} className={classes.loading} />
    ) : children
};
