/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = url => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/"
          )}`}
        >
          <NavLink className="menu-link" to="/">
            <span className="menu-text">Painel de Gestão</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/stores"
          )}`}
        >
          <NavLink className="menu-link" to="/stores">
            <span className="menu-text">Filiais</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
