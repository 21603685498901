import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useModule() {
  const [module, setModule] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    const positionOfSecondBar = pathname.indexOf("/", 1) - 1;
    const end =
      positionOfSecondBar > -2 ? positionOfSecondBar : pathname.length;
    const detectedModule = pathname.substr(1, end);
    setModule(detectedModule === "admin" ? "admin" : "user");
  }, [location]);

  return module;
}
