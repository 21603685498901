import React, { useState, useRef, useContext } from "react";
import {
  Card,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import Brand from "./Brand";
import ExportPopover from "../../Common/ExportPopover";
import { formatStoresToId } from "../../../helpers";
import { useSelectedStores } from "../../../hooks/stores";
import { exportToWithoutTurn } from "../../../services/product-management";
import fileDownload from "js-file-download";
import moment from "moment";
import { ProductsManagementFiltersContext } from "../../../contexts/ProductsManagementFiltersProvider";

const types = {
  BRAND: "brand",
  ITEM: "item"
};

export default function WithoutTurn({ totalStock, withoutTurnStock }) {
  const { weeksInterval, category, subCategory, segment } = useContext(ProductsManagementFiltersContext);
  const [type, setType] = useState(types.ITEM);
  const [exporting, setExporting] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [exportHandle, setExportHandle] = useState(null);
  const selectedStores = useSelectedStores();
  const containerRef = useRef();

  const openExportHandle = e => {
    setExportHandle(e.currentTarget);
  };

  const closeExportHandle = () => {
    setExportHandle(null);
  };

  const handleExport = async extension => {
    setExporting(extension);
    const storesId = formatStoresToId(selectedStores);

    try {
      const res = await exportToWithoutTurn(extension, storesId, weeksInterval, category, subCategory, segment);
      fileDownload(res.data, `WT${moment().format("YYYYMMDD")}.${extension}`);
    } catch (e) {
      setErrorAlert(true);
    }
    setExporting(null);
  };

  return (
    <>
      <Card ref={containerRef}>
        <CardHeader
          title=""
          toolbar={
            <CardHeaderToolbar className="toolbar-container d-print-none">
              <ExportPopover
                open={exportHandle}
                handleOpenExport={openExportHandle}
                handleCloseExport={closeExportHandle}
                handleExport={handleExport}
                exporting={exporting}
              />
            </CardHeaderToolbar>
          }
        />
        <Brand
          totalStock={totalStock}
          withoutTurnStock={withoutTurnStock}
        />
      </Card>
    </>
  );
}
